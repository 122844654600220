var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import InfoDisplay from './InfoDisplay';
var PointOfCareTestDisplay = function (_a) {
    var data = _a.data, onAccept = _a.onAccept, onCancel = _a.onCancel;
    function hint() {
        return "".concat(data.userRequest);
    }
    return (_jsxs(InfoDisplay, __assign({ className: 'bg-yellow-100', hint: hint(), infoTypeName: 'Point Of Care Test', onAccept: onAccept, onCancel: onCancel }, { children: [data.testName && _jsx("span", __assign({ className: 'font-bold' }, { children: data.testName })), data.sampleType && _jsx("span", { children: data.sampleType })] })));
};
export default PointOfCareTestDisplay;
