var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ArcwareView from "../../components/Arcware/Arcware";
import React, { useState } from "react";
import { SchemaSelector, GetSchemaRenderer } from "../TreeView/SchemaTest";
import { AnimatePresence, motion } from "framer-motion";
var TeamLeadPrototypeView = function () {
    var _a = useState([]), resultHistory = _a[0], setResultHistory = _a[1];
    function onResultSelected(result) {
        console.log(result);
        setResultHistory(__spreadArray(__spreadArray([], resultHistory, true), [result], false));
    }
    ;
    return (_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden' }, { children: [_jsx("div", __assign({ id: "ue5-container", className: "flex-grow h-full w-full absolute" }, { children: _jsx(ArcwareView, {}) })), _jsx("div", __assign({ className: 'absolute bottom-0 w-full flex flex-col items-center p-2 z-[99999]' }, { children: _jsx("div", __assign({ className: "w-96" }, { children: _jsx(SchemaSelector, { onResultSelected: onResultSelected }) })) })), _jsx(AnimatePresence, { children: _jsx(motion.div, __assign({ className: 'absolute top-0 left-0 flex flex-col p-2 gap-1 z-[99999]' }, { children: resultHistory.reverse().slice(0, 5).reverse().map(function (result, index) { return (_jsx(motion.div, __assign({ initial: "collapsed", animate: "open", exit: "collapsed", variants: {
                            open: { scale: 1 },
                            collapsed: { scale: 0 }
                        }, style: { opacity: 1 - (index * 0.1) } }, { children: GetSchemaRenderer(result) }), result.filledSchema)); }) })) })] })));
};
export default TeamLeadPrototypeView;
