var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Button_Background from "./Button_Background.svg";
import Button_Background_Active from "./Button_Background_Active.svg";
import { useMenuControl } from "./DemoControlCommon";
import { ControlsButton, ControlsMenu, ControlsMenuButton } from "./DemoControlsCommon";
import { AnimatePresence } from "framer-motion";
import { GiInterleavedArrows } from "react-icons/gi";
import useDynamicVirtualPatient from "../../pages/DemoMode/DynamicVirtualPatientStore";
import DynamicVirtualPatientService from "../../pages/DemoMode/DynamicVirtualPatientService";
var ActionState;
(function (ActionState) {
    ActionState[ActionState["Unavailable"] = 0] = "Unavailable";
    ActionState[ActionState["Available"] = 1] = "Available";
    ActionState[ActionState["Active"] = 2] = "Active";
})(ActionState || (ActionState = {}));
var SimpleInterventionControl = function (props) {
    var _a = useMenuControl('SimpleInterventions'), isDemoMenuActive = _a.isDemoMenuActive, toggleDemoMenuActive = _a.toggleDemoMenuActive, deactivateDemoMenu = _a.deactivateDemoMenu;
    var _b = useDynamicVirtualPatient(), activeActions = _b.activeActions, setActiveActions = _b.setActiveActions;
    function chinLift() {
        if (getChinLiftState() === ActionState.Available) {
            setActiveActions(__spreadArray(__spreadArray([], activeActions, true), ['ChinLift'], false));
            DynamicVirtualPatientService.performAction("Chin lift");
            deactivateDemoMenu();
        }
    }
    function getChinLiftState() {
        if (activeActions.includes('ChinLift')) {
            return ActionState.Active;
        }
        return ActionState.Available;
    }
    return (_jsxs("div", __assign({ className: "w-full relative" }, { children: [_jsxs(ControlsButton, __assign({ onClick: toggleDemoMenuActive, active: isDemoMenuActive }, { children: [_jsx("img", { src: isDemoMenuActive ? Button_Background_Active : Button_Background, alt: '', className: 'w-full h-full' }), _jsx("div", __assign({ className: "absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold text-3xl\n                    ".concat(isDemoMenuActive ? 'opacity-100' : 'opacity-60', "\n                    ") }, { children: _jsx(GiInterleavedArrows, {}) }))] })), _jsx(AnimatePresence, { children: isDemoMenuActive && (_jsx(ControlsMenu, __assign({ props: props }, { children: _jsx(ControlsMenuButton, __assign({ onClick: chinLift, active: getChinLiftState() === ActionState.Active }, { children: "Chin Lift" })) }))) })] })));
};
export default SimpleInterventionControl;
