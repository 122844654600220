var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import Button_Steth from "./Button_Steth.svg";
import Button_Steth_Active from "./Button_Steth_Active.svg";
import { useAppContext } from "../../AppContext";
import { UE5MessageType } from "../ue5";
import { useMenuControl } from "./DemoControlCommon";
import { AnimatePresence } from "framer-motion";
import { ControlsButton, ControlsMenu, ControlsMenuButton } from "./DemoControlsCommon";
var StethoscopeLocations = [
    { msg: UE5MessageType.listenHeartAortic.valueOf(), label: "Heart - Aortic" },
    { msg: UE5MessageType.listenLungAnteriorLeft.valueOf(), label: "Lung - Left Anterior" },
    { msg: UE5MessageType.listenLungAnteriorRight.valueOf(), label: "Lung - Right Anterior" }
];
var StethoscopeControl = function (props) {
    var _a = useMenuControl('Stethoscope'), isDemoMenuActive = _a.isDemoMenuActive, toggleDemoMenuActive = _a.toggleDemoMenuActive;
    var broadcastMessage = useAppContext().broadcastMessage;
    var _b = useState(), activeOption = _b[0], setActiveOption = _b[1];
    var stopStethoscope = useCallback(function () {
        broadcastMessage(UE5MessageType.stopUsingStethoscope.valueOf(), {});
        setActiveOption(undefined);
    }, [broadcastMessage]);
    useEffect(function () {
        if (!isDemoMenuActive) {
            stopStethoscope();
        }
    }, [isDemoMenuActive, stopStethoscope]);
    function doStethoscope(id, msg) {
        if (id === activeOption) {
            stopStethoscope();
        }
        else {
            broadcastMessage(msg, {});
            setActiveOption(id);
        }
    }
    return (_jsxs("div", __assign({ className: "w-full relative" }, { children: [_jsx(ControlsButton, __assign({ onClick: toggleDemoMenuActive, active: isDemoMenuActive }, { children: _jsx("img", { src: isDemoMenuActive ? Button_Steth_Active : Button_Steth, alt: '', className: 'w-full h-full' }) })), _jsx(AnimatePresence, { children: isDemoMenuActive && (_jsx(ControlsMenu, __assign({ props: props }, { children: StethoscopeLocations.map(function (_a, id) {
                        var msg = _a.msg, label = _a.label;
                        return (_jsx(ControlsMenuButton, __assign({ onClick: function () { return doStethoscope(id, msg); }, active: id === activeOption }, { children: label }), id));
                    }) }))) })] })));
};
export default StethoscopeControl;
