import { jsx as _jsx } from "react/jsx-runtime";
import { AppProvider } from "../../AppContext";
import { useLocation } from "react-router-dom";
import IndividualAssignmentResponse from "./IndividualAssignmentResponse";
import AssignmentReviewList from "./AssignmentReviewList";
var AssignmentReviewIndex = function () {
    var query = new URLSearchParams(useLocation().search);
    var assignmentResponseId = query.get('assignmentResponseId');
    return (_jsx(AppProvider, { children: assignmentResponseId ? (_jsx(IndividualAssignmentResponse, { assignmentResponseId: assignmentResponseId })) : (_jsx(AssignmentReviewList, {})) }));
};
export default AssignmentReviewIndex;
