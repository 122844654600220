var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from "react";
var UnitInputControl = function (_a) {
    var label = _a.label, value = _a.value, unit = _a.unit, onChange = _a.onChange;
    var _b = useState(value.toFixed(0)), inputValue = _b[0], setInputValue = _b[1];
    var _c = useState(false), isEditing = _c[0], setIsEditing = _c[1];
    var inputRef = useRef(null);
    useEffect(function () {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);
    useEffect(function () {
        if (!isEditing) {
            setInputValue(value.toString());
        }
    }, [value, isEditing]);
    var handleBlur = function () {
        setIsEditing(false);
        onChange(parseFloat(inputValue));
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            onChange(parseFloat(inputValue));
            setIsEditing(false);
        }
    };
    var handleDivClick = function () {
        setIsEditing(true);
    };
    return (_jsxs("div", __assign({ className: "cursor-pointer flex gap-2 w-full", onClick: handleDivClick }, { children: [_jsx("p", __assign({ className: "font-bold" }, { children: label })), isEditing ? (_jsxs("div", __assign({ className: "relative flex-grow" }, { children: [_jsx("input", { ref: inputRef, value: inputValue, onChange: function (e) { return setInputValue(e.target.value); }, onBlur: handleBlur, onKeyUp: handleKeyPress, className: "p-0 pl-1 text-xs w-full -pr-2" }), _jsx("span", __assign({ className: "absolute right-1 top-1/2 transform -translate-y-1/2 text-xs" }, { children: unit }))] }))) : (_jsx("span", __assign({ className: "pl-1 ml-auto pr-2" }, { children: "".concat(value, " ").concat(unit) })))] })));
};
export default UnitInputControl;
