var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
var AssignmentQuestionsList = function (_a) {
    var assignment = _a.assignment, selectedQuestionId = _a.selectedQuestionId, onQuestionSelected = _a.onQuestionSelected;
    var _b = useState(assignment.questions), questions = _b[0], setQuestions = _b[1];
    var handleAddQuestion = function () {
        var newQuestion = {
            question: "New Question",
            options: [
                {
                    answer: "",
                    learningInfo: ""
                }
            ],
            correctAnswerId: 0,
            patientId: ""
        };
        setQuestions(__spreadArray(__spreadArray([], questions, true), [newQuestion], false));
    };
    var handleRemoveQuestion = function (index) {
        if (window.confirm("Are you sure you want to delete this question?")) {
            var updatedQuestions = questions.filter(function (_, i) { return i !== index; });
            setQuestions(updatedQuestions);
        }
    };
    return (_jsxs("div", __assign({ className: "h-full flex flex-col p-4 bg-gray-100 rounded-lg shadow-lg" }, { children: [questions.map(function (question, index) {
                var isSelected = selectedQuestionId === index;
                return (_jsxs("div", __assign({ className: "flex flex-row items-center p-2 mb-2 rounded-lg cursor-pointer transition-colors duration-200 ".concat(isSelected ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-100'), onClick: function () { onQuestionSelected(index); } }, { children: [_jsx("div", __assign({ className: "flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-blue-200 text-blue-800 font-bold mr-4" }, { children: index + 1 })), _jsx("div", __assign({ className: "flex-grow overflow-hidden whitespace-nowrap overflow-ellipsis" }, { children: question.question })), _jsx("button", __assign({ onClick: function (e) {
                                e.stopPropagation();
                                handleRemoveQuestion(index);
                            }, className: "ml-4 bg-red-500 text-white p-1 rounded-lg button-sm" }, { children: "X" }))] }), index));
            }), _jsx("button", __assign({ onClick: handleAddQuestion, className: "mt-4 bg-green-500 text-white p-2 rounded-lg button-sm" }, { children: "Add Question" }))] })));
};
export default AssignmentQuestionsList;
