import { create } from 'zustand';
import { DefaultVirtualPatientState } from 'mirno-shared/src/DefaultState';
import { produce } from 'immer';
export var useVirtualPatientStore = create(function (set) { return ({
    virtualPatientState: DefaultVirtualPatientState,
    updateVirtualPatientState: function (updater) { return set(produce(function (state) {
        updater(state.virtualPatientState);
    })); },
    setVirtualPatientState: function (newState) { return set({ virtualPatientState: newState }); },
}); });
