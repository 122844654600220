var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useEffect, useContext } from 'react';
import { app, firestore as db } from '../../firebase-config';
import { doc, collection, onSnapshot, updateDoc, serverTimestamp, setDoc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { QRCodeSVG } from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import { AuthService } from '../../components/Database/Authentication';
import { v4 as uuidv4 } from 'uuid';
import { getAuth } from 'firebase/auth';
var functions = getFunctions(app);
var server_createSession = httpsCallable(functions, 'createSession');
var SessionContext = createContext(undefined);
export var SessionProvider = function (_a) {
    var children = _a.children;
    var auth = getAuth();
    var location = useLocation();
    var queryParams = new URLSearchParams(location.search);
    var sessionIdFromURL = queryParams.get('sessionId');
    var _b = useState(null), firestoreSessionObject = _b[0], setFirestoreSessionObject = _b[1];
    var _c = useState(sessionIdFromURL), sessionId = _c[0], setSessionId = _c[1];
    useEffect(function () {
        if (!sessionIdFromURL && !sessionId) {
            createSession();
        }
    });
    useEffect(function () {
        function setupSession() {
            return __awaiter(this, void 0, void 0, function () {
                var sessionDocRef, sessionDoc;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(auth.currentUser && sessionIdFromURL)) return [3 /*break*/, 3];
                            if (!sessionIdFromURL) return [3 /*break*/, 3];
                            // console.log("Joining session from URL:", sessionIdFromURL);
                            setSessionId(sessionIdFromURL);
                            sessionDocRef = doc(collection(db, 'sessions'), sessionIdFromURL);
                            return [4 /*yield*/, getDoc(sessionDocRef)];
                        case 1:
                            sessionDoc = _a.sent();
                            if (!!sessionDoc.exists()) return [3 /*break*/, 3];
                            return [4 /*yield*/, setDoc(sessionDocRef, { sessionData: {}, lastActivity: serverTimestamp() })];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        setupSession();
    }, [auth.currentUser, sessionIdFromURL]);
    useEffect(function () {
        function connect() {
            return __awaiter(this, void 0, void 0, function () {
                var sessionDocRef_1, fetchData;
                var _this = this;
                return __generator(this, function (_a) {
                    if (sessionId) {
                        sessionDocRef_1 = doc(collection(db, 'sessions'), sessionId);
                        fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
                            var unsubscribe;
                            return __generator(this, function (_a) {
                                unsubscribe = onSnapshot(sessionDocRef_1, function (doc) {
                                    // console.log("Session data updated:", doc);
                                    if (doc.exists() && doc.data().lastActivity != null) {
                                        // Update the local session data
                                        // console.log("Updating session data:", doc.data());
                                        setFirestoreSessionObject(doc.data());
                                    }
                                });
                                return [2 /*return*/, function () { return unsubscribe(); }];
                            });
                        }); };
                        fetchData();
                    }
                    return [2 /*return*/];
                });
            });
        }
        connect();
    }, [sessionId]);
    var writeDataToSession = React.useCallback(function (sessionData) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedData, docRef;
        return __generator(this, function (_a) {
            // console.log("Setting session data:", sessionData);
            if (AuthService.currentUser() == null) {
                throw new Error("User not signed in - session is read only");
            }
            updatedData = { sessionData: sessionData, lastActivity: serverTimestamp() };
            // console.log(sessionId);
            if (sessionId) {
                docRef = doc(collection(db, 'sessions'), sessionId);
                updateDoc(docRef, updatedData);
            }
            return [2 /*return*/];
        });
    }); }, [sessionId]);
    var createSession = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var newSessionId, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(AuthService.currentUser() == null)) return [3 /*break*/, 1];
                    newSessionId = uuidv4().slice(0, 8);
                    setSessionId(newSessionId);
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, server_createSession()];
                case 2:
                    result = _a.sent();
                    if (result.data && result.data.success) {
                        setSessionId(result.data.sessionId);
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    var sessionQRCode = function (path) {
        var url = window.location.origin;
        var sessionControllerURL = "".concat(url, "/").concat(path, "?sessionId=").concat(sessionId);
        return (_jsx("a", __assign({ href: sessionControllerURL, target: '_blank', rel: "noreferrer", className: 'w-full h-full' }, { children: _jsx(QRCodeSVG, { value: sessionControllerURL, bgColor: '#00000000', fgColor: '#ffffff', className: 'h-full w-full', size: 128, level: 'L' }) })));
    };
    return (_jsx(SessionContext.Provider, __assign({ value: { fullSessionData: firestoreSessionObject, sessionId: sessionId, createSession: createSession, writeDataToSession: writeDataToSession, sessionQRCode: sessionQRCode } }, { children: children })));
};
export var useSession = function () {
    var context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
};
