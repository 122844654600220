var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import "./StartScreenStyle.css";
import MedagogicLogo from "../../assets/mirno_icon.png";
import Icon_Success from "./Icon_Success.svg";
var ViewerAnswersSubmitted = function (_a) {
    var onComplete = _a.onComplete;
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: MedagogicLogo, className: 'max-w-xs w-56 pointer-events-none', alt: "Medagogic Logo" }), _jsxs("div", __assign({ className: 'flex flex-col items-center justify-between gap-8 pb-24 w-3/4' }, { children: [_jsx("div", __assign({ className: '' }, { children: "SUCCESS" })), _jsx("img", { src: Icon_Success, alt: "" }), _jsxs("div", __assign({ className: 'bg-[#70ACB133] p-2 text-sm text-[#70ACB1] w-3/4 text-center' }, { children: ["Your responses were submitted", _jsx("br", {}), _jsx("br", {}), "You may exit the application"] }))] })), _jsx("button", __assign({ className: "intro-button", onClick: onComplete }, { children: "Exit" }))] }));
};
export default ViewerAnswersSubmitted;
