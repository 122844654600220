var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { ActivityState, Posture } from "mirno-shared/src/components/Disability";
import { useModifiedValues } from "./ControllerUtils";
import SimpleDropdownControl from "./InputComponents/SimpleDropdownControl";
import SimpleInputControl from "./InputComponents/SimpleInputControl";
import UnitInputControl from "./InputComponents/UnitInputControl";
import AnimatedSymptomValueBox from "./AnimatedSymptomValueBox";
import { useVirtualPatientStore } from '../../../storage/VirtualPatientStore';
var labelMapping = {
    activity: "Activity",
    avpu: "AVPU",
    leftPupilSize: "Left Pupil Size",
    rightPupilSize: "Right Pupil Size",
    leftPupilSluggishness: "Left Pupil Sluggishness",
    rightPupilSluggishness: "Right Pupil Sluggishness",
    posture: "Posture",
};
export function useDisabilityState() {
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    function setDisabilityValue(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.disability[key] = value;
        });
    }
    return { setDisabilityValue: setDisabilityValue };
}
var DisabilityController = function () {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var setDisabilityValue = useDisabilityState().setDisabilityValue;
    var modifiedValues = useModifiedValues("disability");
    // const allKeys = Object.keys(virtualPatientState.medicalState.disability) as (keyof DisabilityState)[];
    var getOptions = function (key) {
        if (key === "activity") {
            return Object.keys(ActivityState);
        }
        else if (key === "avpu") {
            return ["A", "V", "P", "U"];
        }
        else if (key === "posture") {
            return Object.keys(Posture);
        }
        return [];
    };
    return (_jsx(_Fragment, { children: modifiedValues.map(function (key, id) {
            var value = virtualPatientState.medicalState.disability[key];
            return (_jsx(AnimatedSymptomValueBox, __assign({ value: value }, { children: typeof value === "number" ? ((key === "leftPupilSize" || key === "rightPupilSize") ? (_jsx(UnitInputControl, { label: labelMapping[key], value: value, unit: "mm", onChange: function (newValue) { setDisabilityValue(key, newValue); } })) : (_jsx(SimpleInputControl, { label: labelMapping[key], value: value, onChange: function (newValue) { setDisabilityValue(key, newValue); } }))) : (_jsx(SimpleDropdownControl, { label: labelMapping[key], value: value, options: getOptions(key), onChange: function (newValue) { setDisabilityValue(key, newValue); } })) }), id));
        }) }));
};
export default DisabilityController;
