var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { HeartSound } from "mirno-shared/src/components/Circulation";
import { useModifiedValues } from "./ControllerUtils";
import AnimatedSymptomValueBox from "./AnimatedSymptomValueBox";
import { useVirtualPatientStore } from '../../../storage/VirtualPatientStore';
import SimpleDropdownControl from './InputComponents/SimpleDropdownControl';
import UnitInputControl from './InputComponents/UnitInputControl';
var labelMapping = {
    capillaryRefillSeconds: "Capillary Refill",
    heartSound: "Heart Sound",
};
export function useCirculationState() {
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    function setCirculationValue(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.circulation[key] = value;
        });
    }
    return { setCirculationValue: setCirculationValue };
}
var CirculationController = function () {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var modifiedKeys = useModifiedValues("circulation");
    // const allKeys = Object.keys(virtualPatientState.medicalState.circulation) as (keyof CirculationState)[];
    var setCirculationValue = useCirculationState().setCirculationValue;
    var getOptions = function (key) {
        if (key === "heartSound") {
            return Object.keys(HeartSound);
        }
        return [];
    };
    return (_jsx(_Fragment, { children: modifiedKeys.map(function (key, id) {
            var value = virtualPatientState.medicalState.circulation[key];
            return (_jsx(AnimatedSymptomValueBox, __assign({ value: value }, { children: key === "capillaryRefillSeconds" ? (_jsx(UnitInputControl, { label: labelMapping[key], value: value, unit: "seconds", onChange: function (newValue) { setCirculationValue(key, newValue); } })) : (_jsx(SimpleDropdownControl, { label: labelMapping[key], value: value, options: getOptions(key), onChange: function (newValue) { setCirculationValue(key, newValue); } })) }), id));
        }) }));
};
export default CirculationController;
