export var BloodPressureMetadata = {
    _description: "Blood pressure",
    systolic: {
        unit: "mmHg"
    },
    diastolic: {
        unit: "mmHg"
    }
};
export function parseBloodPressure(bloodPressure) {
    var _a = bloodPressure.split("/"), systolic = _a[0], diastolic = _a[1];
    return { systolic: Number(systolic), diastolic: Number(diastolic) };
}
export function formatBloodPressure(bloodPressure) {
    return "".concat(bloodPressure.systolic, "/").concat(bloodPressure.diastolic);
}
