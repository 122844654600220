var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import "../../firebase-config";
import { DefaultVirtualPatientState } from 'mirno-shared/src/DefaultState';
import { app } from '../../firebase-config';
import { collection, getFirestore, addDoc, getDoc, doc, updateDoc, setDoc, serverTimestamp, getDocs } from 'firebase/firestore';
import { AuthService } from './Authentication';
import { Metadata } from 'mirno-shared/src/types/DatabaseTypes';
import axios from 'axios';
var FIRESTORE_DB = getFirestore(app);
var PATIENT_PRESET_COLLECTION = collection(FIRESTORE_DB, 'virtual-patient-states');
var VirtualPatientStateStorage = /** @class */ (function () {
    function VirtualPatientStateStorage() {
    }
    // static async getPatientPresets(): Promise<FirestoreDoc_VirtualPatientState[]> {
    //     try {
    //         const response = await axios.get(`${VirtualPatientStateStorage.baseURL}getPatientPresets`);
    //         const partialPresets =  response.data as Partial<FirestoreDoc_VirtualPatientState>[];
    //         const presets: FirestoreDoc_VirtualPatientState[] = partialPresets.map(partialPreset => {
    //             const patientData: Partial<VirtualPatientState> = partialPreset.virtualPatientState!;
    //             partialPreset.virtualPatientState = {...DefaultVirtualPatientState, ...patientData};
    //             return partialPreset as FirestoreDoc_VirtualPatientState;
    //         })
    //         return presets
    //     } catch (error) {
    //         console.error("Error fetching patient presets: ", error);
    //         throw error;
    //     }
    // }
    VirtualPatientStateStorage._updatePatientPreset = function (firestoreData, patientData) {
        return __awaiter(this, void 0, void 0, function () {
            var docRef, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        docRef = doc(PATIENT_PRESET_COLLECTION, firestoreData.id);
                        user = AuthService.currentUser();
                        if (!user || user.uid !== firestoreData.metadata.ownerId) {
                            throw new Error("User not authorized to update this preset");
                        }
                        return [4 /*yield*/, updateDoc(docRef, {
                                patientData: patientData,
                                metadata: {
                                    updatedAt: serverTimestamp()
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // Validation should be server side but fuck it
    VirtualPatientStateStorage.savePatientPreset = function (virtualPatientState, id) {
        return __awaiter(this, void 0, Promise, function () {
            var user, newMetadata, docRef, firestoreDoc, firestoreData, newDoc, uploadData, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = AuthService.currentUser();
                        if (!user) {
                            throw new Error("User not signed in");
                        }
                        newMetadata = Metadata(user.uid, user.displayName || "Unknown", user.email);
                        if (!id) return [3 /*break*/, 5];
                        docRef = doc(PATIENT_PRESET_COLLECTION, id);
                        return [4 /*yield*/, getDoc(docRef)];
                    case 1:
                        firestoreDoc = _a.sent();
                        if (!firestoreDoc.exists()) return [3 /*break*/, 3];
                        firestoreData = firestoreDoc.data();
                        firestoreData.id = id;
                        return [4 /*yield*/, VirtualPatientStateStorage._updatePatientPreset(firestoreData, virtualPatientState)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        newDoc = {
                            metadata: newMetadata,
                            virtualPatientState: virtualPatientState,
                        };
                        setDoc(docRef, newDoc);
                        _a.label = 4;
                    case 4: return [2 /*return*/, id];
                    case 5:
                        uploadData = {
                            virtualPatientState: virtualPatientState,
                            metadata: newMetadata
                        };
                        return [4 /*yield*/, addDoc(PATIENT_PRESET_COLLECTION, uploadData)];
                    case 6:
                        result = _a.sent();
                        return [2 /*return*/, result.id];
                }
            });
        });
    };
    VirtualPatientStateStorage.writeVirtualPatientDoc = function (vpDoc) {
        return __awaiter(this, void 0, void 0, function () {
            var user, newMetadata, docRef, docRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = AuthService.currentUser();
                        if (!user) {
                            throw new Error("User not signed in");
                        }
                        newMetadata = Metadata(user.uid, user.displayName || "Unknown", user.email);
                        vpDoc.metadata = newMetadata;
                        if (!!vpDoc.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, addDoc(PATIENT_PRESET_COLLECTION, vpDoc)];
                    case 1:
                        docRef = _a.sent();
                        vpDoc.id = docRef.id;
                        return [3 /*break*/, 4];
                    case 2:
                        docRef = doc(PATIENT_PRESET_COLLECTION, vpDoc.id);
                        return [4 /*yield*/, setDoc(docRef, vpDoc)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, vpDoc.id];
                }
            });
        });
    };
    VirtualPatientStateStorage.getPreset = function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var docRef, docSnap, loadedData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        docRef = doc(PATIENT_PRESET_COLLECTION, id);
                        return [4 /*yield*/, getDoc(docRef)];
                    case 1:
                        docSnap = _a.sent();
                        loadedData = docSnap.exists() ? docSnap.data() : null;
                        if (loadedData) {
                            loadedData.id = id;
                            loadedData.virtualPatientState = __assign(__assign({}, DefaultVirtualPatientState), loadedData.virtualPatientState);
                            return [2 /*return*/, loadedData];
                        }
                        else {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    VirtualPatientStateStorage.listPresets = function () {
        return __awaiter(this, void 0, Promise, function () {
            var docs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getDocs(PATIENT_PRESET_COLLECTION)];
                    case 1:
                        docs = _a.sent();
                        return [2 /*return*/, docs.docs.map(function (doc) {
                                return __assign(__assign({}, doc.data()), { id: doc.id });
                            })];
                }
            });
        });
    };
    VirtualPatientStateStorage.deletePreset = function (id, authToken) {
        return __awaiter(this, void 0, Promise, function () {
            var response, error_1, axiosError;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.delete("".concat(VirtualPatientStateStorage.baseURL, "deletePatientPreset?id=").concat(id), {
                                headers: {
                                    Authorization: "Bearer ".concat(authToken)
                                }
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_1 = _a.sent();
                        axiosError = error_1;
                        if (axiosError.response) {
                            // Error response from server
                            console.error("Error status text: ", axiosError.response.statusText);
                            throw new Error(axiosError.response.statusText);
                        }
                        else if (axiosError.request) {
                            // The request was made but no response was received
                            console.error("No response received");
                        }
                        else {
                            // Something happened in setting up the request that triggered an Error
                            console.error("Error setting up request: ", axiosError.message);
                        }
                        throw axiosError;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VirtualPatientStateStorage.baseURL = 'https://us-central1-patient-viewer.cloudfunctions.net/';
    return VirtualPatientStateStorage;
}());
export default VirtualPatientStateStorage;
