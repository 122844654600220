import { z } from "zod";
export var AirwayInterventionSchema = z.object({
    procedure: z.enum([
        "Intubation",
        "Oxygen Therapy",
        "Mechanical Ventilation",
        "Non-Invasive Ventilation"
    ]),
    intubation: z
        .object({
        tubeSize: z.string().optional().describe("eg. '7.5mm' - must always include units"),
        method: z.enum(["Oral", "Nasal"]).optional(),
        medications: z.array(z.string()).optional()
    })
        .optional(),
    oxygenTherapy: z
        .object({
        method: z
            .string()
            .describe("eg 'Nasal Cannula', 'Face Mask', 'Non-rebreather', 'High Flow'")
            .optional(),
        flowRate: z
            .string()
            .describe("eg. '15L/minute")
            .optional()
    })
        .optional(),
    mechanicalVentilation: z
        .object({
        mode: z.enum(["AC", "SIMV", "PRVC", "HFOV"]).optional(),
        tidalVolume: z.number().optional(),
        respiratoryRate: z.number().optional(),
        peep: z.number().optional(),
        fio2: z.number().optional()
    })
        .optional(),
    nonInvasiveVentilation: z
        .object({
        mode: z.enum(["CPAP", "BiPAP"]).optional(),
        inspiratoryPressure: z.number().optional(),
        expiratoryPressure: z.number().optional()
    })
        .optional(),
    userRequest: z.string().optional()
});
