var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Button_Examination from "./Button_Examination.svg";
import Button_Examination_Active from "./Button_Examination_Active.svg";
import { useAppContext } from "../../AppContext";
import { UE5MessageType } from "../ue5";
import { useMenuControl } from "./DemoControlCommon";
import { ControlsButton, ControlsMenu, ControlsMenuButton } from "./DemoControlsCommon";
import { AnimatePresence } from "framer-motion";
var ExaminationControl = function (props) {
    var _a = useMenuControl('Examinations'), isDemoMenuActive = _a.isDemoMenuActive, toggleDemoMenuActive = _a.toggleDemoMenuActive, deactivateDemoMenu = _a.deactivateDemoMenu;
    var broadcastMessage = useAppContext().broadcastMessage;
    function doExamination(msg) {
        broadcastMessage(msg, {});
        setTimeout(function () {
            deactivateDemoMenu();
        }, 100);
    }
    return (_jsxs("div", __assign({ className: "w-full relative" }, { children: [_jsx(ControlsButton, __assign({ onClick: toggleDemoMenuActive, active: isDemoMenuActive }, { children: _jsx("img", { src: isDemoMenuActive ? Button_Examination_Active : Button_Examination, alt: '', onClick: toggleDemoMenuActive, className: 'pointer-events-auto cursor-pointer w-full h-full' }) })), _jsx(AnimatePresence, { children: isDemoMenuActive && (_jsxs(ControlsMenu, __assign({ props: props }, { children: [_jsx(ControlsMenuButton, __assign({ onClick: function () { return doExamination(UE5MessageType.examinePupilResponse.valueOf()); } }, { children: "Pupil Response" })), _jsx(ControlsMenuButton, __assign({ onClick: function () { return doExamination(UE5MessageType.examineCapillaryRefill.valueOf()); } }, { children: "Capillary Refill" })), _jsx(ControlsMenuButton, __assign({ onClick: function () { return doExamination(UE5MessageType.sternumRub.valueOf()); } }, { children: "Sternum Rub" }))] }))) })] })));
};
export default ExaminationControl;
