import { z } from "zod";
export var MedicationInterventionSchema = z.object({
    medication: z.string(),
    dose: z.string().optional(),
    route: z.enum([
        "Oral",
        "IV",
        "IM",
        "IO",
        "SC",
        "Inhaled",
        "Topical",
        "Rectal",
        "Intranasal"
    ]).optional(),
    userRequest: z.string().optional()
});
