var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Transition } from "@headlessui/react";
import UserProfileComponent from "../../components/Reuseable/UserProfileComponent";
import VirtualPatientStateStorage from "../../components/Database/VirtualPatientStateStorage";
import { useEffect, useState } from "react";
import { useAppContext } from "../../AppContext";
import { PatientType } from "mirno-shared/src/PatientState";
import { FaUser, FaX } from "react-icons/fa6";
var LoadModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, onSelection = _a.onSelection;
    var authedUser = useAppContext().authedUser;
    var _b = useState([]), presets = _b[0], setPresets = _b[1];
    function loadPresets() {
        return __awaiter(this, void 0, void 0, function () {
            var presets;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, VirtualPatientStateStorage.listPresets()];
                    case 1:
                        presets = _a.sent();
                        setPresets(presets);
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        loadPresets();
    });
    function presetContainer(key, doc, onClick) {
        var virtualPatientState = doc.virtualPatientState;
        var sexColor = virtualPatientState.staticData.patientProfile.patientType === PatientType.female1yo ? "text-mirno-purple" : "text-mirno-accent-blue";
        return (_jsx("div", __assign({ className: "cursor-pointer hover:brightness-125", onClick: onClick }, { children: _jsx("div", __assign({ className: 'rounded-md shadow-md p-2 bg-mirno-dark text-mirno-light' }, { children: _jsxs("div", __assign({ className: 'flex gap-2' }, { children: [_jsx("div", __assign({ className: "h-full self-center pr-2 pl-2 ".concat(sexColor) }, { children: _jsx(FaUser, { className: 'text-2xl' }) })), _jsxs("div", __assign({ className: 'flex flex-col w-full' }, { children: [_jsxs("div", __assign({ className: 'w-full flex' }, { children: [_jsxs("div", __assign({ className: 'flex flex-col w-full' }, { children: [_jsx("div", __assign({ className: 'font-bold' }, { children: virtualPatientState.name || 'Untitled' })), _jsx("div", __assign({ className: 'text-sm text-gray-400' }, { children: virtualPatientState.staticData.patientProfile.patientType }))] })), _jsx("div", __assign({ className: 'flex flex-col text-sm w-full' }, { children: _jsx("div", __assign({ className: 'text-sm text-gray-400 overflow-hidden text-ellipsis italic', style: {
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: 'vertical',
                                                } }, { children: virtualPatientState.description || 'No description' })) }))] })), _jsx("div", __assign({ className: 'flex text-sm justify-between text-mirno-accent-pink opacity-50' }, { children: _jsxs("div", { children: [doc.metadata.ownerName, doc.metadata.email ? " - " + doc.metadata.email : ""] }) }))] }))] })) })) }), key));
    }
    return (_jsx(Transition, __assign({ show: open, enter: "transition-opacity duration-500", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition-opacity duration-500", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx("div", __assign({ className: "fixed inset-0 z-[9999999] bg-black bg-opacity-80 flex justify-center items-center" }, { children: _jsxs("div", __assign({ className: "bg-white p-6 rounded-lg shadow-lg w-1/2 max-h-full flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex mb-4 items-center" }, { children: [_jsx("h2", __assign({ className: "text-xl font-bold" }, { children: "Virtual Patients" })), _jsx("button", __assign({ className: "ml-auto", onClick: onClose }, { children: _jsx(FaX, {}) }))] })), authedUser != null ? (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "flex flex-col gap-2 overflow-y-auto" }, { children: presets.map(function (doc, key) { return presetContainer(key, doc, function () {
                                onSelection(doc);
                            }); }) })) })) : (_jsxs(_Fragment, { children: [_jsx(UserProfileComponent, {}), _jsx("button", __assign({ className: "bg-blue-500 text-white px-4 py-2 rounded", onClick: onClose }, { children: "Close" }))] }))] })) })) })));
};
export default LoadModal;
