var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useModifiedValues } from "./ControllerUtils";
import SimpleInputControl from "./InputComponents/SimpleInputControl";
import AnimatedSymptomValueBox from "./AnimatedSymptomValueBox";
import { useVirtualPatientStore } from '../../../storage/VirtualPatientStore';
var labelMapping = {
    headBobbing: "Head Bobbing",
    nasalFlaring: "Nasal Flaring",
    retractions: "Retractions",
    sterno: "Sterno",
};
var WorkOfBreathingController = function () {
    var _a = useVirtualPatientStore(), virtualPatientState = _a.virtualPatientState, updateVirtualPatientState = _a.updateVirtualPatientState;
    var modifiedValues = useModifiedValues("workOfBreathing");
    function onChange(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.workOfBreathing[key] = value;
        });
    }
    return (_jsx(_Fragment, { children: modifiedValues.map(function (key, id) {
            var value = virtualPatientState.medicalState.workOfBreathing[key];
            return (_jsx(AnimatedSymptomValueBox, __assign({ value: value }, { children: _jsx(SimpleInputControl, { label: labelMapping[key], value: value, onChange: function (newValue) { onChange(key, newValue); } }) }), id));
        }) }));
};
export default WorkOfBreathingController;
