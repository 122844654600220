import { jsx as _jsx } from "react/jsx-runtime";
import { Route } from "react-router-dom";
import { AppProvider } from "../../AppContext";
import { SessionProvider } from "../Sessions/SessionContext";
import TeamLeadPrototypeView from "./TeamLeadPrototypeView";
var TeamLeadPrototypeIndex = function () {
    return (_jsx(AppProvider, { children: _jsx(SessionProvider, { children: _jsx(TeamLeadPrototypeView, {}) }) }));
};
export var TeamLeadPrototypeRoutes = [
    _jsx(Route, { path: "/team-lead-prototype", element: _jsx(TeamLeadPrototypeIndex, {}) })
];
export default TeamLeadPrototypeIndex;
