var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// File: src/components/AssignmentEditor/AssignmentForm.tsx
import React from 'react';
import { TextInput, Textarea, Card, Text } from "@tremor/react";
var AssignmentForm = function (_a) {
    var assignment = _a.assignment, patientData = _a.patientData, onAssignmentChange = _a.onAssignmentChange, onPatientSelect = _a.onPatientSelect;
    return (_jsxs(_Fragment, { children: [_jsx(TextInput, { className: "mb-4", placeholder: "Assignment Name", value: assignment.name, onChange: function (e) { return onAssignmentChange('name', e.target.value); } }), _jsx(Textarea, { className: "mb-4", placeholder: "Introduction", value: assignment.introduction, onChange: function (e) { return onAssignmentChange('introduction', e.target.value); } }), _jsx(Card, __assign({ className: "mb-4 cursor-pointer", onClick: onPatientSelect }, { children: patientData ? (_jsxs(_Fragment, { children: [_jsx(Text, { children: patientData.virtualPatientState.name }), _jsx(Text, { children: patientData.virtualPatientState.description })] })) : (_jsx(Text, { children: "Click to select a patient" })) }))] }));
};
export default AssignmentForm;
