var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
var PlatformLayout = function () {
    return (_jsxs("div", __assign({ className: "flex h-screen bg-gray-100" }, { children: [_jsx(Sidebar, {}), _jsx("main", __assign({ className: "flex-1 overflow-x-hidden overflow-y-auto bg-gray-100" }, { children: _jsx("div", __assign({ className: "container mx-auto p-8" }, { children: _jsx(Outlet, {}) })) }))] })));
};
export default PlatformLayout;
