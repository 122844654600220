var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
var TextInputForm = function (_a) {
    var onSubmit = _a.onSubmit;
    var inputRef = useRef(null);
    var handleSubmit = function (event) {
        event.preventDefault();
        if (inputRef.current && inputRef.current.value.trim()) {
            onSubmit(inputRef.current.value);
            inputRef.current.value = '';
        }
    };
    return (_jsxs("form", __assign({ onSubmit: handleSubmit, className: "flex flex-col items-center p-4" }, { children: [_jsx("div", __assign({ className: "mb-4 w-full" }, { children: _jsx("input", { type: "text", ref: inputRef, className: "w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500", placeholder: "Type something..." }) })), _jsx("button", __assign({ type: "submit", className: "px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500" }, { children: "Submit" }))] })));
};
export default TextInputForm;
