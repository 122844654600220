import { z } from "zod";
export var FluidInterventionSchema = z.object({
    fluidType: z.string().optional(),
    volume: z
        .string()
        .describe("eg 100mL, 1L")
        .optional(),
    rate: z
        .string()
        .describe("eg 200mL/minute")
        .optional(),
    route: z
        .enum([
        "Oral",
        "IV",
        "IM",
        "IO",
        "Intraperitoneal",
        "Subcutaneous",
        "Rectal"
    ])
        .optional(),
    userRequest: z.string().optional()
});
