var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CustomSlider from '../../../../components/Reuseable/CustomSlider';
import { LungImageComponent } from '../../../../components/Reuseable/LungSelect';
import NumericalInput from '../../../../components/Reuseable/NumericalInput';
import { useVirtualPatientStore } from '../../../../storage/VirtualPatientStore';
export function useBreathingSound() {
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    function setBreathingSound(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.breathingSounds[key] = value;
        });
    }
    return { setBreathingSound: setBreathingSound };
}
var AirwayBreathingContent = function () {
    var _a = useVirtualPatientStore(), virtualPatientState = _a.virtualPatientState, updateVirtualPatientState = _a.updateVirtualPatientState;
    var setBreathingSound = useBreathingSound().setBreathingSound;
    var medicalState = virtualPatientState.medicalState;
    function setRespiratoryRate(value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.vitals.respiratoryRate = value;
        });
    }
    function onLungStateChange(lungId, newState) {
        updateVirtualPatientState(function (state) {
            state.medicalState.breathingSounds["".concat(lungId, "LungSound")] = newState;
        });
    }
    function setAccessoryMuscleUse(value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.workOfBreathing.headBobbing = value;
            state.medicalState.workOfBreathing.retractions = value;
            state.medicalState.workOfBreathing.sterno = value;
        });
    }
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "mb-4 flex flex-col gap-2" }, { children: [_jsx("span", __assign({ className: "text-md font-bold" }, { children: "Vitals" })), _jsx(NumericalInput, { id: "breathingRate", label: "Respiratory Rate", units: "Breaths per minute", value: medicalState.vitals.respiratoryRate, setValue: setRespiratoryRate })] })), _jsxs("div", __assign({ id: "breathingSounds", className: "mb-4" }, { children: [_jsx("span", __assign({ className: "text-md font-bold" }, { children: "Breathing Sounds" })), _jsx(CustomSlider, { label: "Normal", value: medicalState.breathingSounds.normal, setValue: function (value) { return setBreathingSound('normal', value); } }), _jsx(CustomSlider, { label: "Stridor", value: medicalState.breathingSounds.stridor, setValue: function (value) { return setBreathingSound('stridor', value); } })] })), _jsxs("div", __assign({ id: "respiratoryEffort", className: "mb-4" }, { children: [_jsx("span", __assign({ className: "text-md font-bold" }, { children: "Work of Breathing" })), _jsx(CustomSlider, { label: "Accessory Muscle Use", value: (medicalState.workOfBreathing.sterno +
                            medicalState.workOfBreathing.retractions +
                            medicalState.workOfBreathing.headBobbing) / 3, setValue: setAccessoryMuscleUse }), _jsx(CustomSlider, { label: "Nasal Flaring", value: medicalState.workOfBreathing.nasalFlaring, setValue: function (value) { return updateVirtualPatientState(function (state) { state.medicalState.workOfBreathing.nasalFlaring = value; }); } })] })), _jsxs("div", __assign({ className: "mb-4" }, { children: [_jsx("span", __assign({ className: "text-md font-bold" }, { children: "Lung Ascultation Sounds" })), _jsx(LungImageComponent, { leftLungState: medicalState.breathingSounds.leftLungSound, rightLungState: medicalState.breathingSounds.rightLungSound, onLungStateChange: onLungStateChange })] }))] }));
};
export default AirwayBreathingContent;
