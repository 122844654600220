var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { motion } from "framer-motion";
import styles from "./DemoMode.module.css";
;
export var ControlsButton = function (_a) {
    var onClick = _a.onClick, children = _a.children, active = _a.active;
    return (_jsx("div", __assign({ className: "relative w-full h-full pointer-events-auto cursor-pointer", onClick: onClick }, { children: children })));
};
export var ControlsMenu = function (_a) {
    var children = _a.children, props = _a.props;
    var alignmentStyle = "left-0 top-0 translate-y-[-100%]";
    if (props.controlsMenuAlignment === "right") {
        alignmentStyle = "left-full bottom-0";
    }
    return (_jsx(motion.div, __assign({ className: "absolute w-max gap-1 \n            rounded-md bg-mirno-dark p-1 flex flex-col overflow-auto pointer-events-auto\n            ".concat(alignmentStyle, "\n            "), initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: {
            duration: 0.2,
        } }, { children: children })));
};
export var ControlsMenuButton = function (_a) {
    var onClick = _a.onClick, children = _a.children, _b = _a.active, active = _b === void 0 ? false : _b;
    return (_jsx("button", __assign({ className: "w-full border border-white rounded text-white p-1 rounded-md uppercase text-xs\n            ".concat(styles.demoButton, "\n            ").concat(active ? styles["demoButton--highlighted"] : "", "\n        "), onClick: onClick }, { children: children })));
};
