var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import AssignmentResponseStorage from "../../competency/AssignmentResponseStorage";
var AssignmentReviewList = function () {
    var authedUser = useAppContext().authedUser;
    var _a = useState(null), assignmentsToReview = _a[0], setAssignmentsToReview = _a[1];
    var navigate = useNavigate();
    var location = useLocation();
    var handleAssignmentClick = function (assignmentResponseId) {
        navigate("".concat(location.pathname, "?assignmentResponseId=").concat(assignmentResponseId));
    };
    useEffect(function () {
        function loadAssignments() {
            return __awaiter(this, void 0, void 0, function () {
                var response, _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!authedUser)
                                return [2 /*return*/];
                            _b = (_a = AssignmentResponseStorage).getAssignmentsToReview;
                            return [4 /*yield*/, authedUser.getIdToken()];
                        case 1: return [4 /*yield*/, _b.apply(_a, [_c.sent()])];
                        case 2:
                            response = _c.sent();
                            console.log(response);
                            setAssignmentsToReview(response);
                            return [2 /*return*/];
                    }
                });
            });
        }
        loadAssignments();
    }, [authedUser]);
    return (_jsx("div", __assign({ className: "min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8" }, { children: _jsxs("div", __assign({ className: "max-w-4xl mx-auto" }, { children: [assignmentsToReview === null && _jsx("p", __assign({ className: "text-center text-lg text-gray-600" }, { children: "Loading..." })), assignmentsToReview && assignmentsToReview.length === 0 && _jsx("p", __assign({ className: "text-center text-lg text-gray-600" }, { children: "No assignments to review" })), assignmentsToReview && assignmentsToReview.length > 0 && (_jsxs("div", __assign({ className: "bg-white shadow overflow-hidden sm:rounded-lg" }, { children: [_jsxs("div", __assign({ className: "px-4 py-5 sm:px-6 border-b border-gray-200" }, { children: [_jsx("h1", __assign({ className: "text-lg leading-6 font-medium text-gray-900" }, { children: "Assignments to Review" })), _jsx("p", __assign({ className: "mt-1 max-w-2xl text-sm text-gray-500" }, { children: "Click on an assignment to review it." }))] })), _jsx("div", __assign({ className: "px-4 py-5 sm:p-0" }, { children: _jsx("dl", __assign({ className: "sm:divide-y sm:divide-gray-200" }, { children: assignmentsToReview.map(function (assignment, index) { return (_jsxs("div", __assign({ className: "py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 cursor-pointer hover:bg-gray-50", onClick: function () { return handleAssignmentClick(assignment.id.toString()); } }, { children: [_jsxs("dt", __assign({ className: "text-sm font-medium text-gray-500" }, { children: [assignment.assignmentId, " - ", assignment.userName] })), _jsx("dd", { className: "mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" })] }), index)); }) })) }))] })))] })) })));
};
export default AssignmentReviewList;
