export var HeartSound;
(function (HeartSound) {
    HeartSound["Normal"] = "Normal";
    HeartSound["AorticRegurgitation"] = "AorticRegurgitation";
    HeartSound["AorticStenosis"] = "AorticStenosis";
    HeartSound["FixedSplittingSecondHeart"] = "FixedSplittingSecondHeart";
    HeartSound["FourthHeartSound"] = "FourthHeartSound";
    HeartSound["InnocentMurmur"] = "InnocentMurmur";
    HeartSound["MildSystolicClick"] = "MildSystolicClick";
    HeartSound["MiralRegurgitation"] = "MiralRegurgitation";
    HeartSound["MiralStenosis"] = "MiralStenosis";
    HeartSound["MitralValveLeafletProlapse"] = "MitralValveLeafletProlapse";
    HeartSound["SplittingSecondHeart"] = "SplittingSecondHeart";
    HeartSound["ThirdHeartSound"] = "ThirdHeartSound";
})(HeartSound || (HeartSound = {}));
export var CirculationMetadata = {
    capillaryRefillSeconds: {
        description: "peripheral measurement",
        unit: "seconds"
    },
    heartSound: {
        description: "when auscultated",
        options: Object.keys(HeartSound)
    }
};
