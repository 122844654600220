var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Title } from '@tremor/react';
import LatestVirtualPatients from './LatestVirtualPatients';
import TopRatedVirtualPatients from './TopRatedVirtualPatients';
var VirtualPatientsIndex = function () {
    return (_jsxs("div", __assign({ className: "max-w-7xl mx-auto flex flex-col gap-4" }, { children: [_jsx(Title, { children: "Virtual Patients" }), _jsx(LatestVirtualPatients, {}), _jsx(TopRatedVirtualPatients, {})] })));
};
export default VirtualPatientsIndex;
