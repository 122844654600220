var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var NumericalInput = function (_a) {
    var id = _a.id, label = _a.label, units = _a.units, value = _a.value, setValue = _a.setValue, valueRange = _a.valueRange;
    return (_jsxs("div", __assign({ className: "relative" }, { children: [_jsx("label", __assign({ htmlFor: id, className: "block font-medium text-gray-700" }, { children: label })), _jsxs("div", __assign({ className: "relative mt-1" }, { children: [_jsx("input", { type: "number", id: id, name: id, className: "block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500", value: value, onChange: function (e) { return setValue(Number(e.target.value)); }, max: valueRange ? valueRange[1] : undefined, min: valueRange ? valueRange[0] : 0 }), _jsx("div", __assign({ className: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8" }, { children: _jsx("span", __assign({ className: "text-xs text-gray-400" }, { children: units })) }))] }))] })));
};
export default NumericalInput;
