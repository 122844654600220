var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import ArcwareView from '../../components/Arcware/Arcware';
import UserProfileComponent from '../../components/Reuseable/UserProfileComponent';
import PatientEditorSidebar from './Sidebar/PatientEditorSidebar';
import { useAppContext } from '../../AppContext';
import SidebarMenuBar from './Sidebar/MenuBar';
import DemoControlGroup from '../../components/DemoControls/DemoControlCommon';
import DevicesControl from '../../components/DemoControls/DevicesControl';
import ExaminationControl from '../../components/DemoControls/ExaminationControl';
import StethoscopeControl from '../../components/DemoControls/StethoscopeControl';
var AdvancedPatientEditor = function () {
    var isModified = useAppContext().isModified;
    var showSidebar = React.useState(true)[0];
    useEffect(function () {
        var handleWindowClose = function (e) {
            if (!isModified())
                return;
            e.preventDefault();
            return (e.returnValue = 'Unsaved changes');
        };
        window.addEventListener('beforeunload', handleWindowClose);
        return function () { return window.removeEventListener('beforeunload', handleWindowClose); };
    }, [isModified]);
    return (_jsx("div", __assign({ className: 'flex flex-col w-full h-full overflow-hidden' }, { children: _jsxs("div", __assign({ className: 'flex w-full overflow-hidden flex-grow relative' }, { children: [_jsxs("div", __assign({ id: "Sidebar", className: "text-gray-700 text-xs bg-white flex flex-col overflow-auto\n          ".concat(showSidebar ? 'w-1/4' : 'w-0', " transition-width duration-300 ease-in-out") }, { children: [_jsxs(_Fragment, { children: [_jsx(SidebarMenuBar, {}), _jsx(PatientEditorSidebar, {})] }), _jsx("div", __assign({ id: "BottomBar", className: 'mt-auto p-1 flex items-center' }, { children: _jsx(UserProfileComponent, {}) }))] })), _jsx("div", __assign({ id: "ue5", className: "flex-grow h-full" }, { children: _jsx(ArcwareView, {}) })), _jsx("div", __assign({ className: 'absolute bottom-0 left-1/4 h-auto p-2 gap-2 flex flex-col z-[999999999999999]' }, { children: _jsxs(DemoControlGroup, { children: [_jsx(StethoscopeControl, { controlsMenuAlignment: "right" }), _jsx(ExaminationControl, { controlsMenuAlignment: "right" }), _jsx(DevicesControl, { controlsMenuAlignment: "right" })] }) }))] })) })));
};
export default AdvancedPatientEditor;
