var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DefaultVirtualPatientState } from "../../DefaultState";
import { GeneratePatientDescription } from "../Agents/GenerateNameAndDescription";
import { GeneratePatientName } from "../Agents/GeneratePatientName";
import { GeneratePatientStaticData } from "../Agents/Physiology/GenerateNewPatient";
import { GenerateVitalSigns } from "../Agents/Physiology/GenerateVitals";
import { MapBreathingParams } from "../Agents/Physiology/MapBreathingParams";
import { MapConsciousnessParams } from "../Agents/Physiology/MapConsciousnessParams";
import { MapSkinParams } from "../Agents/Physiology/MapSkinParams";
import { PaigenFunctionImplementor } from "./LLMFunctionUtils";
var GenerateNewPatientImplementor = /** @class */ (function (_super) {
    __extends(GenerateNewPatientImplementor, _super);
    function GenerateNewPatientImplementor() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toolDefinition = {
            definition: {
                name: "generateNewPatient",
                description: "Generates a new patient based on the provided description.",
                parameters: {
                    type: "object",
                    properties: {
                        description: {
                            type: "string",
                        },
                    },
                    required: ["description"],
                },
            },
            implementation: function (args) { return __awaiter(_this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.generateNewPatient(args.description)];
                });
            }); }
        };
        return _this;
    }
    GenerateNewPatientImplementor.prototype.generateNewPatient = function (description) {
        return __awaiter(this, void 0, void 0, function () {
            var messageId, generatedPatientState, _a, vitals, tasks, totalTasks, completedTasks, updateProgress, promises, results, breathingParams, skinParams, consciousnessParams, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        messageId = this.chatManager.addUIMessage({ progress: 0, text: "Generating Virtual Patient profile..." });
                        generatedPatientState = JSON.parse(JSON.stringify(DefaultVirtualPatientState));
                        _a = generatedPatientState;
                        return [4 /*yield*/, GeneratePatientStaticData(description, this.chatManager.openai)];
                    case 1:
                        _a.staticData = _c.sent();
                        //         this.chatManager.updateUIMessage(messageId, {progress: 0.1, text: "Assessing request..."});
                        //         const virtualPatientSymptomRequirements = await EvaluateVirtualPatientRequirementsAgent(JSON.stringify(generatedPatientState.staticData, null, 4), this.chatManager.openai);
                        //         const capabilitiesResult = await CheckSystemCapabilitiesAgent(JSON.stringify(virtualPatientSymptomRequirements, null, 4), this.chatManager.openai);
                        //         if (!capabilitiesResult.ok) {
                        //             return `
                        // The Virtual Patient isn't capable of the following, which may be required:
                        // ${capabilitiesResult.missingCapabilities?.join("\n")}
                        // Ask if the user would like to continue without these symptoms, or if they would like to do something else.
                        //             `.trim();
                        //         }
                        this.chatManager.updateUIMessage(messageId, { progress: 0.2, text: "Generating vital signs..." });
                        return [4 /*yield*/, GenerateVitalSigns(description, generatedPatientState.staticData, this.chatManager.openai)];
                    case 2:
                        vitals = _c.sent();
                        generatedPatientState.medicalState.vitals = {
                            etco2: vitals.etco2,
                            heartRate: vitals.heartRate,
                            nibp: vitals.nibp,
                            respiratoryRate: vitals.respiratoryRate,
                            spo2: vitals.spo2,
                        };
                        this.chatManager.setGeneratedPatient(generatedPatientState);
                        this.chatManager.updateUIMessage(messageId, { progress: 0.4, text: "Mapping Virtual Patient parameters..." });
                        tasks = [
                            { name: "breathing", promise: MapBreathingParams(JSON.stringify(generatedPatientState.staticData, null, 4), JSON.stringify(vitals, null, 4), this.chatManager.openai) },
                            { name: "skin", promise: MapSkinParams(JSON.stringify(generatedPatientState.staticData, null, 4), JSON.stringify(vitals, null, 4), this.chatManager.openai) },
                            { name: "consciousness", promise: MapConsciousnessParams(JSON.stringify(generatedPatientState.staticData, null, 4), JSON.stringify(vitals, null, 4), this.chatManager.openai) },
                            { name: "patient name", promise: GeneratePatientName(generatedPatientState.staticData, this.chatManager.openai) }
                        ];
                        totalTasks = tasks.length;
                        completedTasks = 0;
                        updateProgress = function () {
                            completedTasks++;
                            var progress = 0.4 + (completedTasks / totalTasks) * 0.6;
                            _this.chatManager.updateUIMessage(messageId, { progress: progress, text: "Generating new patient..." });
                        };
                        promises = tasks.map(function (task) {
                            return task.promise.then(function (result) {
                                updateProgress();
                                return { name: task.name, result: result };
                            });
                        });
                        return [4 /*yield*/, Promise.allSettled(promises)];
                    case 3:
                        results = _c.sent();
                        generatedPatientState.name = results.find(function (res) { return res.value.name === "patient name"; }).value.result;
                        breathingParams = results.find(function (res) { return res.value.name === "breathing"; }).value.result;
                        skinParams = results.find(function (res) { return res.value.name === "skin"; }).value.result;
                        consciousnessParams = results.find(function (res) { return res.value.name === "consciousness"; }).value.result;
                        if (breathingParams) {
                            generatedPatientState.medicalState.breathingSounds = breathingParams.breathingSounds;
                            generatedPatientState.medicalState.workOfBreathing = breathingParams.workOfBreathing;
                            this.chatManager.setGeneratedPatient(generatedPatientState);
                        }
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 1000); })];
                    case 4:
                        _c.sent();
                        if (skinParams) {
                            generatedPatientState.medicalState.skin = skinParams.skinState;
                            generatedPatientState.medicalState.exposure = skinParams.exposureState;
                            this.chatManager.setGeneratedPatient(generatedPatientState);
                        }
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 1000); })];
                    case 5:
                        _c.sent();
                        if (consciousnessParams) {
                            generatedPatientState.medicalState.disability = consciousnessParams;
                            this.chatManager.setGeneratedPatient(generatedPatientState);
                        }
                        _b = generatedPatientState;
                        return [4 /*yield*/, GeneratePatientDescription(description, generatedPatientState, this.chatManager.openai)];
                    case 6:
                        _b.description = _c.sent();
                        this.chatManager.setGeneratedPatient(generatedPatientState);
                        this.chatManager.updateUIMessage(messageId, "Success!");
                        return [2 /*return*/, "Success!\n        \n".concat(JSON.stringify(generatedPatientState, null, 4), "\n\nYou don't need to repeat the above info to the user - it's displayed in UI - just give a very brief summary of any elements you decided on yourself.\nNow ask the user for any additional information needed to complete the scenario, and ask them to review the look and sound of the Virtual Patient.\n").trim()];
                }
            });
        });
    };
    return GenerateNewPatientImplementor;
}(PaigenFunctionImplementor));
export default GenerateNewPatientImplementor;
