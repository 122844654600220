var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { DrugDefinitions, InterventionDefinitions, AdministrationRouteDefinitions } from './AutocompleteData';
import { IoMdSend } from "react-icons/io";
var DefinitionType;
(function (DefinitionType) {
    DefinitionType["Drug"] = "Drug";
    DefinitionType["Intervention"] = "Intervention";
    DefinitionType["AdministrationRoute"] = "AdministrationRoute";
    DefinitionType["FreeText"] = "FreeText";
})(DefinitionType || (DefinitionType = {}));
var AllDefinitions = __spreadArray(__spreadArray(__spreadArray([], DrugDefinitions.map(function (value) { return { name: value, type: DefinitionType.Drug }; }), true), InterventionDefinitions.map(function (value) { return { name: value, type: DefinitionType.Intervention }; }), true), AdministrationRouteDefinitions.map(function (value) { return { name: value, type: DefinitionType.AdministrationRoute }; }), true);
var AllDefinitionTypes = Object.values(DefinitionType);
var recipes = [
    [DefinitionType.Drug, DefinitionType.AdministrationRoute, DefinitionType.FreeText],
    [DefinitionType.Intervention],
    [DefinitionType.Intervention, DefinitionType.FreeText],
    [DefinitionType.FreeText]
];
var SimActionInterface = function () {
    var _a;
    var _b = useState(''), inputValue = _b[0], setInputValue = _b[1];
    var _c = useState(false), showSuggestions = _c[0], setShowSuggestions = _c[1];
    var _d = useState([]), autocompleteSuggestions = _d[0], setAutocompleteSuggestions = _d[1];
    var _e = useState([]), actionComposition = _e[0], setActionComposition = _e[1];
    var handleInputChange = function (event) {
        var value = event.target.value;
        setInputValue(value);
        if (value.length > 0) {
            var availableDefinitionTypes = AllDefinitionTypes;
            if (actionComposition.length > 0) {
                availableDefinitionTypes = [];
                recipes.forEach(function (recipe) {
                    recipe.forEach(function (typeInRecipe) {
                        if (actionComposition.some(function (selected) { return selected.type === typeInRecipe; })) {
                            availableDefinitionTypes.push.apply(availableDefinitionTypes, recipe.filter(function (type) { return actionComposition.find(function (selected) { return selected.type === type; }) === undefined; }));
                        }
                    });
                });
            }
            var regex_1 = new RegExp("".concat(value), 'i');
            var filteredDefinitions = AllDefinitions
                .filter(function (definition) { return availableDefinitionTypes.includes(definition.type); })
                .filter(function (definition) { return regex_1.test(definition.name); });
            setAutocompleteSuggestions(filteredDefinitions);
            setShowSuggestions(true);
        }
        else {
            setShowSuggestions(false);
        }
    };
    var handleSuggestionClick = function (suggestion) {
        setActionComposition(__spreadArray(__spreadArray([], actionComposition, true), [suggestion], false));
        setInputValue("");
        setShowSuggestions(false);
    };
    var handleEnterClicked = function (event) {
        event.preventDefault();
        // console.log('Form submitted with input:', inputValue);
        if (inputValue.trim().length > 0) {
            setActionComposition(__spreadArray(__spreadArray([], actionComposition, true), [{ name: inputValue.trim(), type: DefinitionType.FreeText }], false));
        }
        setInputValue("");
        setShowSuggestions(false);
    };
    var sendSelections = function () {
        console.log('Submitting action:', actionComposition);
        setActionComposition([]);
    };
    var isRecipeComplete = recipes.some(function (recipe) {
        return recipe.every(function (type) {
            return actionComposition.some(function (selected) { return selected.type === type; });
        });
    });
    var typeToColorMap = (_a = {},
        _a[DefinitionType.Drug] = "bg-blue-500",
        _a[DefinitionType.Intervention] = "bg-green-500",
        _a[DefinitionType.AdministrationRoute] = "bg-yellow-500",
        _a[DefinitionType.FreeText] = "bg-gray-500",
        _a);
    return (_jsx("div", __assign({ className: "p-4" }, { children: _jsxs("div", __assign({ className: "space-x-2 m-2 flex" }, { children: [actionComposition.map(function (selection, index) { return (_jsx("span", __assign({ className: "px-2 py-1 rounded-md text-white ".concat(typeToColorMap[selection.type]) }, { children: selection.name }), index)); }), _jsxs("form", __assign({ onSubmit: handleEnterClicked, className: "space-x-2" }, { children: [_jsx("input", { type: "text", value: inputValue, onChange: handleInputChange, className: "form-input w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent", placeholder: "Type here..." }), showSuggestions && (_jsx("ul", __assign({ className: "absolute bg-white border border-gray-300 mt-1 rounded-md z-10" }, { children: autocompleteSuggestions.map(function (suggestion, index) { return (_jsxs("li", __assign({ className: "px-4 py-2 hover:bg-gray-100 cursor-pointer", onClick: function () { return handleSuggestionClick(suggestion); } }, { children: [suggestion.name, " - ", suggestion.type] }), index)); }) })))] })), isRecipeComplete && (_jsx("button", __assign({ onClick: sendSelections, className: "px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600" }, { children: _jsx(IoMdSend, {}) })))] })) })));
};
export default SimActionInterface;
