var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from "react";
var SimpleDropdownControl = function (_a) {
    var label = _a.label, value = _a.value, options = _a.options, onChange = _a.onChange;
    var _b = useState(false), isEditing = _b[0], setIsEditing = _b[1];
    var selectRef = useRef(null);
    useEffect(function () {
        if (isEditing && selectRef.current) {
            selectRef.current.focus();
            selectRef.current.click();
        }
    }, [isEditing, selectRef]);
    var handleBlur = function () {
        setIsEditing(false);
    };
    var handleChange = function (e) {
        onChange(e.target.value);
        setIsEditing(false);
    };
    var handleDivClick = function () {
        setIsEditing(true);
    };
    return (_jsxs("div", __assign({ className: "cursor-pointer flex gap-2 w-full", onClick: handleDivClick }, { children: [_jsx("p", __assign({ className: "font-bold w-full" }, { children: label })), isEditing ? (_jsx("select", __assign({ ref: selectRef, value: value, onChange: handleChange, onBlur: handleBlur, className: "p-0 pl-1 text-xs w-full" }, { children: options.map(function (option) { return (_jsx("option", __assign({ value: option }, { children: option }), option)); }) }))) : (_jsx("span", __assign({ className: "pl-1 ml-auto pr-2" }, { children: value })))] })));
};
export default SimpleDropdownControl;
