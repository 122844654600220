var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
var AssignmentQuestionEditor = function (_a) {
    var assignment = _a.assignment, selectedQuestionId = _a.selectedQuestionId, onAssignmentChange = _a.onAssignmentChange;
    var _b = useState(0), selectedOptionId = _b[0], setSelectedOptionId = _b[1];
    var _c = useState(assignment.questions[selectedQuestionId].question), questionText = _c[0], setQuestionText = _c[1];
    useEffect(function () {
        setQuestionText(assignment.questions[selectedQuestionId].question);
    }, [selectedQuestionId, assignment]);
    function onOptionSelected(index) {
        setSelectedOptionId(index);
    }
    function handleRemoveOption(index) {
        var newOptions = __spreadArray([], assignment.questions[selectedQuestionId].options, true);
        newOptions.splice(index, 1);
        var updatedAssignment = __assign({}, assignment);
        updatedAssignment.questions[selectedQuestionId].options = newOptions;
        onAssignmentChange(updatedAssignment);
        setSelectedOptionId(0); // reset to first option
    }
    function handleAddOption() {
        var newOption = { answer: '', learningInfo: '' };
        var updatedAssignment = __assign({}, assignment);
        updatedAssignment.questions[selectedQuestionId].options.push(newOption);
        onAssignmentChange(updatedAssignment);
        setSelectedOptionId(updatedAssignment.questions[selectedQuestionId].options.length - 1); // set to newly added option
    }
    function handleOptionChange(event, field) {
        var _a;
        var newOptions = __spreadArray([], assignment.questions[selectedQuestionId].options, true);
        newOptions[selectedOptionId] = __assign(__assign({}, newOptions[selectedOptionId]), (_a = {}, _a[field] = event.target.value, _a));
        var updatedAssignment = __assign({}, assignment);
        updatedAssignment.questions[selectedQuestionId].options = newOptions;
        onAssignmentChange(updatedAssignment);
    }
    function handleQuestionChange(event) {
        setQuestionText(event.target.value);
        var updatedAssignment = __assign({}, assignment);
        updatedAssignment.questions[selectedQuestionId].question = event.target.value;
        onAssignmentChange(updatedAssignment);
    }
    function handleCorrectOptionChange(event) {
        var index = Number(event.target.value);
        var updatedAssignment = __assign({}, assignment);
        updatedAssignment.questions[selectedQuestionId].correctAnswerId = index;
        onAssignmentChange(updatedAssignment);
    }
    return (_jsxs("div", __assign({ className: 'flex gap-1 p-1 bg-gray-50 rounded-lg shadow-md h-full' }, { children: [_jsxs("div", __assign({ className: 'w-1/2 h-full overflow-hidden flex flex-col' }, { children: [_jsxs("div", __assign({ className: 'mb-1 text-xs font-bold text-gray-700 flex' }, { children: [_jsx("textarea", { value: questionText, onChange: handleQuestionChange, className: 'w-full p-1 border border-gray-300 rounded-lg text-xs' }), _jsx("select", __assign({ className: 'p-1 border border-gray-300 rounded-lg text-xs', value: assignment.questions[selectedQuestionId].correctAnswerId, onChange: handleCorrectOptionChange }, { children: assignment.questions[selectedQuestionId].options.map(function (option, index) { return (_jsx("option", __assign({ value: index }, { children: index + 1 }), index)); }) }))] })), _jsx("div", __assign({ className: 'grow overflow-y-scroll' }, { children: _jsxs("div", __assign({ className: "flex flex-col p-1 bg-white rounded-lg shadow-lg" }, { children: [assignment.questions[selectedQuestionId].options.map(function (option, index) {
                                    var isSelected = selectedOptionId === index;
                                    return (_jsxs("div", __assign({ className: "flex flex-row items-center p-1 mb-1 rounded-lg cursor-pointer transition-colors duration-200 ".concat(isSelected ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-100'), onClick: function () { onOptionSelected(index); } }, { children: [_jsx("div", __assign({ className: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full text-white-800 font-bold mr-2 ".concat(index === assignment.questions[selectedQuestionId].correctAnswerId ? 'bg-green-200' : 'bg-red-200') }, { children: index + 1 })), _jsx("div", __assign({ className: "flex-grow overflow-hidden whitespace-nowrap overflow-ellipsis text-xs" }, { children: option.answer })), _jsx("button", __assign({ onClick: function (e) {
                                                    e.stopPropagation();
                                                    handleRemoveOption(index);
                                                }, className: "ml-2 bg-red-500 text-white p-1 rounded-lg button-sm text-xs" }, { children: "X" }))] }), index));
                                }), _jsx("button", __assign({ onClick: handleAddOption, className: "mt-2 bg-green-500 text-white p-1 rounded-lg text-xs" }, { children: "Add Answer" }))] })) }))] })), _jsx("div", __assign({ className: 'flex-grow' }, { children: _jsxs("div", __assign({ className: 'flex flex-col gap-2' }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ className: 'block mb-1 font-semibold text-gray-600 text-xs' }, { children: "Answer" })), _jsx("input", { type: 'text', value: assignment.questions[selectedQuestionId].options[selectedOptionId].answer, onChange: function (e) { return handleOptionChange(e, 'answer'); }, className: 'w-full p-1 border border-gray-300 rounded-lg text-xs' })] }), _jsxs("div", { children: [_jsx("label", __assign({ className: 'block mb-1 font-semibold text-gray-600 text-xs' }, { children: "Learning Info" })), _jsx("textarea", { value: assignment.questions[selectedQuestionId].options[selectedOptionId].learningInfo, onChange: function (e) { return handleOptionChange(e, 'learningInfo'); }, className: 'w-full p-1 border border-gray-300 rounded-lg text-xs' })] })] })) }))] })));
};
export default AssignmentQuestionEditor;
