var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, motion } from "framer-motion";
import MirnoIcon from "../../assets/mirno_icon.png";
import React from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
var CinemedicOptionsButton = function (_a) {
    var className = _a.className;
    return (_jsx(motion.div, __assign({ whileTap: { scale: 1.2 }, className: "w-8 h-8 cursor-pointer hover:brightness-125" }, { children: _jsx("img", { src: MirnoIcon, alt: "Mirno Icon", className: "h-full w-full aspect-square" }) })));
};
var CinemedicOptionsControls = function (props) {
    return (_jsxs(_Fragment, { children: [_jsx(MenuItem, { children: _jsx(motion.div, __assign({ className: "w-full bg-slate-700 rounded p-2 pr-4 hover:brightness-125 text-left text-white text-sm cursor-pointer", onClick: props.onLoadNewPatient }, { children: "Load new patient" })) }), _jsx(MenuItem, { children: _jsx(motion.div, __assign({ className: "w-full bg-slate-700 rounded p-2 pr-4 hover:brightness-125 text-left text-white text-sm cursor-pointer", onClick: props.onShowSessionOptions }, { children: "Session options" })) })] }));
};
var CinemedicOptions = function (props) {
    return (_jsx("div", __assign({ className: "absolute w-full h-full z-[999999999] pointer-events-none" }, { children: _jsx(AnimatePresence, { children: _jsx("div", __assign({ className: "absolute bottom-0 left-0 z-10 m-2 pointer-events-auto" }, { children: _jsxs(Menu, { children: [_jsx(MenuButton, __assign({ className: "m-2" }, { children: _jsx(CinemedicOptionsButton, {}) })), _jsx(MenuItems, __assign({ transition: true, anchor: "top", className: "z-[999999999] [--anchor-gap:0.25rem] ml-4 bg-slate-800 rounded shadow flex flex-col gap-2 p-1 transition duration-200 ease-out data-[closed]:scale-50 data-[closed]:opacity-0 origin-bottom-left w-56" }, { children: _jsx(CinemedicOptionsControls, __assign({}, props)) }))] }) })) }) })));
};
export default CinemedicOptions;
