var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import InfoDisplay from './InfoDisplay';
var AirwayDisplay = function (_a) {
    var data = _a.data, onAccept = _a.onAccept, onCancel = _a.onCancel;
    function hint() {
        return "".concat(data.userRequest);
    }
    return (_jsxs(InfoDisplay, __assign({ className: 'bg-green-100', hint: hint(), infoTypeName: 'Airway Management', onAccept: onAccept, onCancel: onCancel }, { children: [data.procedure && _jsx("span", __assign({ className: 'font-bold' }, { children: data.procedure })), data.intubation &&
                _jsxs(_Fragment, { children: [data.intubation.method && _jsx("span", { children: data.intubation.method }), data.intubation.tubeSize && _jsx("span", { children: data.intubation.tubeSize }), data.intubation.medications && _jsx("span", { children: data.intubation.medications })] }), data.mechanicalVentilation && _jsxs(_Fragment, { children: [data.mechanicalVentilation.mode && _jsxs("span", { children: ["mode: ", data.mechanicalVentilation.mode] }), data.mechanicalVentilation.tidalVolume && _jsxs("span", { children: ["tidal volume: ", data.mechanicalVentilation.tidalVolume] }), data.mechanicalVentilation.respiratoryRate && _jsxs("span", { children: ["RR: ", data.mechanicalVentilation.respiratoryRate] }), data.mechanicalVentilation.peep && _jsxs("span", { children: ["peep: ", data.mechanicalVentilation.peep] }), data.mechanicalVentilation.fio2 && _jsxs("span", { children: ["fio2: ", data.mechanicalVentilation.fio2] })] }), data.nonInvasiveVentilation && _jsxs(_Fragment, { children: [data.nonInvasiveVentilation.mode && _jsxs("span", { children: ["mode: ", data.nonInvasiveVentilation.mode] }), data.nonInvasiveVentilation.inspiratoryPressure && _jsxs("span", { children: ["IP: ", data.nonInvasiveVentilation.inspiratoryPressure] }), data.nonInvasiveVentilation.expiratoryPressure && _jsxs("span", { children: ["EP: ", data.nonInvasiveVentilation.expiratoryPressure] })] }), data.oxygenTherapy && _jsxs(_Fragment, { children: [data.oxygenTherapy.method && _jsx("span", { children: data.oxygenTherapy.method }), data.oxygenTherapy.flowRate && _jsx("span", { children: data.oxygenTherapy.flowRate })] })] })));
};
export default AirwayDisplay;
