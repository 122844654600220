var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Message, MessageList, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import { marked } from 'marked';
import ProgressBar from "@ramonak/react-progress-bar";
var renderer = new marked.Renderer();
renderer.link = function (_a) {
    var href = _a.href, title = _a.title, text = _a.text;
    return "<a href=\"".concat(href, "\" target=\"_blank\" rel=\"noopener noreferrer\">").concat(text, "</a>");
};
var renderPaigenMessageListContent = function (_a) {
    var messages = _a.messages, incomingMessageId = _a.incomingMessageId, incomingMessageText = _a.incomingMessageText, isWaitingForResponse = _a.isWaitingForResponse;
    var currentUserName = "";
    var changedMessageDirection = false;
    var previousMessageDirection = "";
    return (_jsxs(MessageList, __assign({ scrollBehavior: "auto", autoScrollToBottom: true, autoScrollToBottomOnMount: true, typingIndicator: isWaitingForResponse ? _jsx(TypingIndicator, { content: "Thinking..." }) : null }, { children: [messages.map(function (message, i) {
                changedMessageDirection = false;
                var renderableMessage = __assign({}, message);
                var className = "";
                if ('tool_calls' in message) {
                    className = "tool-call";
                    var toolCallMessage = message;
                    renderableMessage.message = toolCallMessage.tool_calls.map(function (toolCall) {
                        return "Tool call: ".concat(toolCall.function.name, "(").concat(toolCall.function.arguments, ")");
                    }).join("\n");
                }
                else if (message.role === "tool") {
                    renderableMessage.message = "Tool result: ".concat(message.content);
                    className = "tool-result";
                }
                else if (message.role === "ui") {
                    if (typeof (message.content) === "string") {
                        renderableMessage.payload = message.content;
                    }
                    else {
                        if (message.content.hasOwnProperty("progress")) {
                            var progressMessage = message.content;
                            renderableMessage.type = "custom";
                            renderableMessage.payload = (_jsxs("div", __assign({ className: 'flex flex-col gap-2' }, { children: [_jsx("span", { children: progressMessage.text }), _jsx(ProgressBar, { completed: progressMessage.progress * 100, className: "progressBar", isLabelVisible: false })] })));
                        }
                        else {
                            renderableMessage.payload = JSON.stringify(message.content);
                        }
                    }
                    className = message.role;
                }
                else {
                    renderableMessage.payload = marked(message.content, { renderer: renderer }).toString().trim();
                    className = message.role;
                }
                renderableMessage.direction = message.role === "user" ? "outgoing" : "incoming";
                if (renderableMessage.direction !== previousMessageDirection) {
                    previousMessageDirection = renderableMessage.direction;
                    currentUserName = { outgoing: "You", incoming: "Maia" }[renderableMessage.direction];
                    changedMessageDirection = true;
                }
                return (_jsxs(_Fragment, { children: [changedMessageDirection &&
                            _jsx("div", __assign({ className: "userLabel ".concat(currentUserName) }, { children: currentUserName })), _jsx(Message, { model: renderableMessage, className: className }, i)] }));
            }), incomingMessageText &&
                _jsx(_Fragment, { children: _jsx(Message, { model: { message: marked(incomingMessageText, { renderer: renderer }).toString().trim(), direction: "incoming" }, className: 'assistant' }, incomingMessageId) })] })));
};
export default renderPaigenMessageListContent;
