export var DrugDefinitions = [
    "Acetaminophen",
    "Aspirin",
    "Ibuprofen",
    "Naproxen",
    "Amoxicillin",
    "Ceftriaxone",
    "Diphenhydramine",
    "Lorazepam",
    "Midazolam",
    "Epinephrine",
    "Albuterol",
    "Salbutamol",
    "Prednisolone",
    "Hydrocortisone",
    "Methylprednisolone",
    "Furosemide",
    "Mannitol",
    "Ondansetron",
    "Ranitidine",
    "Dexamethasone",
    "Atropine",
    "Adenosine",
    "Magnesium sulfate",
    "Naloxone",
    "Flumazenil",
    "Vancomycin",
    "Gentamicin",
    "Clindamycin",
    "Metronidazole",
    "Levetiracetam",
    "Phenytoin",
    "Dobutamine",
    "Dopamine",
    "Nitroglycerin",
    "Glucose (Dextrose)",
    "Insulin",
    "Morphine",
    "Fentanyl",
    "Ketamine",
    "Lidocaine",
    "Procainamide",
    "Amiodarone",
    "Sodium bicarbonate",
    "Calcium gluconate",
    "Calcium chloride",
    "Zinc sulfate",
    "Vitamin K",
    "Prochlorperazine",
    "Promethazine",
    "Normal saline",
    "Lactated Ringer's solution",
    "5% Dextrose in water",
    "0.45% Sodium chloride (Half-normal saline)",
    "10% Dextrose in water",
    "Albumin solution",
    "Fresh frozen plasma",
    "Sodium chloride 3% (Hypertonic saline)",
    "Potassium chloride",
    "Magnesium chloride",
];
export var AdministrationRouteDefinitions = [
    "Oral",
    "Intravenous (IV)",
    "Intramuscular (IM)",
    "Subcutaneous (SC)",
    "Rectal",
    "Inhalational",
    "Nebulizer",
    "Topical",
    "Transdermal",
    "Sublingual",
    "Buccal",
    "Nasal",
    "Ophthalmic",
    "Otic",
    "Intraosseous (IO)",
    "Intrathecal",
    "Epidural",
    "Intraperitoneal",
    "Intracardiac",
    "Intravesical", // Medications administered directly into the bladder.
];
export var InterventionDefinitions = [
    "Chin lift",
    "Jaw thrust",
    "IV access",
    "Chest compression",
    "Defibrillation",
    "Intubation",
    "Ventilation",
    "Oxygen administration",
    "Cardiac monitoring",
    "Blood pressure monitoring",
    "Temperature monitoring",
    "Blood glucose testing",
    "ECG",
    "CPR",
    "Administration of medications",
    "Wound dressing",
    "Application of splints",
    "Application of tourniquet",
    "Catheter insertion",
    "Nasogastric tube insertion",
    "Gastric lavage",
    "Lumbar puncture",
    "Thoracentesis",
    "Paracentesis",
    "Pericardiocentesis",
    "Bone marrow biopsy",
    "Arterial blood gas sampling",
    "Central venous pressure monitoring",
    "Pulse oximetry",
    "Ultrasound",
    "X-ray examination",
    "CT scan",
    "MRI scan",
    "Suturing of lacerations",
    "Incision and drainage",
    "Reduction of fractures",
    "Reduction of dislocations",
    "Electrocardiographic monitoring",
    "Physical restraint",
    "Psychological de-escalation",
];
