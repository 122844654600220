var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Card, Title, Text, Badge, List, ListItem } from '@tremor/react';
import { BookOpen, Users, Clock, Tablet, Film, Gamepad2 } from 'lucide-react';
var getApplicationIcon = function (app) {
    switch (app) {
        case 'micromedic':
            return _jsx(Tablet, { className: "w-4 h-4 text-blue-500" });
        case 'cinemedic':
            return _jsx(Film, { className: "w-4 h-4 text-purple-500" });
        case 'playground':
            return _jsx(Gamepad2, { className: "w-4 h-4 text-green-500" });
    }
};
var OngoingAssignments = function () {
    var assignments = [
        {
            name: "Sepsis Management",
            virtualPatient: {
                title: "Sepsis in a 1 year old girl",
                version: "2.1"
            },
            studentsComplete: 15,
            studentsWaiting: 8,
            applications: ['micromedic', 'cinemedic']
        },
        {
            name: "Fever Assessment",
            virtualPatient: {
                title: "Pediatric Fever Management",
                version: "1.0"
            },
            studentsComplete: 7,
            studentsWaiting: 12,
            applications: ['micromedic', 'playground']
        },
        {
            name: "Respiratory Distress Evaluation",
            virtualPatient: {
                title: "Asthma Exacerbation in a 5-year-old",
                version: "1.2"
            },
            studentsComplete: 20,
            studentsWaiting: 3,
            applications: ['cinemedic']
        }
    ];
    return (_jsxs(Card, { children: [_jsxs(Title, __assign({ className: "flex items-center" }, { children: [_jsx(BookOpen, { className: "w-5 h-5 text-blue-500 mr-2" }), "Ongoing Assignments"] })), _jsx(List, __assign({ className: "mt-4" }, { children: assignments.map(function (assignment, index) { return (_jsx(ListItem, __assign({ className: "!block" }, { children: _jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsxs("div", __assign({ className: "flex-grow" }, { children: [_jsx(Text, __assign({ className: "font-bold" }, { children: assignment.name })), _jsxs(Text, __assign({ className: "text-sm text-gray-600" }, { children: ["Linked to: ", assignment.virtualPatient.title, " (v", assignment.virtualPatient.version, ")"] })), _jsx("div", __assign({ className: "flex mt-1 space-x-2" }, { children: assignment.applications.map(function (app, i) { return (_jsx("div", __assign({ className: "flex items-center", title: app }, { children: getApplicationIcon(app) }), i)); }) }))] })), _jsxs("div", __assign({ className: "flex items-center space-x-4" }, { children: [_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx(Users, { className: "w-4 h-4 text-green-500 mr-1" }), _jsxs(Text, __assign({ className: "text-sm" }, { children: [assignment.studentsComplete, " complete"] }))] })), _jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx(Clock, { className: "w-4 h-4 text-yellow-500 mr-1" }), _jsxs(Text, __assign({ className: "text-sm" }, { children: [assignment.studentsWaiting, " waiting"] }))] })), _jsx(Badge, __assign({ size: "sm", color: "blue" }, { children: "View Details" }))] }))] })) }), index)); }) }))] }));
};
export default OngoingAssignments;
