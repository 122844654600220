var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PatientSelector from "../../components/PatientSelection/PatientSelector";
import CinemedicModal from "./CinemedicModal";
import { useSession } from "../Sessions/SessionContext";
import { motion } from "framer-motion";
import React from "react";
import { IoMdContract, IoMdExpand } from "react-icons/io";
var CinemedicLoadingScreen = function (_a) {
    var onSelection = _a.onSelection, onClose = _a.onClose, canClose = _a.canClose;
    var sessionQRCode = useSession().sessionQRCode;
    var _b = React.useState(false), expandQR = _b[0], setExpandQR = _b[1];
    return (_jsx(CinemedicModal, __assign({ title: "Cinemedic", subtitle: "Scan QR code to connect admin device, or select Virtual Patient.", onClose: onClose, canClose: canClose }, { children: _jsxs(motion.div, __assign({ layout: true, className: "h-full flex overflow-hidden w-full relative" }, { children: [_jsxs(motion.div, __assign({ className: "h-full z-50 origin-top-left bg-slate-700", style: {
                        width: expandQR ? "32rem" : "8rem",
                        transition: "0.3s"
                    } }, { children: [_jsx(motion.div, __assign({ className: "w-full h-auto aspect-square p-2" }, { children: sessionQRCode("cinemedicController") })), _jsx("div", __assign({ className: "text-xl w-full flex justify-center" }, { children: _jsx("div", __assign({ className: "rounded-full bg-white/20 p-2 cursor-pointer", onClick: function () { return setExpandQR(function (prev) { return !prev; }); } }, { children: expandQR ? _jsx(IoMdContract, {}) : _jsx(IoMdExpand, {}) })) }))] })), _jsx(PatientSelector, { onSelection: onSelection, className: "h-full w-full min-w-96 overflow-hidden" })] })) })));
};
export default CinemedicLoadingScreen;
