var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useVirtualPatientStore } from '../../../../storage/VirtualPatientStore';
var PresetInfo = function () {
    var _a = useVirtualPatientStore(), virtualPatientState = _a.virtualPatientState, updateVirtualPatientState = _a.updateVirtualPatientState;
    function setPresetName(name) {
        updateVirtualPatientState(function (state) {
            state.name = name;
        });
    }
    function setPresetDescription(description) {
        updateVirtualPatientState(function (state) {
            state.description = description;
        });
    }
    return (_jsx("div", { children: _jsxs("div", __assign({ id: "patientType", className: "mb-4" }, { children: [_jsx("span", __assign({ className: "text-md font-bold" }, { children: "Preset" })), _jsxs("div", __assign({ className: "mb-4 flex flex-col gap-2" }, { children: [_jsx("input", { type: "text", id: "presetName", name: "presetName", placeholder: "Preset name", className: "block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-violet-500 focus:border-violet-500", value: virtualPatientState.name || "", onChange: function (e) { return setPresetName(e.target.value); } }), _jsx("textarea", { placeholder: "Description", value: virtualPatientState.description || "", className: "block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-violet-500 focus:border-violet-500", onChange: function (e) { return setPresetDescription(e.target.value); } })] }))] })) }));
};
export default PresetInfo;
