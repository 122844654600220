import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import AdvancedPatientEditor from './AdvancedPatientEditor';
var EditorIndex = function () {
    useEffect(function () {
        document.title = 'Mirno Advanced Editor';
    }, []);
    return (_jsx(AdvancedPatientEditor, {}));
};
export default EditorIndex;
