var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { DefaultMedicalState } from 'mirno-shared/src/DefaultState';
import CirculationController from './Circulation';
import { getModifiedValues } from './ControllerUtils';
import VitalsController from './Vitals';
import DisabilityController from './Disability';
import SkinController from './Skin';
import WorkOfBreathingController from './WorkOfBreathing';
import BloodGasController from './BloodGas';
import BreathingSoundsController from './BreathingSounds';
import ExposureController from './Exposure';
import { useVirtualPatientStore } from '../../../storage/VirtualPatientStore';
import { AnimatePresence, motion } from 'framer-motion';
import Monitor from '../../../components/Monitor/Monitor';
import BloodGasReport from '../../../components/Reuseable/ReportDisplays/BloodGasReport';
import { GenerateBloodGas } from 'mirno-shared/src/paigen/Agents/Physiology/GenerateBloodGas';
import OpenAI from 'openai';
var ControllerMap = {
    vitals: { title: "Vitals", component: VitalsController },
    bloodGas: { title: "Blood Gas", component: BloodGasController },
    breathingSounds: { title: "Breathing Sounds", component: BreathingSoundsController },
    circulation: { title: "Circulation", component: CirculationController },
    disability: { title: "Disability", component: DisabilityController },
    exposure: { title: "Exposure", component: ExposureController },
    skin: { title: "Skin", component: SkinController },
    workOfBreathing: { title: "Work of Breathing", component: WorkOfBreathingController },
};
var SymptomControlDisplay = function () {
    var _a = useVirtualPatientStore(), virtualPatientState = _a.virtualPatientState, updateVirtualPatientState = _a.updateVirtualPatientState;
    var _b = React.useState([]), modifiedSections = _b[0], setModifiedSections = _b[1];
    var _c = React.useState(true), expanded = _c[0], setExpanded = _c[1];
    var _d = React.useState(false), showMonitor = _d[0], setShowMonitor = _d[1];
    var _e = React.useState(false), showBloodGasReport = _e[0], setShowBloodGasReport = _e[1];
    var _f = React.useState(false), generatingBloodGas = _f[0], setGeneratingBloodGas = _f[1];
    useEffect(function () {
        setModifiedSections(function () {
            var modifiedSections = getModifiedValues(DefaultMedicalState, virtualPatientState.medicalState);
            // ensure vitals is always first
            if (modifiedSections.includes("vitals")) {
                modifiedSections.splice(modifiedSections.indexOf("vitals"), 1);
                // Sort all other sections alphabetically by title
                modifiedSections.sort(function (a, b) {
                    var titleA = ControllerMap[a].title;
                    var titleB = ControllerMap[b].title;
                    return titleA.localeCompare(titleB);
                });
                modifiedSections.unshift("vitals");
            }
            else {
                modifiedSections.unshift("vitals");
            }
            return modifiedSections;
        });
    }, [virtualPatientState.medicalState]);
    function onGenerateBloodGas() {
        return __awaiter(this, void 0, void 0, function () {
            var openai, generatedBloodGas;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        openai = new OpenAI({
                            apiKey: process.env.REACT_APP_OPENAI_API_KEY,
                            dangerouslyAllowBrowser: true,
                        });
                        setGeneratingBloodGas(true);
                        return [4 /*yield*/, GenerateBloodGas(virtualPatientState, openai)];
                    case 1:
                        generatedBloodGas = _a.sent();
                        updateVirtualPatientState(function (state) {
                            state.medicalState.bloodGas = generatedBloodGas;
                        });
                        setGeneratingBloodGas(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs("div", __assign({ className: 'flex flex-col bg-none w-full h-full overflow-hidden relative' }, { children: [_jsxs("div", __assign({ className: 'bg-mirno-dark text-mirno-light cursor-pointer p-2 select-none flex w-full z-[999999]' }, { children: [virtualPatientState.name ? virtualPatientState.name : "Untitled", " - ", virtualPatientState.staticData.patientProfile.patientType.toString(), _jsx("p", __assign({ className: 'ml-auto pointer-events-auto', onClick: function () { return setShowMonitor(function (prev) { return !prev; }); } }, { children: showMonitor ? "Hide Monitor" : "Show Monitor" })), _jsx("p", __assign({ className: 'ml-auto pointer-events-auto', onClick: function () { return setShowBloodGasReport(function (prev) { return !prev; }); } }, { children: showBloodGasReport ? "Hide Blood Gas" : "Show Blood Gas" })), _jsx("p", __assign({ className: 'ml-auto pointer-events-auto', onClick: function () { return setExpanded(!expanded); } }, { children: expanded ? "Symptoms >" : "< Symptoms" }))] })), expanded &&
                _jsx(motion.div, __assign({ className: "ml-auto z-[999999] max-h-full overflow-x-hidden bg-red flex flex-col gap-1" }, { children: _jsx(AnimatePresence, { children: modifiedSections.map(function (sectionName) {
                            var ControllerComponent = ControllerMap[sectionName].component;
                            return ControllerComponent ? (_jsx(ControllerComponent, {}, sectionName)) : null;
                        }) }) })), _jsx(AnimatePresence, { children: showBloodGasReport &&
                    _jsx(motion.div, __assign({ id: "blood-gas", className: 'absolute left-0 right-0 top-8 z-[9999] flex items-center justify-center', initial: "collapsed", animate: "open", exit: "collapsed", variants: {
                            open: { opacity: 1, translateY: "0%" },
                            collapsed: { opacity: 0, translateY: "-100%" }
                        }, transition: { duration: 0.3, ease: 'easeInOut' } }, { children: _jsxs("div", __assign({ className: 'rounded-b p-4 bg-mirno-dark/80 shadow-lg text-white pointer-events-auto' }, { children: [_jsx("h2", __assign({ className: "text-xl font-bold text-center mb-4" }, { children: "Blood Gas Report" })), virtualPatientState.medicalState.bloodGas ?
                                    _jsx("div", __assign({ className: 'bg-white text-mirno-dark' }, { children: _jsx(BloodGasReport, {}) }))
                                    :
                                        _jsxs("div", __assign({ className: 'flex flex-col items-center gap-2' }, { children: [_jsx("p", { children: "No blood gas data available" }), generatingBloodGas ?
                                                    _jsx("p", { children: "Generating blood gas..." })
                                                    :
                                                        _jsx("button", __assign({ className: 'rounded bg-mirno-green hover:shadow p-2', onClick: onGenerateBloodGas }, { children: "Generate Blood Gas" }))] }))] })) })) }), _jsx(AnimatePresence, { children: showMonitor &&
                    _jsx(motion.div, __assign({ className: 'absolute bottom-0 right-0 w-[1000px] z-[999999] origin-bottom-right scale-50 m-2', initial: "collapsed", animate: "open", exit: "collapsed", variants: {
                            open: { opacity: 1 },
                            collapsed: { opacity: 0 }
                        } }, { children: _jsx(Monitor, { vitals: virtualPatientState.medicalState.vitals, deviceState: {
                                ecg: true,
                                pulseOximeterLeftFoot: true,
                                pulseOximeterRightFoot: false,
                                nibpLeftArm: true,
                                nibpRightArm: false,
                                nasalCannula: true,
                                ivCatheterLeftArm: true,
                                ivCatheterLeftHand: false,
                                ivCatheterRightArm: false,
                                ivCatheterRightHand: false
                            } }) })) })] })));
};
export default SymptomControlDisplay;
