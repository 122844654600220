var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import InfoDisplay from './InfoDisplay';
var FluidDisplay = function (_a) {
    var _b = _a.data, fluidType = _b.fluidType, volume = _b.volume, rate = _b.rate, route = _b.route, userRequest = _b.userRequest, onAccept = _a.onAccept, onCancel = _a.onCancel;
    function hint() {
        return "".concat(userRequest);
    }
    return (_jsxs(InfoDisplay, __assign({ className: 'bg-orange-100', hint: hint(), infoTypeName: 'Fluids', onAccept: onAccept, onCancel: onCancel }, { children: [fluidType && _jsx("span", { children: fluidType }), volume ? _jsx("span", { children: volume }) : _jsx("span", __assign({ className: "text-red-400" }, { children: "No volume" })), rate ? _jsx("span", { children: rate }) : _jsx("span", __assign({ className: "text-red-400" }, { children: "No rate" })), route && _jsx("span", { children: route })] })));
};
export default FluidDisplay;
