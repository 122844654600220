var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// File: src/components/AssignmentEditor/QuestionList.tsx
import React from 'react';
import { Card, TextInput, Textarea, Button } from "@tremor/react";
import { Check, X, Loader } from "lucide-react";
var CustomCheckbox = function (_a) {
    var isCorrect = _a.isCorrect, isSelected = _a.isSelected, onChange = _a.onChange;
    return (_jsx("div", __assign({ className: "w-6 h-6 border rounded flex items-center justify-center cursor-pointer flex-shrink-0 ".concat(isSelected ? (isCorrect ? 'bg-green-500 border-green-600' : 'bg-red-500 border-red-600') : 'bg-white border-gray-300'), onClick: onChange }, { children: isSelected && (isCorrect ? (_jsx(Check, { size: 16, color: "white" })) : (_jsx(X, { size: 16, color: "white" }))) })));
};
var QuestionList = function (_a) {
    var questions = _a.questions, onQuestionChange = _a.onQuestionChange, onOptionChange = _a.onOptionChange, onAddOption = _a.onAddOption, onCorrectAnswerChange = _a.onCorrectAnswerChange, onAddQuestion = _a.onAddQuestion, isGeneratingQuestion = _a.isGeneratingQuestion;
    return (_jsxs(_Fragment, { children: [questions.map(function (question, qIndex) { return (_jsxs(Card, __assign({ className: "mb-4" }, { children: [_jsx(TextInput, { className: "mb-2", placeholder: "Patient ID", value: question.patientId, onChange: function (e) { return onQuestionChange(qIndex, 'patientId', e.target.value); } }), _jsx(Textarea, { className: "mb-2", placeholder: "Question", value: question.question, onChange: function (e) { return onQuestionChange(qIndex, 'question', e.target.value); } }), question.options.map(function (option, oIndex) { return (_jsxs("div", __assign({ className: "flex flex-col mb-2 p-2 rounded ".concat(question.correctAnswerId === oIndex
                            ? 'bg-green-50'
                            : 'bg-red-50') }, { children: [_jsxs("div", __assign({ className: "flex items-center mb-2" }, { children: [_jsx(CustomCheckbox, { isCorrect: question.correctAnswerId === oIndex, isSelected: true, onChange: function () { return onCorrectAnswerChange(qIndex, oIndex); } }), _jsxs("span", __assign({ className: "ml-2 font-semibold" }, { children: ["Option ", oIndex + 1] }))] })), _jsx(Textarea, { className: "mb-2", placeholder: "Option ".concat(oIndex + 1), value: option.answer, onChange: function (e) { return onOptionChange(qIndex, oIndex, 'answer', e.target.value); } }), _jsx(Textarea, { placeholder: "Learning Info (optional)", value: option.learningInfo || '', onChange: function (e) { return onOptionChange(qIndex, oIndex, 'learningInfo', e.target.value); } })] }), oIndex)); }), _jsx(Button, __assign({ size: "xs", variant: "secondary", onClick: function () { return onAddOption(qIndex); }, className: "mt-2" }, { children: "Add Option" }))] }), qIndex)); }), _jsx(Button, __assign({ onClick: onAddQuestion, disabled: isGeneratingQuestion, className: "mt-4" }, { children: isGeneratingQuestion ? (_jsxs(_Fragment, { children: [_jsx(Loader, { className: "animate-spin mr-2", size: 16 }), "Generating Question..."] })) : ('Add Question') }))] }));
};
export default QuestionList;
