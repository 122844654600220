var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ReactComponent as LungsImage } from './lungs.svg';
import "./LungSelect.css";
import { LungSound } from 'mirno-shared/src/components/Breathing';
var hotspots = [
    { id: 'left', name: 'Left Lung', position: { top: '50%', left: '25%' } },
    { id: 'right', name: 'Right Lung', position: { top: '50%', left: '75%' } },
];
export var LungImageComponent = function (_a) {
    var leftLungState = _a.leftLungState, rightLungState = _a.rightLungState, onLungStateChange = _a.onLungStateChange;
    var handleSelectChange = function (e, id) {
        onLungStateChange(id, e.target.value);
    };
    return (_jsxs("div", __assign({ className: "relative ml-4 mr-4" }, { children: [_jsx(LungsImage, { className: "w-full h-auto lungs-image" }), hotspots.map(function (hotspot) { return (_jsx("select", __assign({ value: hotspot.id === 'left' ? leftLungState : rightLungState, onChange: function (e) { return handleSelectChange(e, hotspot.id); }, className: "lung-sound-select absolute bg-blue-500 text-white p-2 rounded-full transform -translate-x-1/2 -translate-y-1/2", style: { top: hotspot.position.top, left: hotspot.position.left } }, { children: Object.entries(LungSound).map(function (_a) {
                    var key = _a[0], value = _a[1];
                    return (_jsx("option", __assign({ value: value }, { children: value }), key));
                }) }), hotspot.id)); })] })));
};
