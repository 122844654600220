import React, { useEffect } from "react";
import { DefaultMedicalState } from "mirno-shared/src/DefaultState";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
export function getModifiedValues(defaultData, currentData) {
    var modifiedValues = [];
    Object.keys(currentData).forEach(function (keyName) {
        var key = keyName;
        if (!defaultData) {
            modifiedValues.push(key);
        }
        else if (!defaultData.hasOwnProperty(key)) {
            modifiedValues.push(key);
        }
        else if (JSON.stringify(currentData[key]) !== JSON.stringify(defaultData[key])) {
            modifiedValues.push(key);
        }
    });
    return modifiedValues;
}
;
export function useModifiedValues(dataKey) {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var _a = React.useState([]), modifiedValues = _a[0], setModifiedValues = _a[1];
    useEffect(function () {
        if (dataKey && virtualPatientState.medicalState[dataKey]) {
            setModifiedValues(getModifiedValues(DefaultMedicalState[dataKey], virtualPatientState.medicalState[dataKey]));
        }
    }, [dataKey, virtualPatientState.medicalState]);
    return modifiedValues;
}
;
export function percentageIntensity(value) {
    value = Math.min(1, Math.max(0, value));
    return "".concat(Math.round(value * 100), " %");
}
export function describeIntensity(value) {
    var descriptions = [
        "None",
        "Very mild",
        "Mild",
        "Noticeable",
        "Moderate",
        "Somewhat significant",
        "Significant",
        "Very significant",
        "Intense",
        "Very intense",
        "Extreme" // 10
    ];
    if (value < 0 || value > 1) {
        throw new Error("Value must be between 0 and 1");
    }
    return descriptions[Math.round(value * 10)];
}
