var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// File: src/components/AssignmentEditor/AssignmentEditor.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Title } from "@tremor/react";
import LoadModal from "../Paigen/LoadModal";
import AssignmentForm from './AssignmentForm';
import QuestionList from './QuestionList';
import { useAssignmentEditData } from './useAssignmentEditData';
var AssignmentEditor = function () {
    var patientId = useParams().patientId;
    var _a = useAssignmentEditData(patientId), assignment = _a.assignment, patientData = _a.patientData, isModalOpen = _a.isModalOpen, isGeneratingQuestion = _a.isGeneratingQuestion, handleAssignmentChange = _a.handleAssignmentChange, addQuestion = _a.addQuestion, handleQuestionChange = _a.handleQuestionChange, handleOptionChange = _a.handleOptionChange, addOption = _a.addOption, handlePatientSelection = _a.handlePatientSelection, setIsModalOpen = _a.setIsModalOpen, handleCorrectAnswerChange = _a.handleCorrectAnswerChange;
    return (_jsxs("div", __assign({ className: "p-4 w-1/3 h-screen overflow-y-auto" }, { children: [_jsx(Title, { children: "Edit Assignment" }), _jsxs(Card, __assign({ className: "mt-4" }, { children: [_jsx(AssignmentForm, { assignment: assignment, patientData: patientData, onAssignmentChange: handleAssignmentChange, onPatientSelect: function () { return setIsModalOpen(true); } }), _jsx(QuestionList, { questions: assignment.questions, onQuestionChange: handleQuestionChange, onOptionChange: handleOptionChange, onAddOption: addOption, onCorrectAnswerChange: handleCorrectAnswerChange, onAddQuestion: addQuestion, isGeneratingQuestion: isGeneratingQuestion })] })), _jsx(LoadModal, { open: isModalOpen, onClose: function () { return setIsModalOpen(false); }, onSelection: handlePatientSelection })] })));
};
export default AssignmentEditor;
