var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Button_Background from "./Button_Background.svg";
import Button_Background_Active from "./Button_Background_Active.svg";
import ButtonIcon_Devices from "./ButtonIcon_Devices.svg";
import { useMenuControl } from "./DemoControlCommon";
import { useVirtualPatientStore } from "../../storage/VirtualPatientStore";
import { AnimatePresence } from "framer-motion";
import { ControlsButton, ControlsMenu, ControlsMenuButton } from "./DemoControlsCommon";
var DevicesControl = function (props) {
    var _a = useMenuControl('Devices'), isDemoMenuActive = _a.isDemoMenuActive, toggleDemoMenuActive = _a.toggleDemoMenuActive;
    var _b = useVirtualPatientStore(), virtualPatientState = _b.virtualPatientState, updateVirtualPatientState = _b.updateVirtualPatientState;
    function toggleDevice(val) {
        updateVirtualPatientState(function (state) {
            state.deviceStatus[val] = !state.deviceStatus[val];
            if (val === "nibpLeftArm" || val === "nibpRightArm") {
                var otherArm = val === "nibpLeftArm" ? "nibpRightArm" : "nibpLeftArm";
                state.deviceStatus[otherArm] = false;
            }
            if (val === "pulseOximeterLeftFoot" || val === "pulseOximeterRightFoot") {
                var otherFoot = val === "pulseOximeterLeftFoot" ? "pulseOximeterRightFoot" : "pulseOximeterLeftFoot";
                state.deviceStatus[otherFoot] = false;
            }
        });
    }
    function deviceButton(val, label) {
        var active = virtualPatientState.deviceStatus[val];
        return (_jsx(ControlsMenuButton, __assign({ onClick: function () { return toggleDevice(val); }, active: active }, { children: label })));
    }
    return (_jsxs("div", __assign({ className: "w-full relative" }, { children: [_jsxs(ControlsButton, __assign({ onClick: toggleDemoMenuActive, active: isDemoMenuActive }, { children: [_jsx("img", { src: isDemoMenuActive ? Button_Background_Active : Button_Background, alt: '', className: 'w-full h-full' }), _jsx("img", { src: ButtonIcon_Devices, alt: '', className: "absolute top-0 left-0 w-full h-full origin-center scale-[65%]\n                        ".concat(isDemoMenuActive ? 'opacity-100' : 'opacity-60', "\n                        ") })] })), _jsx(AnimatePresence, { children: isDemoMenuActive && (_jsxs(ControlsMenu, __assign({ props: props }, { children: [deviceButton("ecg", "ECG"), deviceButton("nasalCannula", "Nasal Cannula"), deviceButton("pulseOximeterLeftFoot", "Pulse Ox - Foot - L"), deviceButton("pulseOximeterRightFoot", "Pulse Ox - Foot - R"), deviceButton("nibpLeftArm", "NIBP - Arm - L"), deviceButton("nibpRightArm", "NIBP - Arm - R"), deviceButton("ivCatheterLeftArm", "IV - Arm - L"), deviceButton("ivCatheterRightArm", "IV - Arm - R"), deviceButton("ivCatheterLeftHand", "IV - Hand - L"), deviceButton("ivCatheterRightHand", "IV - Hand - R")] }))) })] })));
};
export default DevicesControl;
