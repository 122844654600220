var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var SectionSelect = function (_a) {
    var categories = _a.categories, currentCategoryIndex = _a.currentCategoryIndex, onCategoryChange = _a.onCategoryChange;
    return (_jsx("div", __assign({ className: "w-100 h-100 p-2" }, { children: categories.map(function (category, index) { return (_jsxs("div", __assign({ className: "flex items-center p-2 m-2 rounded cursor-pointer ".concat(currentCategoryIndex === index ? 'bg-gray-200' : 'hover:bg-gray-100'), onClick: function () { return onCategoryChange(index); } }, { children: [_jsx("div", __assign({ className: 'shrink-0 w-5' }, { children: category.icon })), _jsx("span", { children: category.name })] }), index)); }) })));
};
export default SectionSelect;
