var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import ArcwareView from '../../components/Arcware/Arcware';
import { QRCodeSVG } from 'qrcode.react';
import { SessionProvider, useSession } from '../Sessions/SessionContext';
var MegascreenView = function () {
    var _a = useSession(), sessionData = _a.fullSessionData, sessionId = _a.sessionId, createSession = _a.createSession;
    var _b = React.useState(''), sessionControllerURL = _b[0], setSessionControllerURL = _b[1];
    var _c = React.useState(false), controllerConnected = _c[0], setControllerConnected = _c[1];
    useEffect(function () {
        createSession();
    }, [createSession]);
    useEffect(function () {
        if (sessionId) {
            setSessionControllerURL("".concat(window.location.origin, "/session-controller?sessionId=").concat(sessionId));
        }
    }, [sessionId]);
    useEffect(function () {
        console.log("Received session data", sessionData);
        if (!sessionData || !sessionData.sessionData) {
            return;
        }
        if (sessionData.sessionData.controllerId) {
            setControllerConnected(true);
        }
    }, [sessionData]);
    return (_jsxs("div", __assign({ className: 'w-full h-full' }, { children: [_jsx("div", __assign({ id: "ue5-container", className: "flex-grow h-full relative" }, { children: _jsx(ArcwareView, {}) })), sessionId && !controllerConnected && (_jsx("div", __assign({ className: 'absolute left-0 top-0 z-[9999999] justify-end p-2 gap-2' }, { children: _jsx("a", __assign({ href: sessionControllerURL, target: '_blank', rel: "noreferrer" }, { children: _jsx(QRCodeSVG, { value: sessionControllerURL, bgColor: '#000000', fgColor: '#ffffff' }) })) })))] })));
};
var MegascreenIndex = function () {
    return (_jsx(SessionProvider, { children: _jsx(MegascreenView, {}) }));
};
export default MegascreenIndex;
