import { serverTimestamp } from 'firebase/firestore';
export function Metadata(userUid, userName, email) {
    return {
        ownerId: userUid,
        ownerName: userName,
        email: email,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
    };
}
