var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Button_Background from "./Button_Background.svg";
import Button_Background_Active from "./Button_Background_Active.svg";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { useMenuControl } from "./DemoControlCommon";
import { AnimatePresence } from "framer-motion";
import { ControlsButton, ControlsMenu, ControlsMenuButton } from "./DemoControlsCommon";
var ReportsControl = function (_a) {
    var onToggleBloodGasReport = _a.onToggleBloodGasReport, props = _a.props;
    var _b = useMenuControl('Reports'), isDemoMenuActive = _b.isDemoMenuActive, toggleDemoMenuActive = _b.toggleDemoMenuActive, deactivateDemoMenu = _b.deactivateDemoMenu;
    var onBloodGasClicked = function () {
        console.log("Blood Gas clicked");
        onToggleBloodGasReport();
        deactivateDemoMenu();
    };
    return (_jsxs("div", __assign({ className: "w-full h-full relative" }, { children: [_jsxs(ControlsButton, __assign({ onClick: toggleDemoMenuActive, active: isDemoMenuActive }, { children: [_jsx("img", { src: isDemoMenuActive ? Button_Background_Active : Button_Background, alt: '', className: 'w-full h-full' }), _jsx("div", __assign({ className: "absolute top-0 left-0 w-full h-full flex items-center justify-center text-white font-bold text-3xl\n                    ".concat(isDemoMenuActive ? 'opacity-100' : 'opacity-60', "\n                    ") }, { children: _jsx(HiOutlineClipboardDocument, {}) }))] })), _jsx(AnimatePresence, { children: isDemoMenuActive && (_jsx(ControlsMenu, __assign({ props: props }, { children: _jsx(ControlsMenuButton, __assign({ onClick: onBloodGasClicked }, { children: "Blood Gas" })) }))) })] })));
};
export default ReportsControl;
