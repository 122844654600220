var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
export function findParentNode(tree, nodeId) {
    if (!tree.children) {
        return null;
    }
    for (var _i = 0, _a = tree.children; _i < _a.length; _i++) {
        var child = _a[_i];
        if (child.renderId === nodeId) {
            return tree;
        }
        var parent = findParentNode(child, nodeId);
        if (parent) {
            return parent;
        }
    }
    return null;
}
var VisualTreeComponent = function (_a) {
    var data = _a.data, onNodeSelect = _a.onNodeSelect, onAddChild = _a.onAddChild;
    var _b = useState({}), nodeSizes = _b[0], setNodeSizes = _b[1];
    var _c = useState(null), selectedNodeId = _c[0], setSelectedNodeId = _c[1];
    var nodeRefs = useRef({});
    useEffect(function () {
        var newSizeMap = {};
        Object.keys(nodeRefs.current).forEach(function (key) {
            var node = nodeRefs.current[key];
            if (node) {
                newSizeMap[key] = {
                    width: node.offsetWidth,
                    height: node.offsetHeight,
                };
            }
        });
        setNodeSizes(newSizeMap);
    }, [data]);
    var handleNodeSelect = function (node) {
        setSelectedNodeId(node.renderId);
        onNodeSelect(node);
    };
    function renderNodeContent(_a) {
        var _b, _c, _d, _e, _f;
        var nodeDatum = _a.nodeDatum;
        var virtualPatientTreeNode = nodeDatum;
        return (_jsx("foreignObject", __assign({ width: ((_b = nodeSizes[virtualPatientTreeNode.renderId]) === null || _b === void 0 ? void 0 : _b.width) || 200, height: ((_c = nodeSizes[virtualPatientTreeNode.renderId]) === null || _c === void 0 ? void 0 : _c.height) || 100, x: -(((_d = nodeSizes[virtualPatientTreeNode.renderId]) === null || _d === void 0 ? void 0 : _d.width) || 200) / 2, y: -(((_e = nodeSizes[virtualPatientTreeNode.renderId]) === null || _e === void 0 ? void 0 : _e.height) || 100) / 2, className: 'overflow-hidden' }, { children: _jsxs("div", __assign({ ref: function (el) { return (nodeRefs.current[virtualPatientTreeNode.renderId] = el); }, className: "p-2 rounded-lg shadow-md flex flex-col items-center cursor-pointer h-full w-full overflow-auto ".concat(selectedNodeId === virtualPatientTreeNode.renderId ? 'bg-green-500' : 'bg-blue-500', " text-white"), onClick: function () { return handleNodeSelect(virtualPatientTreeNode); } }, { children: [((_f = virtualPatientTreeNode.data) === null || _f === void 0 ? void 0 : _f.id) && (_jsx("div", __assign({ className: "text-xs font-bold bg-white text-black rounded-full px-2 py-1 mb-1" }, { children: virtualPatientTreeNode.data.id }))), _jsx("div", __assign({ className: "font-bold" }, { children: virtualPatientTreeNode.name })), _jsx("div", __assign({ className: "text-sm mt-1" }, { children: virtualPatientTreeNode.description }))] })) })));
    }
    return (_jsx("div", __assign({ style: { width: '100%', height: '100%' } }, { children: _jsx(Tree, { data: data, renderCustomNodeElement: renderNodeContent, orientation: "vertical", pathFunc: "step", separation: { siblings: 2, nonSiblings: 2 }, translate: { x: 300, y: 100 }, zoom: 0.7 }) })));
};
export default VisualTreeComponent;
