var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { LungSound } from "mirno-shared/src/components/Breathing";
import { useModifiedValues } from "./ControllerUtils";
import SimpleInputControl from "./InputComponents/SimpleInputControl";
import SimpleDropdownControl from "./InputComponents/SimpleDropdownControl";
import AnimatedSymptomValueBox from "./AnimatedSymptomValueBox";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
var labelMapping = {
    grunting: "Grunting",
    wheezing: "Wheezing",
    stridor: "Stridor",
    gurgling: "Gurgling",
    leftLungSound: "Left Lung Sound",
    normal: "Normal",
    rightLungSound: "Right Lung Sound",
};
export function useBreathingSounds() {
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    function setBreathingSoundValue(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.breathingSounds[key] = value;
        });
    }
    return { setBreathingSoundValue: setBreathingSoundValue };
}
var BreathingSoundsController = function () {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var setBreathingSoundValue = useBreathingSounds().setBreathingSoundValue;
    var modifiedValues = useModifiedValues("breathingSounds");
    // const allKeys = Object.keys(virtualPatientState.medicalState.breathingSounds) as (keyof BreathingSoundState)[];
    var getOptions = function (key) {
        if (key === "leftLungSound" || key === 'rightLungSound') {
            return Object.keys(LungSound);
        }
        return [];
    };
    return (_jsx(_Fragment, { children: modifiedValues.map(function (key, index) {
            var value = virtualPatientState.medicalState.breathingSounds[key];
            return (_jsx(AnimatedSymptomValueBox, __assign({ value: value }, { children: typeof (value) === "number" ? (_jsx(SimpleInputControl, { label: labelMapping[key], value: value, onChange: function (value) { setBreathingSoundValue(key, value); } })) : (_jsx(SimpleDropdownControl, { label: labelMapping[key], value: value, options: getOptions(key), onChange: function (value) { setBreathingSoundValue(key, value); } })) }), index));
        }) }));
};
export default BreathingSoundsController;
