var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback } from 'react';
import SchemaProcessor from 'mirno-shared/src/paigen/ActionSchemas/SchemaProcessor';
import OpenAI from 'openai';
import debounce from 'lodash/debounce';
import { ClipLoader } from 'react-spinners';
import MedicationDisplay from './MedicationDisplay';
import FluidDisplay from './FluidDisplay';
import AirwayDisplay from './AirwayDisplay';
import PointOfCareTestDisplay from './PointOfCareTestDisplay';
import { AnimatePresence, motion } from 'framer-motion';
var schemaMapping = {
    "Medication": {
        renderer: MedicationDisplay
    },
    "Fluids": {
        renderer: FluidDisplay
    },
    "Airway": {
        renderer: AirwayDisplay
    },
    "PointOfCareTests": {
        renderer: PointOfCareTestDisplay
    }
};
export function GetSchemaRenderer(schemaResult, onAccept, onCancel) {
    if (!schemaResult.selectedSchema)
        return null;
    if (!schemaMapping[schemaResult.selectedSchema])
        return null;
    var RendererComponent = schemaMapping[schemaResult.selectedSchema].renderer;
    if (!RendererComponent)
        return null;
    return _jsx(RendererComponent, { data: schemaResult.filledSchema, onAccept: onAccept, onCancel: onCancel });
}
export var SchemaSelector = function (_a) {
    var onResultChange = _a.onResultChange, onResultSelected = _a.onResultSelected;
    var _b = useState(''), userInput = _b[0], setUserInput = _b[1];
    var _c = useState(null), result = _c[0], setResult = _c[1];
    var _d = useState(false), processing = _d[0], setProcessing = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var _f = useState(null), processor = _f[0], setProcessor = _f[1];
    useEffect(function () {
        var initializeProcessor = function () { return __awaiter(void 0, void 0, void 0, function () {
            var openai, newProcessor;
            return __generator(this, function (_a) {
                openai = new OpenAI({
                    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
                    dangerouslyAllowBrowser: true,
                });
                newProcessor = new SchemaProcessor(openai);
                setProcessor(newProcessor);
                return [2 /*return*/];
            });
        }); };
        initializeProcessor();
    }, []);
    var processInput = useCallback(function (input) { return __awaiter(void 0, void 0, void 0, function () {
        var processorResult, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!processor)
                        return [2 /*return*/];
                    setProcessing(true);
                    setError(null);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, processor.processUserInput(input)];
                case 2:
                    processorResult = _a.sent();
                    setResult(processorResult);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error processing user input:', error_1);
                    setError(error_1.message);
                    setResult(null);
                    return [3 /*break*/, 5];
                case 4:
                    setProcessing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [processor, setProcessing, setResult]);
    // eslint-disable-next-line
    var debouncedProcessInput = useCallback(debounce(function (input) {
        processInput(input);
    }, 500), [processInput]);
    var handleChange = function (e) {
        setUserInput(e.target.value);
        debouncedProcessInput(e.target.value);
    };
    useEffect(function () {
        if (onResultChange) {
            onResultChange(result);
        }
    }, [result, onResultChange]);
    // Clean up debounce on unmount
    React.useEffect(function () {
        return function () {
            debouncedProcessInput.cancel();
        };
    }, [debouncedProcessInput]);
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            processInput(userInput);
        }
    };
    function handleResultAccept() {
        if (!result)
            return;
        onResultSelected(result);
        setResult(null);
        setUserInput('');
    }
    function handleResultCancel() {
        setResult(null);
        setUserInput('');
    }
    function renderInfo() {
        if (!result)
            return null;
        return GetSchemaRenderer(result, handleResultAccept, handleResultCancel);
    }
    return (_jsxs("div", __assign({ className: 'flex flex-col gap-2 items-center w-full' }, { children: [_jsxs(AnimatePresence, { children: [result && _jsx(motion.div, __assign({ initial: "collapsed", animate: "open", exit: "collapsed", variants: {
                            open: { opacity: 1, scale: 1 },
                            collapsed: { opacity: 0, scale: 0 }
                        } }, { children: renderInfo() })), error && _jsxs("p", __assign({ className: 'text-red-500' }, { children: ["Error: ", error] }))] }), _jsxs("div", __assign({ className: 'flex items-center w-full relative' }, { children: [_jsx("input", { type: "text", value: userInput, onChange: handleChange, onKeyUp: handleKeyPress, placeholder: "Action or request...", className: 'border border-gray-300 p-2 rounded-full w-full ml-2' }), _jsx(AnimatePresence, { children: processing && (_jsx(motion.div, __assign({ className: 'absolute right-0 h-full flex items-center pr-1' }, { children: _jsx(ClipLoader, { color: "#000" }) }))) })] }))] })));
};
var SchemaSelectorTestPage = function () {
    var _a = useState(null), result = _a[0], setResult = _a[1];
    function onResultSelected(result) {
        console.log('Result selected:', result);
    }
    return (_jsxs("div", __assign({ className: 'w-full h-full bg-mirno-dark' }, { children: [_jsx("div", __assign({ className: 'absolute bottom-0 w-full flex flex-col items-center p-2' }, { children: _jsx("div", __assign({ className: "w-96" }, { children: _jsx(SchemaSelector, { onResultChange: setResult, onResultSelected: onResultSelected }) })) })), result && (_jsxs("div", __assign({ className: 'text-xs p-2 bg-gray-100' }, { children: [_jsxs("p", { children: ["Selected Schema: ", result.selectedSchema] }), _jsx("pre", { children: JSON.stringify(result.filledSchema, null, 2) })] })))] })));
};
export default SchemaSelectorTestPage;
