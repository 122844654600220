var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { zodResponseFormat } from 'openai/helpers/zod';
import { MedicationInterventionSchema } from './schemas/Medication';
import { FluidInterventionSchema } from './schemas/Fluid';
import { AirwayInterventionSchema } from './schemas/Airway';
import { PointOfCareTestsSchema } from './schemas/PointOfCareTests';
var SchemaProcessor = /** @class */ (function () {
    function SchemaProcessor(openai) {
        this.openai = openai;
        this.schemas = {
            "Medication": {
                schema: MedicationInterventionSchema,
                description: "Administering medication to the patient"
            },
            "Fluids": {
                schema: FluidInterventionSchema,
                description: "Fluid administration, including IV fluids, oral fluids, etc"
            },
            "Airway": {
                schema: AirwayInterventionSchema,
                description: "Airway management, including intubation, ventilation, oxygen therapy, etc"
            },
            "PointOfCareTests": {
                schema: PointOfCareTestsSchema,
                description: "Performing point of care tests on the patient, such as blood glucose, blood gas, urinalysis, pregnancy, etc"
            },
        };
    }
    SchemaProcessor.prototype.processUserInput = function (userInput) {
        return __awaiter(this, void 0, Promise, function () {
            var selectedSchemaName, filledSchema;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.selectSchema(userInput)];
                    case 1:
                        selectedSchemaName = _a.sent();
                        return [4 /*yield*/, this.fillSchema(selectedSchemaName, userInput)];
                    case 2:
                        filledSchema = _a.sent();
                        return [2 /*return*/, { selectedSchema: selectedSchemaName, filledSchema: filledSchema }];
                }
            });
        });
    };
    SchemaProcessor.prototype.selectSchema = function (userInput) {
        return __awaiter(this, void 0, Promise, function () {
            var schemaInfo, SYSTEM_PROMPT, USER_PROMPT, response, result, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        schemaInfo = Object.keys(this.schemas).map(function (schemaName) {
                            var schema = _this.schemas[schemaName];
                            return "NAME: ".concat(schemaName, "\t\tDESCRIPTION: ").concat(schema.description, ")");
                        });
                        SYSTEM_PROMPT = "\nGiven the following JSON schemas, select the schema which matches the user's input.\n\n".concat(schemaInfo.join('\n\n'), "\n\nBe smart and try to pick the best match where relevant.\nIf none of the schemas match the user's input, set \"matchingSchema\" to false, selectedSchema to null, and errorMessage to \"no matching schema\"\nIf the user's input is too complex and could potentially match multiple schemas, set \"matchingSchema\" to false, selectedSchema to null, and write a brief error message.\n\nIf it's not possible to pick a match, the error message should explain the ambiguity.\n\nRespond in the following JSON format:\n\n{\n    errorMessage: string | null\n    matchingSchema: boolean     // Only the schema name, skipping the description\n    selectedSchema: string | null\n}\n\n        ").trim();
                        USER_PROMPT = userInput.trim();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.openai.chat.completions.create({
                                model: 'gpt-4o-mini-2024-07-18',
                                messages: [{ role: 'system', content: SYSTEM_PROMPT }, { role: 'user', content: USER_PROMPT }],
                                temperature: 0,
                                response_format: { type: 'json_object' }
                            })];
                    case 2:
                        response = _a.sent();
                        result = JSON.parse(response.choices[0].message.content);
                        console.log(JSON.stringify(result, null, 4));
                        if (!result.matchingSchema) {
                            if (result.errorMessage) {
                                throw new Error(result.errorMessage);
                            }
                            else {
                                throw new Error("No matching schema for ".concat(userInput));
                            }
                        }
                        else {
                            return [2 /*return*/, result.selectedSchema];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error selecting schema:', error_1);
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SchemaProcessor.prototype.fillSchema = function (selectedSchemaName, userInput) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var schemaData, SYSTEM_PROMPT, USER_PROMPT, responseFormat, completion, message, dataObject, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        schemaData = this.schemas[selectedSchemaName];
                        if (!schemaData) {
                            throw new Error("Schema ".concat(selectedSchemaName, " not found"));
                        }
                        SYSTEM_PROMPT = "\nRespond in JSON format using the specified json schema, using the information from the user to build the object.\n// If the user hasn't explicitly specified info for a field, do not include that field in the response.\nFix obvious typos in the user's input where necessary, but never change the meaning of the user's input (eg don't change the dose amount).\n\nThe \"userRequest\" field should always be skipped.\n        ".trim();
                        USER_PROMPT = userInput.trim();
                        responseFormat = zodResponseFormat(schemaData.schema, selectedSchemaName);
                        responseFormat.json_schema.strict = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.openai.beta.chat.completions.parse({
                                model: 'gpt-4o-2024-08-06',
                                messages: [{ role: 'system', content: SYSTEM_PROMPT }, { role: 'user', content: USER_PROMPT }],
                                temperature: 0,
                                response_format: responseFormat,
                            })];
                    case 2:
                        completion = _b.sent();
                        message = (_a = completion.choices[0]) === null || _a === void 0 ? void 0 : _a.message;
                        console.log(message);
                        dataObject = JSON.parse(JSON.stringify(message.parsed));
                        dataObject.userRequest = userInput;
                        return [2 /*return*/, dataObject];
                    case 3:
                        error_2 = _b.sent();
                        console.error('Error filling schema:', error_2);
                        throw error_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return SchemaProcessor;
}());
export default SchemaProcessor;
