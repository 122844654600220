var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import './CustomToggle.css';
var CustomToggle = function (_a) {
    var label = _a.label, value = _a.value, setValue = _a.setValue;
    return (_jsxs("div", __assign({ className: 'flex items-center w-full mt-2 mb-2 hover:bg-gray-100 p-2' }, { children: [_jsx("label", __assign({ htmlFor: "".concat(label, "Toggle"), className: 'w-24 select-none' }, { children: label })), _jsx("div", __assign({ className: "custom-toggle ".concat(value ? 'toggle-on' : 'toggle-off'), onClick: function () { return setValue(!value); } }, { children: _jsx("div", { className: "toggle-thumb ".concat(value ? 'thumb-on' : 'thumb-off') }) }))] })));
};
export default CustomToggle;
