var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
var MenuContext = createContext(undefined);
export function useMenuControl(id) {
    var context = useContext(MenuContext);
    if (!context) {
        throw new Error('useMenuControl must be used within a MenuProvider');
    }
    var activeMenu = context.activeMenu, setActiveMenu = context.setActiveMenu;
    var activateDemoMenu = useCallback(function () { return setActiveMenu(id); }, [setActiveMenu, id]);
    var deactivateDemoMenu = useCallback(function () { return setActiveMenu(null); }, [setActiveMenu]);
    var toggleDemoMenuActive = useCallback(function () { return setActiveMenu(id); }, [setActiveMenu, id]);
    return {
        isDemoMenuActive: activeMenu === id,
        activateDemoMenu: activateDemoMenu,
        deactivateDemoMenu: deactivateDemoMenu,
        toggleDemoMenuActive: toggleDemoMenuActive,
    };
}
var MenuProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), activeMenu = _b[0], setActiveMenu = _b[1];
    var handleSetActiveMenu = useCallback(function (id) {
        if (activeMenu === id) {
            setActiveMenu(null); // If the current active menu is clicked again, deactivate it.
        }
        else {
            setActiveMenu(id); // Activate the new menu directly.
        }
    }, [activeMenu]);
    var value = useMemo(function () { return ({
        activeMenu: activeMenu,
        setActiveMenu: handleSetActiveMenu,
    }); }, [activeMenu, handleSetActiveMenu]);
    return (_jsx(MenuContext.Provider, __assign({ value: value }, { children: children })));
};
var DemoControlGroup = function (_a) {
    var children = _a.children;
    return (_jsx(MenuProvider, { children: children }));
};
export default DemoControlGroup;
