var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import { ChatContainer, MainContainer, MessageInput, Button } from '@chatscope/chat-ui-kit-react';
import { observer } from 'mobx-react-lite';
import renderPaigenMessageListContent from './PaigenMessageListContent';
import '../styles.css';
import { isDevelopment } from '../../../utils/utils';
var PaigenChatComponent = observer(function (_a) {
    var paigenChatManager = _a.paigenChatManager;
    var _b = useState(false), isWaitingForResponse = _b[0], setIsWaitingForResponse = _b[1];
    var _c = useState(), incomingMessageId = _c[0], setIncomingMessageId = _c[1];
    var _d = useState(), incomingMessageText = _d[0], setIncomingMessageText = _d[1];
    var _e = useState(false), showToolAndSystemMessages = _e[0], setShowToolAndSystemMessages = _e[1];
    var inputRef = useRef(null); // Ref for focusing the input
    useEffect(function () {
        var handleDelta = function (content, id) {
            setIsWaitingForResponse(false);
            if (id !== incomingMessageId) {
                setIncomingMessageId(id);
            }
            setIncomingMessageText(function (prev) { return (prev ? prev + content : content); });
        };
        var handleTool = function (toolName, toolArgs) {
            console.log("Tool called: ".concat(toolName), toolArgs);
        };
        paigenChatManager.on('delta', handleDelta);
        paigenChatManager.on('tool', handleTool);
        return function () {
            paigenChatManager.removeListener('delta', handleDelta);
            paigenChatManager.removeListener('tool', handleTool);
        };
    }, [incomingMessageId, paigenChatManager]);
    var handleUserInput = function (input) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    paigenChatManager.addUserMessage(input);
                    setIsWaitingForResponse(true);
                    return [4 /*yield*/, paigenChatManager.processMessages()];
                case 1:
                    _a.sent();
                    setIncomingMessageId(undefined);
                    setIncomingMessageText(undefined);
                    inputRef.current.focus();
                    return [2 /*return*/];
            }
        });
    }); };
    var toggleVisibility = function () {
        setShowToolAndSystemMessages(!showToolAndSystemMessages);
    };
    return (_jsxs("div", __assign({ className: 'flex flex-col overflow-hidden h-full' }, { children: [isDevelopment() &&
                _jsx(Button, __assign({ onClick: toggleVisibility }, { children: showToolAndSystemMessages ? 'Hide System Messages' : 'Show System Messages' })), _jsx(MainContainer, { children: _jsxs(ChatContainer, __assign({ className: "".concat(showToolAndSystemMessages ? '' : 'hide-tool-system') }, { children: [renderPaigenMessageListContent({
                            messages: paigenChatManager.messages,
                            incomingMessageId: incomingMessageId,
                            incomingMessageText: incomingMessageText,
                            isWaitingForResponse: isWaitingForResponse
                        }), _jsx(MessageInput, { ref: inputRef, placeholder: "Type here", onSend: handleUserInput, attachButton: false, disabled: isWaitingForResponse })] })) })] })));
});
export default PaigenChatComponent;
