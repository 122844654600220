var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import AssignmentQuestionsList from './AssignmentQuestionsList';
import AssignmentQuestionEditor from './AssignmentQuestionEditor';
var AssignmentEditor = function (_a) {
    var assignment = _a.assignment, setAssignment = _a.setAssignment;
    var _b = React.useState(0), selectedQuestionIndex = _b[0], setSelectedQuestionIndex = _b[1];
    return (_jsxs("div", __assign({ className: "flex flex-row w-full h-full" }, { children: [_jsx("div", __assign({ className: "w-1/4 bg-gray-100" }, { children: _jsx(AssignmentQuestionsList, { assignment: assignment, selectedQuestionId: selectedQuestionIndex, onQuestionSelected: setSelectedQuestionIndex }) })), _jsx("div", __assign({ className: "flex-grow bg-gray-200" }, { children: _jsx(AssignmentQuestionEditor, { assignment: assignment, selectedQuestionId: selectedQuestionIndex, onAssignmentChange: setAssignment }) }))] })));
};
export default AssignmentEditor;
