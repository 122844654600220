var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from "react";
import { useAppContext } from "../../AppContext";
// import ArcwareView from "../../components/Arcware/Arcware";
import ToolbarController from "./ToolbarController";
import { UE5MessageType } from "../../components/ue5";
import { useParams } from "react-router-dom";
import VirtualPatientStateStorage from "../../components/Database/VirtualPatientStateStorage";
import ArcwareView from "../../components/Arcware/Arcware";
import CinemedicLoadingScreen from "./CinemedicLoadingScreen";
import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import CinemedicOptions from "./CinemedicOptions";
import Monitor from "../../components/Monitor/Monitor";
import { useVirtualPatientStore } from "../../storage/VirtualPatientStore";
import { useSession } from "../Sessions/SessionContext";
import CinemedicModal from "./CinemedicModal";
import { FullscreenBloodGasView } from "../../components/Reuseable/ReportDisplays/BloodGasReport";
var CinemedicView = function () {
    var _a = useAppContext(), loadPreset = _a.loadPreset, broadcastMessage = _a.broadcastMessage;
    var _b = useVirtualPatientStore(), virtualPatientState = _b.virtualPatientState, updateVirtualPatientState = _b.updateVirtualPatientState, setVirtualPatientState = _b.setVirtualPatientState;
    var patientId = useParams().patientId;
    var canCloseLoadingScreen = React.useState(false)[0];
    var _c = React.useState(true), showLoadScreen = _c[0], setShowLoadScreen = _c[1];
    var _d = React.useState(false), showMonitor = _d[0], setShowMonitor = _d[1];
    var _e = React.useState(false), showSessionOptions = _e[0], setShowSessionOptions = _e[1];
    var _f = useSession(), sessionQRCode = _f.sessionQRCode, sessionId = _f.sessionId, fullSessionData = _f.fullSessionData;
    var _g = React.useState(false), showBloodGasReport = _g[0], setShowBloodGasReport = _g[1];
    useEffect(function () {
        loadFromUrlParam();
    });
    useEffect(function () {
        broadcastMessage(UE5MessageType.setCamera.valueOf(), { name: "Top" });
    }, [broadcastMessage]);
    useEffect(function () {
        if (fullSessionData && fullSessionData.sessionData && fullSessionData.sessionData.virtualPatientState) {
            setVirtualPatientState(fullSessionData.sessionData.virtualPatientState);
            setShowLoadScreen(false);
        }
    }, [fullSessionData, setVirtualPatientState, setShowLoadScreen]);
    var loadFromUrlParam = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var patient;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!patientId)
                        return [2 /*return*/];
                    return [4 /*yield*/, VirtualPatientStateStorage.getPreset(patientId)];
                case 1:
                    patient = _a.sent();
                    if (!patient)
                        return [2 /*return*/];
                    loadPreset(patient === null || patient === void 0 ? void 0 : patient.virtualPatientState);
                    return [2 /*return*/];
            }
        });
    }); }, [loadPreset, patientId]);
    function onPatientPresetSelected(doc) {
        loadPreset(doc.virtualPatientState);
        setShowLoadScreen(false);
    }
    var onToggleMonitor = useCallback(function () {
        setShowMonitor(function (prev) { return !prev; });
        updateVirtualPatientState(function (prev) {
            prev.deviceStatus.ecg = true;
            prev.deviceStatus.nibpRightArm = true;
            prev.deviceStatus.pulseOximeterLeftFoot = true;
            return prev;
        });
    }, [setShowMonitor, updateVirtualPatientState]);
    return (_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden' }, { children: [_jsx("div", __assign({ id: "ue5-container", className: "flex-grow h-full w-full absolute" }, { children: _jsx(ArcwareView, {}) })), _jsx(CinemedicOptions, { onLoadNewPatient: function () { return setShowLoadScreen(true); }, onShowSessionOptions: function () { return setShowSessionOptions(true); } }), _jsx(ToolbarController, { onToggleMonitor: onToggleMonitor, onToggleBloodGasReport: function () { return setShowBloodGasReport(function (prev) { return !prev; }); } }), _jsxs(AnimatePresence, { children: [showLoadScreen &&
                        _jsx(CinemedicLoadingScreen, { canClose: canCloseLoadingScreen, onClose: function () { return setShowLoadScreen(false); }, onSelection: onPatientPresetSelected }), showMonitor &&
                        _jsx(motion.div, __assign({ className: "z-[100000000] absolute top-24 right-24 w-[30rem] scale-75 origin-top-right shadow-xl m-2", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx(Monitor, { vitals: virtualPatientState.medicalState.vitals, deviceState: virtualPatientState.deviceStatus }) })), showSessionOptions &&
                        _jsx(CinemedicModal, __assign({ title: "Session Options", onClose: function () { return setShowSessionOptions(false); } }, { children: _jsxs("div", __assign({ className: "flex flex-col items-center gap-4 h-full w-full" }, { children: [sessionId && _jsxs("div", __assign({ className: "text-md" }, { children: ["Session ID: ", sessionId] })), _jsx("div", __assign({ className: "text-md" }, { children: "Scan the QR code to join the session" })), _jsx("div", __assign({ className: "mt-auto mb-auto" }, { children: sessionQRCode("cinemedicController") }))] })) }))] }), _jsx(AnimatePresence, { children: showBloodGasReport &&
                    _jsx(FullscreenBloodGasView, { onClose: function () { return setShowBloodGasReport(false); } }) })] })));
};
export default CinemedicView;
