var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { PatientType } from 'mirno-shared/src/PatientState';
import { useVirtualPatientStore } from '../../../../storage/VirtualPatientStore';
var PatientContent = function () {
    var _a = useVirtualPatientStore(), virtualPatientState = _a.virtualPatientState, updateVirtualPatientState = _a.updateVirtualPatientState;
    var patientTypeOptions = Object.keys(PatientType).map(function (key) {
        var value = PatientType[key];
        return (_jsx("option", __assign({ value: value }, { children: value }), key));
    });
    function setPatientType(value) {
        updateVirtualPatientState(function (state) {
            state.staticData.patientProfile.patientType = value;
        });
    }
    return (_jsx("div", { children: _jsxs("div", __assign({ id: "patientType", className: "mb-4" }, { children: [_jsx("span", __assign({ className: "text-md font-bold" }, { children: "Patient" })), _jsxs("div", __assign({ id: "patientTypeSection", className: "mb-4" }, { children: [_jsx("label", __assign({ htmlFor: "patientType", className: "block font-medium text-gray-700" }, { children: "Age and sex" })), _jsx("select", __assign({ id: "patientType", name: "patientType", className: "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500", value: virtualPatientState.staticData.patientProfile.patientType, onChange: function (e) { return setPatientType(e.target.value); } }, { children: patientTypeOptions }))] }))] })) }));
};
export default PatientContent;
