var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { MdClose } from "react-icons/md";
var InfoDisplay = function (_a) {
    var className = _a.className, hint = _a.hint, infoTypeName = _a.infoTypeName, children = _a.children, onAccept = _a.onAccept, onCancel = _a.onCancel;
    return (_jsxs(motion.div, __assign({ layout: true, className: "relative w-max gap-2 flex items-center p-2 border border-gray-300 text-sm rounded-full bg-gray-100 ".concat(className, " select-none"), title: infoTypeName + ": " + hint }, { children: [children, _jsxs(motion.div, __assign({ className: "flex gap-1 ml-auto pl-2" }, { children: [onAccept &&
                        _jsx(motion.div, __assign({ className: 'bg-green-500 rounded-full text-white cursor-pointer p-1 hover:bg-green-400', onClick: onAccept }, { children: _jsx(FaCheck, {}) })), onCancel &&
                        _jsx(motion.div, __assign({ className: 'bg-red-500 rounded-full text-white cursor-pointer p-1 hover:bg-red-400', onClick: onCancel }, { children: _jsx(MdClose, {}) }))] }))] })));
};
export default InfoDisplay;
