var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useModifiedValues } from "./ControllerUtils";
import SimpleInputControl from "./InputComponents/SimpleInputControl";
import AnimatedSymptomValueBox from "./AnimatedSymptomValueBox";
import { useVirtualPatientStore } from '../../../storage/VirtualPatientStore';
var labelMapping = {
    burns: "Burns",
    petechiae: "Petechiae",
    rashes: "Rashes",
    purpura: "Purpura",
    swelling: "Swelling",
    urticaria: "Urticaria",
    wounds: "Wounds",
    description: "Description",
};
export function useExposureState() {
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    function setExposureValue(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.exposure[key] = value;
        });
    }
    return { setExposureValue: setExposureValue };
}
var ExposureController = function () {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var setExposureValue = useExposureState().setExposureValue;
    var modifiedValues = useModifiedValues("exposure");
    // const allKeys = Object.keys(virtualPatientState.medicalState.exposure) as (keyof ExposureState)[];
    return (_jsx(_Fragment, { children: modifiedValues.map(function (key, id) {
            var value = virtualPatientState.medicalState.exposure[key];
            return (_jsx(AnimatedSymptomValueBox, __assign({ value: value }, { children: _jsx(SimpleInputControl, { label: labelMapping[key], value: value, onChange: function (newValue) { setExposureValue(key, newValue); } }) }), id));
        }) }));
};
export default ExposureController;
