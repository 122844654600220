var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var GPT_MODEL = "gpt-4o";
var SYSTEM_PROMPT = "\nTake the patient information from the user and convert it into data parameters to be displayed to the user via our 3D audio & visual simulation.\n\nReply as json, using the following format:\n\n{\n    faceRedness: number,    // Amount of redness in the face, 0-1\n    sweaty: number, // Amount of sweating, 0-1\n    cyanosisBody: number,   // Amount of cyanosis in the body, 0-1\n    cyanosisFace: number,   // Amount of cyanosis in the face, 0-1\n    mottled: number,    // Intensity of skin mottling, 0-1\n    paleness: number,   // Amount of paleness, 0-1\n    liverEnlargement: number,   // Enlargement of the liver, 0-1\n\n    burns: number,  // Intensity of whole-body burns, 0-1\n    rashes: number, // Intensity of whole-body rashes, 0-1\n    wounds: number, // Intensity of wounds, 0-1\n    swelling: number,   // Intensity of swelling (primarily hands, feet, and face), 0-1\n    purpura: number,    // Intensity of purpura, 0-1\n    petechiae: number,  // Intensity of petechiae, 0-1\n    urticaria: number,  // Intensity of urticaria, 0-1\n}\n".trim();
export function MapSkinParams(patient_info, vitals, openai) {
    return __awaiter(this, void 0, void 0, function () {
        var USER_PROMPT, completionResponse, responseContent, skinParams, skinState, exposureState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    USER_PROMPT = "\n".concat(patient_info, "\n\n").concat(vitals, "\n").trim();
                    return [4 /*yield*/, openai.chat.completions.create({
                            model: GPT_MODEL,
                            temperature: 0,
                            max_tokens: 4095,
                            response_format: { type: "json_object" },
                            messages: [
                                {
                                    role: "system",
                                    content: SYSTEM_PROMPT
                                },
                                {
                                    role: "user",
                                    content: USER_PROMPT
                                }
                            ]
                        })];
                case 1:
                    completionResponse = _a.sent();
                    responseContent = completionResponse.choices[0].message.content;
                    if (responseContent) {
                        skinParams = JSON.parse(responseContent);
                        skinState = {
                            cyanosisBody: skinParams.cyanosisBody,
                            cyanosisFace: skinParams.cyanosisFace,
                            liverEnlargement: skinParams.liverEnlargement,
                            mottled: skinParams.mottled,
                            paleness: skinParams.paleness,
                            faceRedness: skinParams.faceRedness,
                            sweaty: skinParams.sweaty
                        };
                        exposureState = {
                            burns: skinParams.burns,
                            petechiae: skinParams.burns,
                            purpura: skinParams.purpura,
                            rashes: skinParams.rashes,
                            swelling: skinParams.swelling,
                            urticaria: skinParams.urticaria,
                            wounds: skinParams.wounds,
                        };
                        return [2 /*return*/, { skinState: skinState, exposureState: exposureState }];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
