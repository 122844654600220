import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
// import { BloodGas } from "mirno-shared/src/components/BloodGas";
// import { useModifiedValues } from "./ControllerUtils";
// import styles from "./SymptomStyles.module.css";
// import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
var BloodGasController = function () {
    // const { virtualPatientState } = useVirtualPatientStore();
    // const modifiedValues = useModifiedValues<BloodGas>("bloodGas");
    return (_jsx(_Fragment, {}));
};
export default BloodGasController;
