export var PatientType;
(function (PatientType) {
    PatientType["male1yo"] = "1 year old boy";
    PatientType["male5yo"] = "5 year old boy";
    PatientType["male10yo"] = "10 year old boy";
    PatientType["female1yo"] = "1 year old girl";
    PatientType["female5yo"] = "5 year old girl";
    PatientType["female10yo"] = "10 year old girl";
})(PatientType || (PatientType = {}));
export var DefaultProgressionData = {
    summaryOfChange: null,
    elapsedTimeMinutes: 0,
    actionsSoFar: null
};
export var DefaultStaticData = {
    patientProfile: {
        patientType: PatientType.female1yo,
        weight: 10,
        medicalHistory: ""
    },
    simulationInstructions: {
        rootCauses: "",
        unusualResponsesToFutureInterventions: null
    },
    presentingComplaint: ""
};
