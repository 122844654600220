var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import SectionSelect from "./SectionSelect";
import { FaHeadSideCough, FaHeart, FaEye, FaChild, FaPen, FaSearch, FaInfoCircle } from 'react-icons/fa';
import PatientContent from "./SidebarSections/Patient";
import AirwayBreathing from "./SidebarSections/AirwayBreathing";
import Circulation from "./SidebarSections/Circulation";
import Disability from "./SidebarSections/Disability";
import ExaminePatientContent from "./SidebarSections/Examine";
import Exposure from "./SidebarSections/Exposure";
import PresetInfo from "./SidebarSections/PresetInfo";
export var EDITOR_CONFIG_SECTIONS = [
    { name: 'Preset Info', icon: _jsx(FaInfoCircle, {}), component: PresetInfo },
    { name: 'Patient', icon: _jsx(FaPen, {}), component: PatientContent },
    { name: 'Airway & Breathing', icon: _jsx(FaHeadSideCough, {}), component: AirwayBreathing },
    { name: 'Circulation', icon: _jsx(FaHeart, {}), component: Circulation },
    { name: 'Disability', icon: _jsx(FaEye, {}), component: Disability },
    { name: 'Exposure', icon: _jsx(FaChild, {}), component: Exposure },
    { name: 'Examine', icon: _jsx(FaSearch, {}), component: ExaminePatientContent },
];
export var VIEWER_CONFIG_SECTIONS = [
    { name: 'Examine', icon: _jsx(FaSearch, {}), component: ExaminePatientContent },
];
var CategoryRenderer = function (_a) {
    var selectedCategoryIndex = _a.selectedCategoryIndex, categorySections = _a.categorySections;
    var selectedCategory = categorySections[selectedCategoryIndex];
    if (!selectedCategory) {
        return _jsx("div", { children: "Category not found" });
    }
    var SelectedComponent = selectedCategory.component;
    return _jsx(SelectedComponent, {});
};
var PatientEditorSidebar = function (_a) {
    var _b = _a.configSections, configSections = _b === void 0 ? EDITOR_CONFIG_SECTIONS : _b;
    var _c = useState(0), currentCategoryIndex = _c[0], setCurrentCategoryIndex = _c[1];
    return (_jsxs("div", __assign({ className: "flex h-full overflow-hidden" }, { children: [_jsx(SectionSelect, { categories: configSections, currentCategoryIndex: currentCategoryIndex, onCategoryChange: setCurrentCategoryIndex }), _jsx("div", __assign({ className: "h-full border-l p-2 w-full overflow-y-auto" }, { children: _jsx(CategoryRenderer, { selectedCategoryIndex: currentCategoryIndex, categorySections: configSections }) }))] })));
};
export default PatientEditorSidebar;
