var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import EditorIndex from './pages/AdvancedPatientEditor';
import AssignmentModeIndex from './pages/AssignmentMode';
import { AppProvider, useAppContext } from './AppContext';
import "./App.css";
import AssignmentReviewIndex from './pages/AssignmentReview';
import MirnoIcon from "./assets/mirno_icon.png";
import PatientEditorSidebar from './pages/AdvancedPatientEditor/Sidebar/PatientEditorSidebar';
import SidebarMenuBar from './pages/AdvancedPatientEditor/Sidebar/MenuBar';
import PaigenIndex from './pages/Paigen/PaigenIndex';
import DemoModeIndex from './pages/DemoMode/DemoMode';
import SimActionInterfaceTest from './components/SimActionInterface/SimActionInterfaceTest';
import SessionsIndex from './pages/Sessions';
import AssignmentEditorIndex from './pages/AssignmentEditor/AssignmentEditorIndex';
import MegascreenIndex from './pages/Megascreen/MegascreenIndex';
import SessionControllerIndex from './pages/Megascreen/SessionController';
import "./MirnoColors.css";
import ScreenshotComponent from './pages/ScreenshotTest';
import PlatformRoutes from './pages/Platform/Routes';
import MicromedicRoutes from './pages/Micromedic/Routes';
import TreeComponentTest from './pages/TreeView/TreeComponentTest';
import SchemaSelectorTestPage from './pages/TreeView/SchemaTest';
import { CinemedicRoutes } from './pages/Cinemedic/CinemedicIndex';
import MonitorTestIndex from './components/Monitor/MonitorTest';
import { TeamLeadPrototypeRoutes } from './pages/TeamLeadPrototype/TeamLeadPrototypeIndex';
var HomePage = function () {
    return (_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden items-center py-24 bg-mirno-dark' }, { children: [_jsx("img", { src: MirnoIcon, className: 'max-w-xs w-56 pointer-events-none', alt: "Medagogic Logo" }), _jsx("div", __assign({ className: 'flex flex-col items-center gap-4 grow justify-center' }, { children: _jsx(Link, __assign({ to: "/maia", className: 'mode-button' }, { children: "Launch Maia" })) }))] })));
};
var UE5ModeSwitcher = function () {
    var isInsideUE5WebUI = useAppContext().isInsideUE5WebUI;
    return (_jsx(_Fragment, { children: isInsideUE5WebUI === true ?
            _jsxs("div", __assign({ className: 'flex w-full h-full flex-col text-xs bg-white' }, { children: [_jsx(SidebarMenuBar, {}), _jsx(PatientEditorSidebar, {})] }))
            :
                _jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(HomePage, {}) }), _jsx(Route, { path: "/editor", element: _jsx(EditorIndex, {}) }), _jsx(Route, { path: "/student", element: _jsx(AssignmentModeIndex, {}) }), _jsx(Route, { path: "/monitor-test", element: _jsx(MonitorTestIndex, {}) }), _jsx(Route, { path: "/teacher-portal", element: _jsx(AssignmentReviewIndex, {}) }), _jsx(Route, { path: "/maia/:presetId?", element: _jsx(PaigenIndex, {}) }), _jsx(Route, { path: "/demo/:presetId?", element: _jsx(DemoModeIndex, {}) }), _jsx(Route, { path: "/action-interface-test", element: _jsx(SimActionInterfaceTest, {}) }), _jsx(Route, { path: "/sessions", element: _jsx(SessionsIndex, {}) }), _jsx(Route, { path: "/assignment-editor", element: _jsx(AssignmentEditorIndex, {}) }), _jsx(Route, { path: "/megascreen", element: _jsx(MegascreenIndex, {}) }), _jsx(Route, { path: "/session-controller", element: _jsx(SessionControllerIndex, {}) }), _jsx(Route, { path: "/screenshot-test", element: _jsx(ScreenshotComponent, {}) }), _jsx(Route, { path: "/tree", element: _jsx(TreeComponentTest, {}) }), _jsx(Route, { path: "/tree/:presetId", element: _jsx(TreeComponentTest, {}) }), _jsx(Route, { path: "/test", element: _jsx(SchemaSelectorTestPage, {}) }), CinemedicRoutes, PlatformRoutes, MicromedicRoutes, TeamLeadPrototypeRoutes] }) }) }));
};
var App = function () {
    return (_jsx(AppProvider, { children: _jsx(UE5ModeSwitcher, {}) }));
};
export default App;
