export var Posture;
(function (Posture) {
    Posture["Normal"] = "Normal";
    Posture["Decerebrate"] = "Decerebrate";
})(Posture || (Posture = {}));
export var ActivityState;
(function (ActivityState) {
    ActivityState["Calm"] = "Calm";
    ActivityState["Drowsy"] = "Drowsy";
    ActivityState["Unconscious"] = "Unconscious";
    ActivityState["Agitated"] = "Agitated";
    ActivityState["Crying"] = "Crying";
    ActivityState["Seizing"] = "Seizing";
})(ActivityState || (ActivityState = {}));
export var DisabilityMetadata = {
    activity: {
        description: "what the patient is doing",
        options: Object.keys(ActivityState)
    },
    posture: {
        options: Object.keys(Posture)
    },
    avpu: {
        options: ["A", "V", "P", "U"]
    },
    leftPupilSize: {
        unit: "mm"
    },
    rightPupilSize: {
        unit: "mm"
    },
    leftPupilSluggishness: {
        unit: "0 - 1, where 0 is normal response time, 1 is very slow"
    },
    rightPupilSluggishness: {
        unit: "0 - 1, where 0 is normal response time, 1 is very slow"
    }
};
