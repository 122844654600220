var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { AppProvider, useAppContext } from "../../../AppContext";
import PatientSelector from "../../../components/PatientSelection/PatientSelector";
import UserProfileComponent from "../../../components/Reuseable/UserProfileComponent";
import { SessionProvider, useSession } from "../../Sessions/SessionContext";
import React from "react";
// interface MyTreeNodeData {
//     id: string;
//     name: string;
//     children?: MyTreeNodeData[];
// }
// const initialData: MyTreeNodeData[] = [
//     {
//         id: '1',
//         name: 'Root 1',
//         children: [
//             { id: '1-1', name: 'Child 1-1' },
//             { id: '1-2', name: 'Child 1-2' },
//         ],
//     },
//     {
//         id: '2',
//         name: 'Root 2',
//         children: [
//             { id: '2-1', name: 'Child 2-1' },
//             { id: '2-2', name: 'Child 2-2' },
//         ],
//     },
// ];
// const customNodeRenderer = ({ node, style, dragHandle }: NodeRendererProps<MyTreeNodeData>) => (
//     <motion.div
//         onClick={() => node.toggle()}
//         className="cursor-pointer hover:bg-white/10"
//         style={style}
//         ref={dragHandle}
//         initial={{ opacity: 0, y: -10 }}
//         animate={{ opacity: 1, y: 0 }}
//         exit={{ opacity: 0, y: -10 }}
//         transition={{ duration: 0.2 }}
//         layout
//     >
//         {node.children && node.children.length > 0 &&
//             <span>{node.isOpen ? '▼' : '►'}</span>
//         }
//         <span>{node.data.name}</span>
//     </motion.div>
// );
var CinemedicController = function () {
    var _a = useSession(), sessionId = _a.sessionId, sessionData = _a.fullSessionData, writeDataToSession = _a.writeDataToSession;
    var authedUser = useAppContext().authedUser;
    function onSelection(doc) {
        var sessionData = {
            docId: doc.id,
            rootDocId: doc.id,
            virtualPatientState: doc.virtualPatientState
        };
        writeDataToSession(sessionData);
    }
    ;
    return (_jsxs("div", __assign({ className: 'w-full h-full flex flex-col overflow-hidden bg-slate-700 text-white' }, { children: [_jsxs("div", { children: ["Connected to: ", sessionId] }), authedUser && (_jsxs("div", __assign({ className: "overflow-hidden flex flex-col" }, { children: [!sessionData &&
                        _jsx(PatientSelector, { onSelection: onSelection, className: "overflow-hidden h-full max-h-96" }), _jsx("div", __assign({ className: "w-full h-full overflow-auto text-xs" }, { children: _jsx("pre", { children: JSON.stringify(sessionData, null, 2) }) }))] }))), _jsx("div", __assign({ className: "" }, { children: _jsx(UserProfileComponent, { className: "w-96" }) }))] })));
};
var CinemedicControllerIndex = function () {
    return (_jsx(AppProvider, { children: _jsx(SessionProvider, { children: _jsx(CinemedicController, {}) }) }));
};
export default CinemedicControllerIndex;
