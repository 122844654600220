var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import useDynamicVirtualPatient from "./DynamicVirtualPatientStore";
import { FaCirclePause } from "react-icons/fa6";
import { FaCirclePlay } from "react-icons/fa6";
var TimelineController = function (_a) {
    var onChange = _a.onChange;
    var _b = useDynamicVirtualPatient(), interpolationAlpha = _b.interpolationAlpha, interpolationDurationSeconds = _b.interpolationDurationSeconds, setInterpolationTimeSeconds = _b.setInterpolationTimeSeconds;
    // State to track whether the timeline is playing
    var _c = useState(false), isPlaying = _c[0], setIsPlaying = _c[1];
    // Local state to control the current time in seconds
    var _d = useState(interpolationAlpha * interpolationDurationSeconds), currentTime = _d[0], setCurrentTime = _d[1];
    // Update the time every second if playing
    useEffect(function () {
        var interval = null;
        if (isPlaying) {
            interval = setInterval(function () {
                setCurrentTime(function (prevTime) {
                    var newTime = prevTime + 1;
                    if (newTime <= interpolationDurationSeconds) {
                        setInterpolationTimeSeconds(newTime);
                        if (onChange) {
                            onChange(newTime);
                        }
                        return newTime;
                    }
                    else {
                        setIsPlaying(false); // Stop playing if end of timeline is reached
                        return prevTime;
                    }
                });
            }, 1000);
        }
        else if (interval) {
            clearInterval(interval);
        }
        return function () {
            if (interval)
                clearInterval(interval);
        };
    }, [isPlaying, interpolationDurationSeconds, setInterpolationTimeSeconds, onChange]);
    var handleSliderChange = function (e) {
        var newTimeSeconds = parseInt(e.target.value);
        setCurrentTime(newTimeSeconds);
        setInterpolationTimeSeconds(newTimeSeconds);
        if (onChange) {
            onChange(newTimeSeconds);
        }
    };
    function interpolationMinutes() {
        return currentTime / 60;
    }
    var togglePlayPause = function () {
        setIsPlaying(!isPlaying);
    };
    return (_jsxs("div", __assign({ className: "w-full flex flex-col items-center gap-2 bg-mirno-dark p-2" }, { children: [_jsx("input", { type: "range", min: 0, max: interpolationDurationSeconds, step: 1, value: currentTime, onChange: handleSliderChange, className: "w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" }), _jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsxs("span", __assign({ className: "text-sm text-white" }, { children: [Math.floor(interpolationMinutes()), ":", (Math.floor((interpolationMinutes() * 60) % 60)).toString().padStart(2, '0')] })), _jsx("button", __assign({ onClick: togglePlayPause, className: "text-white" }, { children: isPlaying ? _jsx(FaCirclePause, {}) : _jsx(FaCirclePlay, {}) }))] }))] })));
};
export default TimelineController;
