var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Card, Title, Text, Badge, List, ListItem } from '@tremor/react';
import { Eye, Star, Sparkle } from 'lucide-react';
var LatestVirtualPatients = function () {
    var patients = [
        {
            title: "Sepsis in a 1 year old girl",
            description: "Acute sepsis resulting from a urinary tract infection",
            approvalRating: 4.5,
            creator: {
                name: "Dr. Pontus Johansson",
                profession: "Neonatologist",
                location: "Södra Älvsborgs Sjukhus, Borås"
            },
            linkedExperiences: 3,
            version: "2.1"
        },
        {
            title: "Pediatric Fever Management",
            description: "Scenario for managing fever in young children",
            approvalRating: null,
            creator: {
                name: "Dr. Hannah Sjostedt",
                profession: "Pediatrician",
                location: "Sahlgrenska University Hospital, Gothenburg"
            },
            linkedExperiences: 0,
            version: "1"
        },
    ];
    return (_jsxs(Card, { children: [_jsxs(Title, __assign({ className: "flex items-center" }, { children: [_jsx(Sparkle, { className: "w-5 h-5 text-green-400 mr-2" }), "Latest Updates"] })), _jsx(List, __assign({ className: "mt-4" }, { children: patients.map(function (patient, index) { return (_jsxs(ListItem, __assign({ className: "!block" }, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsxs("div", __assign({ className: "flex-grow" }, { children: [_jsxs("div", __assign({ className: "flex items-center space-x-2" }, { children: [_jsx(Text, __assign({ className: "font-bold" }, { children: patient.title })), patient.version === "1" && (_jsx(Badge, __assign({ color: "green", size: "sm" }, { children: "New" })))] })), _jsx(Text, __assign({ className: "text-sm text-gray-600" }, { children: patient.description }))] })), _jsxs("div", __assign({ className: "flex items-center space-x-4" }, { children: [patient.approvalRating !== null ? (_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx(Star, { className: "w-4 h-4 text-yellow-400 mr-1" }), _jsx(Text, __assign({ className: "text-sm" }, { children: patient.approvalRating.toFixed(1) }))] }))) : (_jsx(Text, __assign({ className: "text-sm text-gray-400" }, { children: "Not rated" }))), _jsxs(Text, __assign({ className: "text-sm" }, { children: [patient.linkedExperiences, " exercises"] })), _jsxs(Text, __assign({ className: "text-sm" }, { children: ["v", patient.version] })), _jsx(Badge, __assign({ icon: Eye, size: "sm", color: "blue" }, { children: "View" }))] }))] })), _jsx("div", __assign({ className: "mt-1" }, { children: _jsxs(Text, __assign({ className: "text-xs text-gray-500" }, { children: ["By ", patient.creator.name, " \u2022 ", patient.creator.profession, " \u2022 ", patient.creator.location] })) }))] }), index)); }) }))] }));
};
export default LatestVirtualPatients;
