var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import NumericalInput from '../../../../components/Reuseable/NumericalInput';
import { parseBloodPressure, formatBloodPressure } from 'mirno-shared/src/components/BloodPressure';
import { HeartSound } from 'mirno-shared/src/components/Circulation';
import { useVirtualPatientStore } from '../../../../storage/VirtualPatientStore';
var Circulation = function () {
    var _a = useVirtualPatientStore(), virtualPatientState = _a.virtualPatientState, updateVirtualPatientState = _a.updateVirtualPatientState;
    var heartSoundOptions = Object.keys(HeartSound).map(function (key) {
        var value = HeartSound[key];
        return (_jsx("option", __assign({ value: value }, { children: value }), key));
    });
    function setBloodPressure(value) {
        var nibp = parseBloodPressure(value);
        updateVirtualPatientState(function (state) {
            state.medicalState.vitals.nibp = nibp;
        });
    }
    function setHeartRate(value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.vitals.heartRate = value;
        });
    }
    function setCapillaryRefillTime(value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.circulation.capillaryRefillSeconds = value;
        });
    }
    function setHeartSounds(value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.circulation.heartSound = value;
        });
    }
    return (_jsxs("div", __assign({ className: "flex flex-col gap-2" }, { children: [_jsxs("div", __assign({ id: "bloodPressureSection" }, { children: [_jsx("label", __assign({ htmlFor: "bloodPressure", className: "block font-medium text-gray-700" }, { children: "Blood Pressure (mmHg):" })), _jsx("input", { type: "text", id: "bloodPressure", name: "bloodPressure", placeholder: "120/80", className: "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500", value: formatBloodPressure(virtualPatientState.medicalState.vitals.nibp), onChange: function (e) { return setBloodPressure(e.target.value); } })] })), _jsx(NumericalInput, { id: "heartRate", label: "Heart Rate", units: "Beats per minute", value: virtualPatientState.medicalState.vitals.heartRate, setValue: setHeartRate }), _jsx(NumericalInput, { id: "capillaryRefillTime", label: "Capillary Refill Time", units: "Seconds", value: virtualPatientState.medicalState.circulation.capillaryRefillSeconds, setValue: setCapillaryRefillTime }), _jsxs("div", __assign({ id: "heartSoundsSection", className: "mb-4" }, { children: [_jsx("label", __assign({ htmlFor: "heartSounds", className: "block font-medium text-gray-700" }, { children: "Heart Sounds:" })), _jsx("select", __assign({ id: "heartSounds", name: "heartSounds", className: "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500", value: virtualPatientState.medicalState.circulation.heartSound, onChange: function (e) { return setHeartSounds(e.target.value); } }, { children: heartSoundOptions }))] }))] })));
};
export default Circulation;
