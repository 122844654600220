import { BloodPressureMetadata } from "./BloodPressure";
export var VitalsMetadata = {
    etco2: {
        unit: "mmHg"
    },
    respiratoryRate: {
        unit: "breaths / minute"
    },
    heartRate: {
        unit: "beats / minute"
    },
    nibp: BloodPressureMetadata,
    spo2: {
        unit: "%"
    }
};
