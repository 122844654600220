var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var GPT_MODEL = "gpt-4o";
export function GenerateHypotheticalActions(currentState, openai) {
    return __awaiter(this, void 0, void 0, function () {
        var SYSTEM_PROMPT, USER_PROMPT, completionResponse, responseContent;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    SYSTEM_PROMPT = "\nYou are part of a virtual patient simulation program. Your role is to take the current state of the simulation from the user, and decide on 4 hypothetical next steps which could be taken in order to stabilize the patient. These can be any form of intervention, medication, etc. You should consider this in the context of an acute emergency room scenario. These descriptions should be definitive, and purely described as an action, with no explanatory reasoning.\n\nDo not propose \"or\", \"if\", or any similar conditionalities in your actions - it is for the user to consider any conditions, you are simply to propose hypothetical actions which could be taken.\n\nBe careful to consider the history of actions which have been completed (if any) when making your decisions.\n\nDo not include any assessments, examinations, ordering tests, scans, etc - only interventions, medications, procedures, etc.\n\nWhen considering timeframe, describe it as \"x minutes\", where the timeframe should be the amount of time until the patient would see a significant change as a result of the action.\n\nRespond in JSON in the following format:\n\n{\n    nextSteps: [\n        {\n            action: string, // The action to be taken\n            timeframeInMinutes: number   // The timeframe for which this would make an impact, in minutes\n        }\n    ]\n}\n    ".trim();
                    USER_PROMPT = "\n".concat(JSON.stringify(currentState, null, 4), "\n    ").trim();
                    console.log(USER_PROMPT);
                    return [4 /*yield*/, openai.chat.completions.create({
                            model: GPT_MODEL,
                            temperature: 0,
                            max_tokens: 4095,
                            response_format: { type: "json_object" },
                            messages: [
                                {
                                    role: "system",
                                    content: SYSTEM_PROMPT
                                },
                                {
                                    role: "user",
                                    content: USER_PROMPT
                                }
                            ]
                        })];
                case 1:
                    completionResponse = _a.sent();
                    responseContent = completionResponse.choices[0].message.content;
                    return [2 /*return*/, JSON.parse(responseContent)];
            }
        });
    });
}
