import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { SessionProvider, useSession } from "../Sessions/SessionContext";
var SessionController = function () {
    var _a = useSession(), sessionData = _a.fullSessionData, sessionId = _a.sessionId, writeDataToSession = _a.writeDataToSession;
    // useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const sessionId = urlParams.get('sessionId');
    //     if (sessionId) {
    //         joinSession(sessionId);
    //     }
    // }, [joinSession]);
    useEffect(function () {
        writeDataToSession({ controllerId: 'controller-1' });
    }, [sessionId, writeDataToSession]);
    return (_jsxs("div", { children: [_jsxs("h1", { children: ["Session Controller - ", sessionId] }), _jsx("pre", { children: JSON.stringify(sessionData, null, 2) })] }));
};
var SessionControllerIndex = function () {
    return (_jsx(SessionProvider, { children: _jsx(SessionController, {}) }));
};
export default SessionControllerIndex;
