export var SkinMetadata = {
    faceRedness: {
        unit: "intensity, 0 - 1"
    },
    cyanosisBody: {
        unit: "intensity, 0 - 1"
    },
    cyanosisFace: {
        unit: "intensity, 0 - 1"
    },
    liverEnlargement: {
        unit: "intensity, 0 - 1"
    },
    mottled: {
        unit: "intensity, 0 - 1"
    },
    paleness: {
        unit: "intensity, 0 - 1"
    },
    sweaty: {
        unit: "intensity, 0 - 1"
    }
};
export var ExposureMetadata = {
    burns: {
        unit: "intensity, 0 - 1"
    },
    petechiae: {
        unit: "intensity, 0 - 1"
    },
    purpura: {
        unit: "intensity, 0 - 1"
    },
    rashes: {
        unit: "intensity, 0 - 1"
    },
    swelling: {
        unit: "intensity, 0 - 1"
    },
    urticaria: {
        unit: "intensity, 0 - 1"
    },
    wounds: {
        unit: "intensity, 0 - 1"
    },
    description: {
        description: "any other info"
    }
};
