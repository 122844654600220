var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FaClipboard, FaCheck } from 'react-icons/fa';
var ShareComponent = function (_a) {
    var shareLink = _a.shareLink;
    var _b = useState(false), isCopied = _b[0], setIsCopied = _b[1];
    var handleCopyClick = function () {
        navigator.clipboard.writeText(shareLink).then(function () {
            setIsCopied(true);
            setTimeout(function () { return setIsCopied(false); }, 2000); // Reset the copied state after 2 seconds
        });
    };
    return (_jsxs("div", __assign({ className: "flex items-center space-x-2 mirnoMenuButton", onClick: handleCopyClick }, { children: [_jsx("input", { id: "share-link", type: "text", value: shareLink, readOnly: true, onClick: function (e) { return e.target.select(); }, className: "w-64 pl-1 rounded-md text-sm bg-gray-100 text-gray-800" }), _jsx("button", __assign({ className: "transition duration-200 pl-1" }, { children: isCopied ? (_jsx(FaCheck, { className: "animate-bounce" })) : (_jsx(FaClipboard, {})) }))] })));
};
export default ShareComponent;
