// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// Your web app's Firebase configuration
export var firebaseConfig = {
    apiKey: "AIzaSyAlLz0JrCWd-oD1kGnqwBho6W320GlgeJ4",
    authDomain: "patient-viewer.firebaseapp.com",
    projectId: "patient-viewer",
    storageBucket: "patient-viewer.appspot.com",
    messagingSenderId: "234546430577",
    appId: "1:234546430577:web:e492183062904d92ded1d0",
    measurementId: "G-M6WFB4095G"
};
// Initialize Firebase
export var app = initializeApp(firebaseConfig);
