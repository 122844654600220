var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import VisualTreeComponent, { findParentNode } from './VisualTreeComponent';
import VirtualPatientStateStorage from '../../components/Database/VirtualPatientStateStorage';
import { Button } from '@headlessui/react';
import { GenerateHypotheticalActions } from "mirno-shared/src/paigen/Agents/GenerateHypotheticalActions";
import OpenAI from 'openai';
import { GetProgressionDescription } from 'mirno-shared/src/paigen/Agents/Physiology/GenerateProgressionDescription';
import { ProgressionDescriptionToStateChange } from 'mirno-shared/src/paigen/Agents/Physiology/ProgressionDescriptionToStateChange';
import { ApplyParameterModifications } from 'mirno-shared/src/paigen/Functions/ModifyPatient';
import { v4 as uuidv4 } from 'uuid';
import TextInputForm from './TextInputForm';
import HierarchyService from 'mirno-shared/src/heirarchy/HeirarchyService';
import { useParams } from 'react-router-dom';
import UserProfileComponent from '../../components/Reuseable/UserProfileComponent';
var openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});
function GetTreeNodeNameAndDescription(vpDoc) {
    var _a;
    return {
        name: ((_a = vpDoc.heirarchy) === null || _a === void 0 ? void 0 : _a.entranceAction) || vpDoc.virtualPatientState.name,
        description: vpDoc.virtualPatientState.description || "",
    };
}
function ConvertLoadedVPHeirarchyType(loadedDataNode) {
    var _a = GetTreeNodeNameAndDescription(loadedDataNode.state), name = _a.name, description = _a.description;
    var treeNode = {
        renderId: loadedDataNode.id,
        name: name,
        description: description,
        data: loadedDataNode.state,
        children: loadedDataNode.children.map(function (child) { return ConvertLoadedVPHeirarchyType(child); }),
    };
    return treeNode;
}
var TestPage = function () {
    var _a = useState({}), treeData = _a[0], setTreeData = _a[1];
    var _b = useState(null), selectedNode = _b[0], setSelectedNode = _b[1];
    var urlParams = useParams();
    useEffect(function () {
        if (urlParams.presetId) {
            HierarchyService.FetchFullHeirarchyData(urlParams.presetId).then(function (data) {
                if (!data) {
                    console.error("Preset ".concat(urlParams.presetId, " not found"));
                    return;
                }
                else {
                    console.log("Loaded data:", data);
                }
                var treeNode = ConvertLoadedVPHeirarchyType(data);
                setTreeData(treeNode);
            });
        }
    }, [urlParams.presetId]);
    var handleNodeSelect = function (node) {
        setSelectedNode(node);
        console.log('Selected node:', node);
    };
    var handleAddChild = function (parentId, newNode) {
        var newId = Date.now().toString();
        var newTreeNode = __assign(__assign({}, newNode), { renderId: newId });
        setTreeData(function (prevData) {
            var addChildToNode = function (node) {
                if (node.renderId === parentId) {
                    return __assign(__assign({}, node), { children: __spreadArray(__spreadArray([], (node.children || []), true), [newTreeNode], false) });
                }
                if (node.children) {
                    return __assign(__assign({}, node), { children: node.children.map(addChildToNode) });
                }
                return node;
            };
            return addChildToNode(prevData);
        });
        console.log('Added new child:', newTreeNode);
    };
    function updateNode(updatedNode) {
        var updateTree = function (tree, updatedNode) {
            if (tree.renderId === updatedNode.renderId) {
                return updatedNode;
            }
            if (tree.children) {
                return __assign(__assign({}, tree), { children: tree.children.map(function (child) { return updateTree(child, updatedNode); }) });
            }
            return tree;
        };
        setTreeData(function (prevTreeData) { return updateTree(prevTreeData, updatedNode); });
    }
    function generateNextStates() {
        return __awaiter(this, void 0, void 0, function () {
            var actions, updatedNode;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!selectedNode || !selectedNode.data) {
                            return [2 /*return*/];
                        }
                        console.log("Generating possible actions...");
                        return [4 /*yield*/, GenerateHypotheticalActions(selectedNode.data.virtualPatientState, openai)];
                    case 1:
                        actions = _a.sent();
                        console.log(JSON.stringify(actions, null, 4));
                        updatedNode = __assign(__assign({}, selectedNode), { children: selectedNode.children ? __spreadArray([], selectedNode.children, true) : [] });
                        actions.nextSteps.forEach(function (actionData) {
                            var node = {
                                name: "Pending: " + actionData.action,
                                description: "",
                                renderId: uuidv4(),
                                entranceActionData: actionData,
                            };
                            updatedNode.children.push(node);
                        });
                        // Update the node in the tree
                        updateNode(updatedNode);
                        // Update the selected node
                        setSelectedNode(updatedNode);
                        return [2 /*return*/];
                }
            });
        });
    }
    function generateStateData() {
        return __awaiter(this, void 0, void 0, function () {
            var parentNode;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!selectedNode) {
                            return [2 /*return*/];
                        }
                        parentNode = findParentNode(treeData, selectedNode.renderId);
                        if (!parentNode || !parentNode.data) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, generateStateForNode(selectedNode, parentNode)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function generateStateForNode(treeNode, parentNode) {
        return __awaiter(this, void 0, void 0, function () {
            var event, eventDescription, progressionDescription, stateChange, newVirtualPatient, _a, name, description;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!parentNode.data || !treeNode.entranceActionData) {
                            console.error("Parent node or entrance action not found");
                            return [2 /*return*/];
                        }
                        console.log("Generating state data...");
                        event = treeNode.entranceActionData;
                        eventDescription = "".concat(event.timeframeInMinutes, " minutes after ").concat(event.action);
                        return [4 /*yield*/, GetProgressionDescription(parentNode.data.virtualPatientState, eventDescription, openai)];
                    case 1:
                        progressionDescription = _b.sent();
                        console.log(progressionDescription);
                        return [4 /*yield*/, ProgressionDescriptionToStateChange(progressionDescription, parentNode.data.virtualPatientState, openai)];
                    case 2:
                        stateChange = _b.sent();
                        console.log(stateChange);
                        return [4 /*yield*/, ApplyParameterModifications(stateChange.parameters, parentNode.data.virtualPatientState)];
                    case 3:
                        newVirtualPatient = (_b.sent()).newVirtualPatient;
                        console.log("Applied parameter modifications");
                        if (!newVirtualPatient.progressionData) {
                            newVirtualPatient.progressionData = {
                                summaryOfChange: null,
                                elapsedTimeMinutes: 0,
                                actionsSoFar: []
                            };
                        }
                        else {
                            newVirtualPatient.progressionData.actionsSoFar = newVirtualPatient.progressionData.actionsSoFar || [];
                        }
                        newVirtualPatient.description = progressionDescription.summary;
                        newVirtualPatient.progressionData.actionsSoFar.push(event.action);
                        newVirtualPatient.progressionData.elapsedTimeMinutes += event.timeframeInMinutes;
                        newVirtualPatient.progressionData.summaryOfChange = progressionDescription.short;
                        treeNode.data = {
                            metadata: {
                                createdAt: null,
                                email: null,
                                ownerId: "",
                                ownerName: "",
                                updatedAt: null
                            },
                            virtualPatientState: newVirtualPatient,
                            heirarchy: {
                                parentId: parentNode.data.id || null,
                                children: [],
                                entranceAction: event.action
                            }
                        };
                        _a = GetTreeNodeNameAndDescription(treeNode.data), name = _a.name, description = _a.description;
                        treeNode.name = name;
                        treeNode.description = description;
                        // treeNode.description = newVirtualPatient.progressionData.elapsedTimeMinutes.toString() + " mins";
                        updateNode(treeNode);
                        return [2 /*return*/];
                }
            });
        });
    }
    function addNodeForAction(action) {
        return __awaiter(this, void 0, void 0, function () {
            var event, newNode, parentNode, updatedNode;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!selectedNode) {
                            return [2 /*return*/];
                        }
                        event = {
                            action: action,
                            timeframeInMinutes: 15
                        };
                        newNode = {
                            renderId: uuidv4(),
                            name: action,
                            description: "",
                            entranceActionData: event,
                        };
                        parentNode = findParentNode(treeData, selectedNode.renderId);
                        if (!parentNode) {
                            return [2 /*return*/];
                        }
                        updatedNode = __assign(__assign({}, parentNode), { children: parentNode.children ? __spreadArray([], parentNode.children, true) : [] });
                        updatedNode.children.push(newNode);
                        updateNode(updatedNode);
                        return [4 /*yield*/, generateStateForNode(newNode, parentNode)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function saveSelectedState() {
        return __awaiter(this, void 0, void 0, function () {
            var thisSavedId, parentNode, parentSavedId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!selectedNode || !selectedNode.data) {
                            console.error("Cannot save node without data");
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, VirtualPatientStateStorage.writeVirtualPatientDoc(selectedNode.data)];
                    case 1:
                        thisSavedId = _a.sent();
                        selectedNode.data.id = thisSavedId;
                        selectedNode.renderId = thisSavedId;
                        updateNode(selectedNode);
                        console.log("Saved node with id", thisSavedId);
                        parentNode = findParentNode(treeData, selectedNode.renderId);
                        if (!(parentNode && parentNode.data)) return [3 /*break*/, 3];
                        if (!parentNode.data.heirarchy) {
                            parentNode.data.heirarchy = {
                                parentId: null,
                                children: []
                            };
                        }
                        parentNode.data.heirarchy.children.push(selectedNode.data.id);
                        return [4 /*yield*/, VirtualPatientStateStorage.writeVirtualPatientDoc(parentNode.data)];
                    case 2:
                        parentSavedId = _a.sent();
                        console.log("Saved parent with id", parentSavedId);
                        return [3 /*break*/, 4];
                    case 3:
                        console.error("Parent node not found???");
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs("div", __assign({ className: "p-4 h-full flex flex-col overflow-hidden" }, { children: [_jsx("h1", __assign({ className: "text-2xl font-bold mb-4" }, { children: "Visual Tree Component Test Page" })), _jsxs("div", __assign({ className: "flex h-full overflow-hidden" }, { children: [_jsx("div", __assign({ className: "w-2/3 pr-4" }, { children: _jsx(VisualTreeComponent, { data: treeData, onNodeSelect: handleNodeSelect, onAddChild: handleAddChild }) })), _jsxs("div", __assign({ className: "w-1/3 pl-4 overflow-y-auto overflow-x-hidden" }, { children: [_jsx(UserProfileComponent, {}), _jsx("h2", __assign({ className: "text-xl font-semibold mb-2" }, { children: "Current State" })), selectedNode ? (_jsx(_Fragment, { children: selectedNode.name === "Custom Action" && selectedNode.description === "" ?
                                    (_jsx("div", { children: _jsx(TextInputForm, { onSubmit: function (value) {
                                                addNodeForAction(value);
                                            } }) })) : (_jsxs("div", { children: [_jsxs("p", { children: [_jsx("strong", { children: "Name:" }), " ", selectedNode.name] }), _jsxs("p", { children: [_jsx("strong", { children: "Description:" }), " ", selectedNode.description] }), selectedNode.data ? (_jsx("pre", __assign({ className: 'text-xs' }, { children: JSON.stringify(selectedNode.data, null, 4) }))) : (_jsx("pre", { children: "No patient state" })), selectedNode.data ? (_jsxs("div", __assign({ className: 'flex gap-2' }, { children: [_jsx(Button, __assign({ onClick: generateNextStates, className: "bg-blue-500 text-white rounded-md p-2 hover:bg-blue-400" }, { children: "Generate Next States" })), _jsx(Button, __assign({ onClick: saveSelectedState, className: "bg-blue-500 text-white rounded-md p-2 hover:bg-blue-400" }, { children: "Save" }))] }))) : (_jsx(Button, __assign({ onClick: generateStateData, className: "bg-blue-500 text-white rounded-md p-2 hover:bg-blue-400" }, { children: "Generate State Data" })))] })) })) : (_jsx("p", { children: "No node selected" }))] }))] }))] })));
};
export default TestPage;
