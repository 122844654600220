import { jsx as _jsx } from "react/jsx-runtime";
import { Route } from "react-router-dom";
import { AppProvider } from "../../AppContext";
import { SessionProvider } from "../Sessions/SessionContext";
import CinemedicView from "./CinemedicView";
import CinemedicControllerIndex from "./SessionController/CinemedicControllerIndex";
var CinemedicIndex = function () {
    return (_jsx(AppProvider, { children: _jsx(SessionProvider, { children: _jsx(CinemedicView, {}) }) }));
};
export var CinemedicRoutes = [
    _jsx(Route, { path: "/cinemedic/:patientId?", element: _jsx(CinemedicIndex, {}) }),
    _jsx(Route, { path: "/cinemedicController", element: _jsx(CinemedicControllerIndex, {}) })
];
export default CinemedicIndex;
