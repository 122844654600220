import { z } from "zod";
export var PointOfCareTestsSchema = z.object({
    testName: z.enum([
        "Blood Glucose",
        "Troponin",
        "Pregnancy Test",
        "Urinalysis",
        "Arterial Blood Gas",
        "Hemoglobin",
        "Other"
    ]),
    sampleType: z
        .enum(["Capillary Blood", "Venous Blood", "Urine", "Other"])
        .optional(),
    userRequest: z.string().optional()
});
