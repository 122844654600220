var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDown, ChevronRight } from 'lucide-react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserProfileButton from './UserProfileButton';
import MirnoIconURL from "../../assets/mirno_icon.png";
var Sidebar = function () {
    var _a = useState([]), openCategories = _a[0], setOpenCategories = _a[1];
    var location = useLocation();
    var categories = [
        {
            name: "Virtual Patients",
            items: [
                { name: "Explore Virtual Patients", path: "/platform/vp-index" },
                { name: "Virtual Patient Creator", path: "/platform/vp-creator" },
            ],
        },
        {
            name: "Micromedic",
            items: [
                { name: "My Content", path: "/platform/micromedic/my-content" },
                { name: "Ongoing Assignments", path: "/platform/micromedic/assignments" },
            ],
        },
        {
            name: "Cinemedic",
            items: [
                { name: "My Content", path: "/platform/cinemedic/my-content" },
                { name: "Session History", path: "/platform/cinemedic/my-content" },
            ],
        },
        {
            name: "Playground",
            items: [
                { name: "My Content", path: "/platform/playground/my-content" },
            ],
        },
    ];
    var toggleCategory = function (categoryName) {
        setOpenCategories(function (prev) {
            return prev.includes(categoryName)
                ? prev.filter(function (name) { return name !== categoryName; })
                : __spreadArray(__spreadArray([], prev, true), [categoryName], false);
        });
    };
    var isActive = function (path) { return location.pathname === path; };
    return (_jsxs("div", __assign({ className: "bg-gray-200 w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out" }, { children: [_jsxs("nav", __assign({ className: "space-y-2" }, { children: [_jsxs(Link, __assign({ to: "/platform", className: "block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-300 flex items-center gap-2 ".concat(isActive('/platform') ? 'bg-gray-300' : '') }, { children: [_jsx("img", { src: MirnoIconURL, className: 'h-4', alt: "Mirno Icon" }), "Home"] })), categories.map(function (category) { return (_jsxs("div", __assign({ className: "space-y-1" }, { children: [_jsxs("button", __assign({ onClick: function () { return toggleCategory(category.name); }, className: "w-full flex items-center justify-between py-2.5 px-4 rounded transition duration-200 hover:bg-gray-300" }, { children: [_jsx("span", { children: category.name }), openCategories.includes(category.name) ? _jsx(ChevronDown, { size: 16 }) : _jsx(ChevronRight, { size: 16 })] })), openCategories.includes(category.name) && (_jsx("div", __assign({ className: "pl-4 space-y-1" }, { children: category.items.map(function (item) { return (_jsx(Link, __assign({ to: item.path, className: "block py-2 px-4 rounded transition duration-200 hover:bg-gray-300 ".concat(isActive(item.path) ? 'bg-gray-300' : '') }, { children: item.name }), item.name)); }) })))] }), category.name)); })] })), _jsx(UserProfileButton, {})] })));
};
export default Sidebar;
