var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { app } from '../../firebase-config';
import { getFunctions, httpsCallable } from 'firebase/functions';
var functions = getFunctions(app);
var server_sendAssignments = httpsCallable(functions, 'sendAssignments');
var AssignmentUserList = function (_a) {
    var assignment = _a.assignment;
    var _b = useState([{ email: "david@mirno.is" }]), users = _b[0], setUsers = _b[1];
    var _c = useState(""), newEmail = _c[0], setNewEmail = _c[1];
    var handleAddUser = function () {
        setUsers(__spreadArray(__spreadArray([], users, true), [{ email: newEmail }], false));
        setNewEmail("");
    };
    var handleEditUser = function (index, newEmail) {
        var updatedUsers = users.map(function (user, i) { return i === index ? { email: newEmail } : user; });
        setUsers(updatedUsers);
    };
    var handleRemoveUser = function (index) {
        var updatedUsers = users.filter(function (_, i) { return i !== index; });
        setUsers(updatedUsers);
    };
    var onSendAssignment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!assignment.docId) {
                        return [2 /*return*/];
                    }
                    console.log("Sending assignment:", assignment.docId, users.map(function (user) { return user.email; }));
                    return [4 /*yield*/, server_sendAssignments({ assignmentId: assignment.docId, emailAddresses: users.map(function (user) { return user.email; }) })];
                case 1:
                    response = _a.sent();
                    console.log("Sent assignments:", response);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { children: [_jsxs("div", { children: ["Assignment ID: ", assignment.docId] }), _jsx("div", { children: users.map(function (user, index) { return (_jsxs("div", { children: [_jsx("input", { type: "text", value: user.email, onChange: function (e) { return handleEditUser(index, e.target.value); } }), _jsx("button", __assign({ onClick: function () { return handleRemoveUser(index); } }, { children: "Remove" }))] }, index)); }) }), _jsx("div", { children: _jsx("button", __assign({ onClick: handleAddUser }, { children: "Add User" })) }), _jsx("div", { children: _jsx("button", __assign({ onClick: onSendAssignment }, { children: "Send Assignment" })) })] }));
};
export default AssignmentUserList;
