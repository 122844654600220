var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback, useRef } from 'react';
import "./EyeControls.css";
import CustomSlider from './CustomSlider';
import { useVirtualPatientStore } from '../../storage/VirtualPatientStore';
var lerp = function (a, b, t) { return (b - a) * t + a; };
var unlerp = function (a, b, t) { return (t - a) / (b - a); };
var map = function (a1, b1, a2, b2, t) { return lerp(a2, b2, unlerp(a1, b1, t)); };
;
var PupilControl = function (_a) {
    var pupilSize = _a.pupilSize, onSizeChange = _a.onSizeChange;
    var eyeRef = useRef(null);
    var isDragging = useRef(false);
    var calculatePupilSize = useCallback(function (event) {
        var eye = eyeRef.current;
        if (!eye)
            return;
        var _a = eye.getBoundingClientRect(), left = _a.left, top = _a.top, width = _a.width, height = _a.height;
        var eyeCenterX = left + width / 2;
        var eyeCenterY = top + height / 2;
        var distance = Math.sqrt(Math.pow(event.clientX - eyeCenterX, 2) + Math.pow(event.clientY - eyeCenterY, 2));
        var a = 2 * distance / width;
        var b = map(0.1, 0.7, 0, 1, a);
        var size = Math.min(Math.max(0, b), 1);
        onSizeChange(size);
    }, [onSizeChange]);
    var startDragging = useCallback(function () {
        isDragging.current = true;
    }, []);
    var stopDragging = useCallback(function () {
        isDragging.current = false;
    }, []);
    var handleMouseMove = useCallback(function (event) {
        if (isDragging.current) {
            calculatePupilSize(event);
        }
    }, [calculatePupilSize]);
    function getRenderSize() {
        return map(0, 1, 0.1, 0.7, pupilSize) * 100;
    }
    return (_jsx("div", __assign({ className: "flex justify-center items-center relative h-full aspect-square", onMouseMove: handleMouseMove, onMouseUp: stopDragging, onMouseLeave: stopDragging }, { children: _jsx("div", __assign({ ref: eyeRef, className: "w-full h-full bg-blue-300 rounded-full flex justify-center items-center relative", onMouseDown: startDragging }, { children: _jsx("div", { className: "bg-black rounded-full", style: { width: "".concat(getRenderSize(), "%"), height: "".concat(getRenderSize(), "%") } }) })) })));
};
export var EyeControlsComponent = function () {
    var _a = useVirtualPatientStore(), virtualPatientState = _a.virtualPatientState, updateVirtualPatientState = _a.updateVirtualPatientState;
    var isLinkedRef = useRef(true);
    var handlePupilSizeChange = React.useCallback(function (newSize, pupilId) {
        function onPupilSizeChange(pupilId, newSize) {
            updateVirtualPatientState(function (state) {
                state.medicalState.disability["".concat(pupilId, "PupilSize")] = newSize;
            });
        }
        onPupilSizeChange(pupilId, newSize);
        if (isLinkedRef.current) {
            var otherPupilId = pupilId === 'left' ? 'right' : 'left';
            console.log("Linked", otherPupilId, newSize);
            onPupilSizeChange(otherPupilId, newSize);
        }
    }, [updateVirtualPatientState]);
    var handlePupilSluggishnessChange = React.useCallback(function (newValue, pupilId) {
        function setSluggishness(pupilId, newValue) {
            updateVirtualPatientState(function (state) {
                state.medicalState.disability["".concat(pupilId, "PupilSluggishness")] = newValue;
            });
        }
        setSluggishness(pupilId, newValue);
        if (isLinkedRef.current) {
            var otherPupilId = pupilId === 'left' ? 'right' : 'left';
            setSluggishness(otherPupilId, newValue);
        }
    }, [updateVirtualPatientState]);
    function toggleLink() {
        if (!isLinkedRef.current) {
            var newSize_1 = (virtualPatientState.medicalState.disability.leftPupilSize + virtualPatientState.medicalState.disability.rightPupilSize) / 2;
            var newSluggishness_1 = (virtualPatientState.medicalState.disability.leftPupilSluggishness + virtualPatientState.medicalState.disability.rightPupilSluggishness) / 2;
            updateVirtualPatientState(function (state) {
                state.medicalState.disability.leftPupilSize = newSize_1;
                state.medicalState.disability.rightPupilSize = newSize_1;
                state.medicalState.disability.leftPupilSluggishness = newSluggishness_1;
                state.medicalState.disability.rightPupilSluggishness = newSluggishness_1;
            });
        }
        isLinkedRef.current = !isLinkedRef.current;
    }
    // State to force component update
    var _b = useState({}), forceUpdate = _b[1];
    return (_jsxs("div", { children: [_jsx("div", __assign({ className: "pupil-controls" }, { children: _jsxs("div", __assign({ className: 'flex justify-center items-center w-full h-20 gap-20 p-2' }, { children: [_jsx(PupilControl, { pupilSize: virtualPatientState.medicalState.disability.leftPupilSize, onSizeChange: function (size) { return handlePupilSizeChange(size, "left"); } }), _jsx(PupilControl, { pupilSize: virtualPatientState.medicalState.disability.rightPupilSize, onSizeChange: function (size) { return handlePupilSizeChange(size, "right"); } })] })) })), _jsxs("div", __assign({ className: 'flex w-full gap-2 p-2' }, { children: [_jsx(CustomSlider, { label: 'Response time', setValue: function (v) { return handlePupilSluggishnessChange(v, "left"); }, value: virtualPatientState.medicalState.disability.leftPupilSluggishness, vertical: true }), _jsx(CustomSlider, { label: 'Response time', setValue: function (v) { return handlePupilSluggishnessChange(v, "right"); }, value: virtualPatientState.medicalState.disability.rightPupilSluggishness, vertical: true })] })), _jsx("div", __assign({ className: "simple-button text-center", onClick: function () { toggleLink(); forceUpdate({}); } }, { children: isLinkedRef.current ? 'Unlink' : 'Link' }))] }));
};
export default EyeControlsComponent;
