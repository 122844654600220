import { BloodPressureMetadata } from "../components/BloodPressure";
import { BreathingSoundMetadata, WorkOfBreathingMetadata } from "../components/Breathing";
import { CirculationMetadata } from "../components/Circulation";
import { DisabilityMetadata } from "../components/Disability";
import { SkinMetadata, ExposureMetadata } from "../components/Skin";
import { VitalsMetadata } from "../components/Vitals";
// import * as TJS from "ts-json-schema-generator";
// import * as path from 'path';
export var MetadataDictionary = {
    BloodPressure: BloodPressureMetadata,
    VitalsState: VitalsMetadata,
    BreathingSound: BreathingSoundMetadata,
    WorkOfBreathing: WorkOfBreathingMetadata,
    Circulation: CirculationMetadata,
    Disability: DisabilityMetadata,
    Skin: SkinMetadata,
    Exposure: ExposureMetadata
};
export var MetadataMapping = {
    vitals: VitalsMetadata,
    breathingSounds: BreathingSoundMetadata,
    workOfBreathing: WorkOfBreathingMetadata,
    circulation: CirculationMetadata,
    disability: DisabilityMetadata,
    skin: SkinMetadata,
    exposure: ExposureMetadata,
    bloodGas: undefined,
};
export function GetAnnotatedPatientState(patientState) {
    var result = "";
    for (var keyName in patientState) {
        var key = keyName;
        var metadata = MetadataMapping[key];
        var values = patientState[key];
        var sectionText = GetAnnotatedSection(key, values, metadata);
        result += sectionText + "\n\n";
    }
    return result;
}
function GetAnnotatedSection(sectionName, sectionValues, sectionMetadata, level) {
    if (level === void 0) { level = 0; }
    var result = "";
    if (level === 0) {
        result = "# ".concat(sectionName, "\n");
    }
    for (var stateVariableName in sectionValues) {
        var headerLine = "**".concat(stateVariableName, "**:\n");
        var dataLines = "";
        if (sectionValues !== undefined && sectionValues !== null) {
            var value = sectionValues[stateVariableName];
            if (value !== undefined && value !== null) {
                if (typeof value === "object") {
                    var subSectionMetadata = undefined;
                    if (sectionMetadata !== undefined) {
                        subSectionMetadata = sectionMetadata[stateVariableName];
                    }
                    dataLines += GetAnnotatedSection(stateVariableName, value, subSectionMetadata, level + 1) + "\n";
                }
                else {
                    dataLines += " - **Current**: ".concat(value, "\n");
                }
            }
        }
        if (sectionMetadata !== undefined) {
            var fieldMetadata = sectionMetadata[stateVariableName];
            if (fieldMetadata !== undefined && fieldMetadata !== null) {
                if (fieldMetadata.description) {
                    dataLines += " - **Description**: ".concat(fieldMetadata.description, "\n");
                }
                if (fieldMetadata.unit) {
                    dataLines += " - **Unit**: ".concat(fieldMetadata.unit, "\n");
                }
                if (fieldMetadata.options) {
                    dataLines += " - **Options**: ".concat(fieldMetadata.options.join(", "), "\n");
                }
            }
        }
        if (dataLines.length > 0) {
            result += headerLine + dataLines;
        }
    }
    result = result.trim();
    // indent all of result by level * 2
    var indent = "  ".repeat(level);
    result = result.split("\n").map(function (line) { return indent + line; }).join("\n");
    return result;
}
export function GetAllMetadataMarkdown() {
    var result = "";
    for (var typeName in MetadataDictionary) {
        var metadata = MetadataDictionary[typeName];
        var sectionText = GetMetadataMarkdown(typeName, metadata);
        result += sectionText + "\n";
    }
    return result;
}
function GetMetadataMarkdown(typeName, metadata, prefix) {
    if (prefix === void 0) { prefix = ''; }
    var result = "# ".concat(typeName, "\n");
    for (var key in metadata) {
        // eslint-disable-next-line no-prototype-builtins
        if (metadata.hasOwnProperty(key)) {
            var fieldMetadata = metadata[key];
            if (fieldMetadata !== undefined && fieldMetadata !== null) {
                var headerLine = "**".concat(prefix).concat(key, "**:\n");
                var dataLines = "";
                if (fieldMetadata.description) {
                    dataLines += " - **Description**: ".concat(fieldMetadata.description, "\n");
                }
                if (fieldMetadata.unit) {
                    dataLines += " - **Unit**: ".concat(fieldMetadata.unit, "\n");
                }
                if (fieldMetadata.options) {
                    dataLines += " - **Options**: ".concat(fieldMetadata.options.join(", "), "\n");
                }
                // dataLines = dataLines.trim();
                if (dataLines.length > 0) {
                    result += headerLine + dataLines + "\n";
                }
            }
        }
    }
    return result;
}
export function GetAnnotatedPatientJson(patientState) {
    var result = "";
    for (var keyName in patientState) {
        var key = keyName;
        var metadata = MetadataMapping[key];
        var values = patientState[key];
        // Skip empty objects
        if (typeof values === "object" && Object.keys(values).length === 0) {
            continue;
        }
        var sectionText = GetAnnotatedJson(key, values, metadata);
        result += sectionText + "\n\n";
    }
    // indent all of result by 2
    result = result.trim().split("\n").map(function (line) { return "  " + line; }).join("\n");
    return "{\n".concat(result, "\n}");
}
function GetAnnotatedJson(sectionName, sectionValues, sectionMetadata, level) {
    if (level === void 0) { level = 1; }
    var result = "\"".concat(sectionName, "\":\n{\n");
    var sectionContent = "";
    for (var stateVariableName in sectionValues) {
        var headerLine = "\"".concat(stateVariableName, "\": ");
        var dataLines = "";
        if (sectionValues !== undefined && sectionValues !== null) {
            var value = sectionValues[stateVariableName];
            if (value !== undefined && value !== null) {
                if (typeof value === "object") {
                    var subSectionMetadata = undefined;
                    if (sectionMetadata !== undefined) {
                        subSectionMetadata = sectionMetadata[stateVariableName];
                    }
                    dataLines += GetAnnotatedJson(stateVariableName, value, subSectionMetadata, level + 1);
                }
                else {
                    dataLines += "".concat(headerLine).concat(JSON.stringify(value), ",");
                }
            }
        }
        if (sectionMetadata !== undefined) {
            var fieldMetadata = sectionMetadata[stateVariableName];
            if (fieldMetadata !== undefined && fieldMetadata !== null) {
                var comments = [];
                if (fieldMetadata.unit) {
                    comments.push("".concat(fieldMetadata.unit));
                }
                if (fieldMetadata.description) {
                    comments.push("".concat(fieldMetadata.description));
                }
                if (fieldMetadata.options) {
                    comments.push("Options: [".concat(fieldMetadata.options.join(", "), "]"));
                }
                if (comments.length > 0) {
                    var fullComment = comments.join(" - ");
                    dataLines += " // ".concat(fullComment.trim().replace(/ - $/, ""));
                }
            }
        }
        if (dataLines.length > 0) {
            sectionContent += dataLines + "\n";
        }
    }
    var indent = "  ";
    sectionContent = sectionContent.trim().split("\n").map(function (line) { return indent + line; }).join("\n");
    result += sectionContent;
    // result += sectionContent.trim().replace(/,$/, ""); // Remove the trailing comma from the last element
    result += "\n},";
    return result;
}
// export async function GetMedicalStateJsonSchema() {
//     const config: TJS.Config = {
//         path: path.resolve(__dirname, "../PatientState.ts"),
//         type: "VirtualPatientMedicalState",
//         jsDoc: "basic"
//     };
//     return TJS.createGenerator(config).createSchema(config.type);
// }
