var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from "react";
import { motion, useMotionValue, animate, AnimatePresence } from "framer-motion";
import { MdDragIndicator } from "react-icons/md";
import DevicesControl from "../../components/DemoControls/DevicesControl";
import ExaminationControl from "../../components/DemoControls/ExaminationControl";
import ReportsControl from "../../components/DemoControls/ReportsControl";
import StethoscopeControl from "../../components/DemoControls/StethoscopeControl";
import DemoControlGroup from "../../components/DemoControls/DemoControlCommon";
import SimpleInterventionControl from "../../components/DemoControls/SimpleInterventionControl";
var ToolbarContent = function (_a) {
    var onToggleBloodGasReport = _a.onToggleBloodGasReport;
    return (_jsx("div", __assign({ className: "flex gap-2 h-12" }, { children: _jsxs(DemoControlGroup, { children: [_jsx(ReportsControl, { props: { controlsMenuAlignment: "top" }, onToggleBloodGasReport: onToggleBloodGasReport }), _jsx(StethoscopeControl, { controlsMenuAlignment: "top" }), _jsx(ExaminationControl, { controlsMenuAlignment: "top" }), _jsx(DevicesControl, { controlsMenuAlignment: "top" }), _jsx(SimpleInterventionControl, { controlsMenuAlignment: "top" })] }) })));
};
var DraggableToolbar = function (_a) {
    var _b = _a.x, initialX = _b === void 0 ? 0 : _b, _c = _a.y, initialY = _c === void 0 ? 0 : _c, initialRotation = _a.initialRotation, onClose = _a.onClose, onToggleMonitor = _a.onToggleMonitor, onToggleBloodGasReport = _a.onToggleBloodGasReport;
    var _d = useState(false), isDragging = _d[0], setIsDragging = _d[1];
    var toolbarRef = useRef(null);
    var dragConstraintsRef = useRef(null);
    var canDrag = useState(true)[0];
    var x = useMotionValue(initialX);
    var y = useMotionValue(initialY);
    var rotation = useMotionValue(initialRotation);
    var margin = 16;
    var snapToEdge = React.useCallback(function (dragX, dragY) {
        var _a;
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var toolbarWidth = ((_a = toolbarRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0;
        var distances = {
            left: dragX,
            right: windowWidth - dragX,
            top: dragY,
            bottom: windowHeight - dragY,
        };
        var minDistanceEdge = Object.keys(distances).reduce(function (a, b) { return distances[a] < distances[b] ? a : b; });
        function getClampedY() {
            var widthPlusMargin = toolbarWidth / 2 + margin;
            return Math.max(widthPlusMargin, Math.min(windowHeight - widthPlusMargin, y.get()));
        }
        function getClampedX() {
            var widthPlusMargin = toolbarWidth / 2 + margin;
            return Math.max(widthPlusMargin, Math.min(windowWidth - widthPlusMargin, x.get()));
        }
        switch (minDistanceEdge) {
            case 'left':
                return { x: margin, y: getClampedY(), rotation: 90 };
            case 'right':
                return { x: windowWidth - margin, y: getClampedY(), rotation: -90 };
            case 'top':
                return { x: getClampedX(), y: margin, rotation: 180 };
            case 'bottom':
                return { x: getClampedX(), y: windowHeight - margin, rotation: 0 };
            default:
                return { x: x.get(), y: y.get(), rotation: 0 };
        }
    }, [x, y]);
    var handleDragEnd = function (event, info) {
        setIsDragging(false);
        var _a = info.point, dragX = _a.x, dragY = _a.y;
        var _b = snapToEdge(dragX, dragY), newX = _b.x, newY = _b.y, newRotation = _b.rotation;
        var desiredRotation = newRotation;
        var rotationDelta = newRotation - rotation.get();
        if (Math.abs(rotationDelta) > 180) {
            desiredRotation += rotationDelta > 0 ? -360 : 360;
        }
        animate(x, newX, { type: "spring", stiffness: 500, damping: 30 });
        animate(y, newY, { type: "spring", stiffness: 500, damping: 30 });
        animate(rotation, desiredRotation, { type: "spring", stiffness: 500, damping: 30 });
    };
    var handleDragStart = function () {
        setIsDragging(true);
    };
    React.useEffect(function () {
        var container = dragConstraintsRef.current;
        if (container) {
            var resizeObserver_1 = new ResizeObserver(function () {
                var currentX = x.get();
                var currentY = y.get();
                var _a = snapToEdge(currentX, currentY), newX = _a.x, newY = _a.y, newRotation = _a.rotation;
                animate(x, newX, { type: "spring", stiffness: 500, damping: 30 });
                animate(y, newY, { type: "spring", stiffness: 500, damping: 30 });
                animate(rotation, newRotation, { type: "spring", stiffness: 500, damping: 30 });
            });
            resizeObserver_1.observe(container);
            return function () {
                resizeObserver_1.disconnect();
            };
        }
    }, [x, y, rotation, snapToEdge]);
    var renderScale = 1;
    return (_jsx("div", __assign({ ref: dragConstraintsRef, className: "absolute w-full h-full pointer-events-none" }, { children: _jsx(motion.div, __assign({ ref: toolbarRef, drag: canDrag, dragMomentum: false, onDragEnd: handleDragEnd, onDragStart: handleDragStart, className: "fixed text-white rounded cursor-move pointer-events-auto ".concat(isDragging ? "z-100" : "z-90"), style: {
                x: x,
                y: y,
                rotate: rotation,
                transformOrigin: "0% 100%",
                translateY: "-100%",
            }, initial: { opacity: 0, scale: 0.2 }, animate: { opacity: 1, scale: renderScale }, exit: { opacity: 0, scale: 0.2 }, transition: { duration: 0.3, type: "spring", stiffness: 500, damping: 30 } }, { children: _jsxs(motion.div, __assign({ className: "flex items-center gap-1 text-slate-200 h-16 min-w-60 p-1 justify-between rounded bg-slate-800", layout: "size", style: { transformOrigin: "0% 50%" } }, { children: [_jsx(MdDragIndicator, { className: "text-3xl h-full flex-shrink-0 -mr-1 pointer-events-auto" }), _jsx(AnimatePresence, { children: _jsx(ToolbarContent, { onToggleBloodGasReport: onToggleBloodGasReport }) })] })) })) })));
};
export default DraggableToolbar;
