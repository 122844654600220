var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route } from 'react-router-dom';
import UserProfilePage from './UserProfile';
import PlatformLayout from './PlatformLayout';
import PlatformDashboard from './PlatformDashboard';
import VirtualPatientsIndex from './VirtualPatients/VirtualPatientsIndex';
var PlatformRoutes = [
    _jsxs(Route, __assign({ path: "/platform", element: _jsx(PlatformLayout, {}) }, { children: [_jsx(Route, { index: true, element: _jsx(PlatformDashboard, {}) }), _jsx(Route, { path: "profile", element: _jsx(UserProfilePage, {}) }), _jsx(Route, { path: "vp-index", element: _jsx(VirtualPatientsIndex, {}) })] }))
];
export default PlatformRoutes;
