var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@tremor/react';
import { User, LogOut } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../components/Database/Authentication';
var UserProfileButton = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var dropdownRef = useRef(null);
    var navigate = useNavigate();
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    var handleManageProfile = function () {
        navigate('/platform/profile');
        setIsOpen(false);
    };
    var handleLogout = function () {
        // Implement logout logic here
        console.log('Logging out...');
        setIsOpen(false);
    };
    function imgSrc() {
        var _a;
        return ((_a = AuthService.currentUser()) === null || _a === void 0 ? void 0 : _a.photoURL) || '/api/placeholder/40/40';
    }
    return (_jsxs("div", __assign({ className: "absolute bottom-4 left-4", ref: dropdownRef }, { children: [_jsx("button", __assign({ onClick: function () { return setIsOpen(!isOpen); }, className: "w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" }, { children: _jsx("img", { src: imgSrc(), alt: "User avatar", className: "w-full h-full object-cover" }) })), isOpen && (_jsxs("div", __assign({ className: "absolute left-0 bottom-full p-2 w-48 bg-white rounded-md shadow-lg z-50" }, { children: [_jsx(Button, __assign({ icon: User, variant: "secondary", className: "w-full justify-start", onClick: handleManageProfile }, { children: "Manage Profile" })), _jsx(Button, __assign({ icon: LogOut, variant: "secondary", className: "w-full justify-start", onClick: handleLogout }, { children: "Log Out" }))] })))] })));
};
export default UserProfileButton;
