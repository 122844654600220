var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var CustomButton = function (_a) {
    var children = _a.children, onClick = _a.onClick, disabled = _a.disabled;
    return (_jsx("button", __assign({ onClick: onClick, className: "w-36 h-8 leading-3 flex justify-center box-border items-center relative border-2 border-gray-400 rounded\n      hover:bg-gray-100 active:bg-gray-200 active:border-gray-400 disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed", disabled: disabled }, { children: children })));
};
export default CustomButton;
