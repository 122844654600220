var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useModifiedValues } from "./ControllerUtils";
import UnitInputControl from "./InputComponents/UnitInputControl";
import BloodPressureInputControl from "./InputComponents/BloodPressureInputControl";
import AnimatedSymptomValueBox from "./AnimatedSymptomValueBox";
import { useVirtualPatientStore } from '../../../storage/VirtualPatientStore';
var unitMapping = {
    etco2: "mmhg",
    heartRate: "bpm",
    nibp: "mmhg",
    respiratoryRate: "bpm",
    spo2: "%",
};
var labelMapping = {
    etco2: "ETCO2",
    heartRate: "Heart rate",
    nibp: "Blood pressure",
    respiratoryRate: "Respiratory rate",
    spo2: "SpO2",
};
export function useVitals() {
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    function setVitals(key, value) {
        updateVirtualPatientState(function (state) {
            state.medicalState.vitals[key] = value;
        });
    }
    return { setVitals: setVitals };
}
var VitalsController = function () {
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var setVitals = useVitals().setVitals;
    var modifiedValues = useModifiedValues("vitals");
    function onChange(key, value) {
        setVitals(key, value);
    }
    return (_jsx(_Fragment, { children: modifiedValues.map(function (key, id) {
            var value = virtualPatientState.medicalState.vitals[key];
            return (_jsx(AnimatedSymptomValueBox, __assign({ value: value }, { children: (key !== "nibp") ? (_jsx(UnitInputControl, { label: labelMapping[key], value: value, unit: unitMapping[key], onChange: function (newValue) { onChange(key, newValue); } })) : (_jsx(BloodPressureInputControl, { label: labelMapping[key], value: value, onChange: function (newValue) { onChange(key, newValue); } })) }), id));
        }) }));
};
export default VitalsController;
